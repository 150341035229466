import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  InputGroup,
  Row,
} from "react-bootstrap";

import "../../Style/Modules/Common.scss";
import "../../Style/Modules/Service.scss";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import ContactBanner from "../../Assets/Images/HomeImages/ContactBanner.png";
import axios from "axios";

const Contact = () => {
  const scrollToRef = useRef();

  useEffect(() => {
    // Scroll to the target element
    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const [contactFoem, setContactForm] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });

  const handleValue = (e) => {
    setContactForm({ ...contactFoem, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    await axios
      .post(
        "https://contact-api-service-5j6j.onrender.com/mailsend",
        contactFoem
      )
      .then((response) => {
        console.log("response", response);
        setContactForm({});
        alert("Success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Banner Section~~~~~~~~~~~~~~~~~~~~~~~*/}

      <section className="globalBanner" ref={scrollToRef}>
        <Container>
          <Row>
            <Col md={10}>
              <div className="globalBanner-text">
                <p>check out our all </p>
                <h1 className="gradient">Contact us</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <div className="globalBanner-text">
                <h5>Your Questions, Our Answers</h5>
                <p className="secondary">
                  We are ready to answer all your queries regarding your
                  business success. Take your time to fill us this form and we
                  will get back to you in no time.
                </p>
              </div>
            </Col>
            <Col md={7}>
              <div className="globalBanner-img">
                <img src={ContactBanner} alt="" />
              </div>
            </Col>
          </Row>
          <Link className="phNumber">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M31.8617 19.6763C32.2677 12.5406 26.8122 6.42678 19.6765 6.02077C12.5408 5.61475 6.42698 11.0702 6.02097 18.2059C5.61495 25.3416 11.0704 31.4554 18.2061 31.8615C21.6331 32.0565 24.9972 30.882 27.5581 28.5965C30.1187 26.3114 31.6667 23.1027 31.8617 19.6763ZM19.5781 27.2949C19.578 27.2949 19.5779 27.2949 19.5778 27.2949C19.577 27.2949 19.5766 27.2939 19.5772 27.2934C19.5779 27.2928 19.5775 27.2918 19.5767 27.2918L18.6563 27.2362C17.451 27.175 16.3652 26.4886 15.793 25.426C15.2419 24.3328 14.8695 23.1584 14.6899 21.9474C14.5105 20.8335 14.454 19.7032 14.5217 18.577C14.5766 17.5473 14.7343 16.5256 14.992 15.5272C15.2627 14.4453 15.6818 13.4061 16.2376 12.4391C16.8971 11.3107 18.1376 10.6517 19.4419 10.7369L20.526 10.7987C20.8281 10.8153 21.0596 11.0737 21.043 11.3758C21.043 11.3765 21.043 11.3774 21.0429 11.3782L20.8476 14.8011C20.831 15.1032 20.5727 15.3347 20.2706 15.3181C20.2698 15.3181 20.269 15.318 20.2681 15.318L18.2582 15.2033C17.6848 15.1674 17.1716 15.5565 17.0512 16.1183C16.8666 16.9728 16.7497 17.8406 16.7014 18.7136C16.6436 19.6905 16.6725 20.6706 16.7879 21.6424C16.8552 22.2034 17.314 22.6359 17.878 22.67L19.837 22.7665C20.1391 22.7831 20.3706 23.0414 20.354 23.3435C20.354 23.3443 20.3539 23.3451 20.3539 23.346L20.155 26.7779C20.1386 27.08 19.8802 27.3115 19.5781 27.2949Z"
                  fill="black"
                />
              </svg>
            </span>
            (033) 4804-7123
          </Link>
        </Container>
        <div className="innerLine">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>

      <section className="contactUs">
        <Container>
          <Row>
            <Col md={8}>
              <p className="primary">CONTACT US</p>
              <h2>Want to Launch a Project?</h2>
            </Col>
            <Col md={4} className="d-flex flex-column justify-content-end">
              <p className="primary mb-3">
                Don’t stop yourself from making the right move. Experience the
                dream of expanding your business today.
              </p>
              <p className="primary">
                Schedule a free call back with us and we will get back to you in
                no time.
              </p>
            </Col>
          </Row>
          <Row className="mt-5 ">
            <Col md={4}>
              <div className="contactUs-details">
                <div className="contactUs-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_617_13389)">
                      <path
                        d="M48.7501 21.65C48.7485 21.6488 48.7474 21.6473 48.7463 21.6462L28.3502 1.25084C27.4808 0.381089 26.3249 -0.0976562 25.0955 -0.0976562C23.866 -0.0976562 22.7101 0.381089 21.8404 1.25084L1.45498 21.6359C1.44811 21.6427 1.44087 21.65 1.43438 21.6568C-0.350902 23.4524 -0.34785 26.3657 1.44315 28.1567C2.26141 28.9754 3.34173 29.4491 4.49721 29.4991C4.54451 29.5037 4.59181 29.506 4.63949 29.506H5.45203V44.5153C5.45203 47.4858 7.86902 49.9024 10.8395 49.9024H18.8191C19.6282 49.9024 20.284 49.2463 20.284 48.4376V36.67C20.284 35.3146 21.3868 34.2122 22.7422 34.2122H27.4488C28.8041 34.2122 29.9066 35.3146 29.9066 36.67V48.4376C29.9066 49.2463 30.5623 49.9024 31.3714 49.9024H39.351C42.3219 49.9024 44.7385 47.4858 44.7385 44.5153V29.506H45.4923C46.7214 29.506 47.8773 29.0272 48.7474 28.1571C50.5403 26.3634 50.5411 23.4448 48.7501 21.65ZM46.6756 26.0857C46.3594 26.402 45.939 26.5763 45.4923 26.5763H43.2737C42.4646 26.5763 41.8088 27.232 41.8088 28.0411V44.5153C41.8088 45.8703 40.7064 46.9727 39.351 46.9727H32.8363V36.67C32.8363 33.6995 30.4196 31.2825 27.4488 31.2825H22.7422C19.7713 31.2825 17.3543 33.6995 17.3543 36.67V46.9727H10.8395C9.48455 46.9727 8.38172 45.8703 8.38172 44.5153V28.0411C8.38172 27.232 7.72597 26.5763 6.91687 26.5763H4.73639C4.7135 26.5748 4.69099 26.5736 4.66772 26.5732C4.23132 26.5656 3.822 26.3924 3.5153 26.0853C2.86299 25.433 2.86299 24.3714 3.5153 23.7187C3.51568 23.7187 3.51568 23.7183 3.51606 23.7179L3.51721 23.7168L23.9125 3.32223C24.2284 3.00599 24.6484 2.83204 25.0955 2.83204C25.5422 2.83204 25.9622 3.00599 26.2784 3.32223L46.6691 23.7126C46.6722 23.7156 46.6756 23.7187 46.6787 23.7217C47.3276 24.3752 47.3264 25.4345 46.6756 26.0857Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_617_13389">
                        <rect width="50" height="50" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <p className="contactUs-details-head">Office Address</p>
                  <p>
                    2nd floor, Indo Japan Building, EP- J I/12, EP Block, Sector
                    V, Bidhannagar, Kolkata, West Bengal 700091
                  </p>
                </div>
              </div>
              <div className="contactUs-details">
                <div className="contactUs-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_617_13394)">
                      <path
                        d="M49.966 5.04634C50.2098 3.91168 49.0974 2.95495 48.0122 3.36901L0.942376 21.3376C0.375878 21.5539 0.00117462 22.0969 2.75658e-06 22.7032C-0.00116911 23.3096 0.371386 23.8541 0.937103 24.0725L14.1599 29.1794V45.2645C14.1599 45.943 14.6258 46.5326 15.286 46.6896C15.9417 46.8455 16.6255 46.5335 16.9329 45.9237L22.4017 35.0714L35.7477 44.9759C36.5595 45.5785 37.7264 45.1953 38.0218 44.227C50.4825 3.37223 49.9438 5.14897 49.966 5.04634ZM38.3469 10.1945L15.4171 26.5243L5.55122 22.714L38.3469 10.1945ZM17.0896 28.9299L37.0765 14.6959C19.878 32.8393 20.7763 31.8844 20.7013 31.9853C20.5898 32.1352 20.8951 31.551 17.0896 39.1026V28.9299ZM35.8255 41.3854L24.078 32.6672L45.3191 10.259L35.8255 41.3854Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_617_13394">
                        <rect width="50" height="50" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <p className="contactUs-details-head">Email ID</p>
                  <Link to={"mailto://support@techwens.com"}>
                    support@techwens.com
                  </Link>
                </div>
              </div>
              <div className="contactUs-details">
                <div className="contactUs-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <mask
                      id="mask0_617_13435"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="50"
                      height="50"
                    >
                      <path d="M0 0H50V50H0V0Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_617_13435)">
                      <path
                        d="M28.46 0.987372C39.7988 0.987372 49.0234 10.212 49.0234 21.5508"
                        stroke="white"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M28.46 6.86227C36.5587 6.86227 43.1484 13.4521 43.1484 21.5508"
                        stroke="white"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M28.46 12.7383C33.3194 12.7383 37.2725 16.6914 37.2725 21.5508"
                        stroke="white"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M28.46 18.6133C30.082 18.6133 31.3975 19.9288 31.3975 21.5508"
                        stroke="white"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.3027 31.7191C18.3027 32.2584 17.8655 32.6956 17.3262 32.6956C16.7869 32.6956 16.3496 32.2584 16.3496 31.7191C16.3496 31.1798 16.7869 30.7425 17.3262 30.7425C17.8655 30.7425 18.3027 31.1798 18.3027 31.7191Z"
                        fill="white"
                      />
                      <path
                        d="M14.4731 28.3796C13.8536 27.5659 13.2665 26.7255 12.7136 25.8603C11.9871 24.7227 12.2068 23.2159 13.1608 22.2618L16.4764 18.9462C17.1901 18.2335 17.1901 17.0762 16.4764 16.3632L8.67353 8.5606C7.96064 7.84664 6.80351 7.84664 6.09062 8.5606L4.11591 10.5351C0.910929 13.7393 0.0447175 18.6298 2.05644 22.6904C5.63554 29.916 13.0008 40.9921 27.1902 47.9502C31.2635 49.9463 36.1766 49.0997 39.3836 45.8926L41.4031 43.873C42.1169 43.1602 42.1169 42.003 41.4031 41.289L33.6003 33.4874C32.8874 32.7734 31.7303 32.7734 31.0174 33.4874L27.7019 36.8028C26.7477 37.7568 25.241 37.9765 24.1032 37.2501C22.8725 36.4634 21.6913 35.6073 20.5653 34.6866"
                        stroke="white"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <p className="contactUs-details-head">Contact Number</p>
                  <Link className="text-start">(033) 4804-7123</Link>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <Row className="gy-2 gy-md-4">
                <Col sm={6}>
                  <InputGroup>
                    <Form.Control
                      placeholder="Enter your name"
                      aria-label="Enter your name"
                      aria-describedby="basic-addon2"
                      name="name"
                      onChange={handleValue}
                      value={contactFoem.name}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <Form.Control
                      placeholder="Company (optional)"
                      aria-label="Company (optional)"
                      aria-describedby="basic-addon2"
                      name="company"
                      onChange={handleValue}
                      value={contactFoem.company}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <Form.Control
                      placeholder="Enter your email"
                      aria-label="Enter your email"
                      aria-describedby="basic-addon2"
                      name="email"
                      onChange={handleValue}
                      value={contactFoem.email}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <Form.Control
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      aria-describedby="basic-addon2"
                      name="phone"
                      onChange={handleValue}
                      value={contactFoem.phone}
                    />
                  </InputGroup>
                </Col>
                <Col sm={12}>
                  <FloatingLabel controlId="floatingTextarea2">
                    <Form.Control
                      as="textarea"
                      placeholder="Tell us about yourself"
                      style={{ height: "100px" }}
                      name="message"
                      onChange={handleValue}
                      value={contactFoem.message}
                    />
                  </FloatingLabel>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col lg={4}>
                      <Button onClick={handleSubmit} className="send">
                        Send message
                      </Button>
                    </Col>
                    <Col lg={8} className="d-flex align-items-center">
                      <p className="primary mt-3 mt-lg-0">
                        By Clicking Contact Us Button, You Agree Our Terms And
                        Policy.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="Location">
        <Container>
          <h3 className="gradient">Discover Our Location</h3>
          <p className="primary">
            Explore our location and know from where we serve through the
            following address.
          </p>
          <div className="Location-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.1627760519727!2d88.43122767594046!3d22.573014532953923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275d11d766819%3A0xabbfac954ad6affc!2sTechwens%20Software%20Pvt.%20Ltd.%20%7C%20Custom%20Enterprise%20Software%20Development%20Services!5e0!3m2!1sen!2sin!4v1697797275698!5m2!1sen!2sin"
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Contact;
