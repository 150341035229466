import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import serviceImg1 from "../../Assets/Images/HomeImages/demandService1.png";
import serviceImg2 from "../../Assets/Images/HomeImages/demandService2.png";
import serviceImg3 from "../../Assets/Images/HomeImages/demandService3.png";
const demoServices = [
  {
    heading: "Mobile App Development",
    image: serviceImg1,
  },
  {
    heading: "UI/UX Design",
    image: serviceImg2,
  },
  {
    heading: "Web Development",
    image: serviceImg3,
  },
  {
    heading: "SEO Service",
    image: serviceImg1,
  },
  {
    heading: "Social Media Marketing",
    image: serviceImg2,
  },
  {
    heading: "Branding",
    image: serviceImg3,
  },
];

const DemandServices = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Demand Services Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="OnDemand">
        <Container>
          <h2>Your Growth, Our Success</h2>
          <div className="OnDemand-services">
            <Row>
              <Col lg={5} xxl={4}>
                <p className="primary">
                  Software development services are crucial today as they enable
                  businesses to create and maintain digital solutions that drive
                  efficiency, innovation, and competitiveness in an increasingly
                  technology-driven and interconnected global economy. Hence,
                  count on us for the same to elevate your business goals in no
                  time.
                </p>
                <div className="OnDemand-book">
                  <Link to="/service" className="secondary-button dark">
                    Book
                    <br />
                    Services
                  </Link>
                  <div>
                    <p>10+</p>
                    <span>Many More Services to Explore</span>
                  </div>
                </div>
              </Col>
              <Col lg={7} xxl={8}>
                <Swiper
                  spaceBetween={20}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="outSlider"
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {demoServices.map((item, index) => (
                    <SwiperSlide key={index}>
                      {" "}
                      <div className="OnDemand-services-card">
                        <p>{item.heading}</p>
                        <div>
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DemandServices;
