import instance from './baseApi';


export default {
    getData: () =>
    instance({
        'method':'GET',
        'url':`/wp-json/wp/v2/posts`,
        // 'url':`/wp-json/wp/v2/posts?per_page=100`,

    }),
    postData: () =>
    instance({
        'method': 'POST',
        'url':'/api',
        'data': {
            'item1':'data1',
            'item2':'item2'
        }
    })
}
