import Index from "./Router/Index";
import "./App.scss";

function App() {
  return (
    <>
      <Index />

    </>
  );
}

export default App;
