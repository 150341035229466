import React from "react";
import "../../Style/Modules/Home.scss";
import { Col, Container, Row } from "react-bootstrap";
import TechnologiesImg from "../../Assets/Images/HomeImages/Technologies.png";

const Technologies = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Technologies Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="technologies">
        <div className="technologies-wrapper">
          <Container>
            <Row className="justify-content-center">
              <Col xl={9}>
                <h3>Our Digital Backbone</h3>
                <p className="secondary">
                  Explore the technologies we use that go along with the latest
                  industrial trends.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="technologies-images">
                  <img src={TechnologiesImg} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Technologies;
