import React, { useEffect, useRef } from "react";
import { Col, Container, Row, Nav, Tab, InputGroup } from "react-bootstrap";
import "../../../Style/Modules/About.scss";
import "../../../Style/Modules/Common.scss";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import BrandingBanner from "../../../Assets/Images/ServiceImages/BrandingBanner.png";
import BrandingSub from "../../../Assets/Images/ServiceImages/BrandingSub.png";
import BrandingList from "../../../Assets/Images/ServiceImages/BrandingList.png";
import BrandSlider1 from "../../../Assets/Images/ServiceImages/BrandSlider1.png";
import BrandSlider2 from "../../../Assets/Images/ServiceImages/BrandSlider2.png";
import BrandSlider3 from "../../../Assets/Images/ServiceImages/BrandSlider3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const Branding = () => {
  const scrollToRef = useRef();
  useEffect(() => {
    // Scroll to the target element
    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="globalBanner inner" ref={scrollToRef}>
        <Container>
          <Row>
            <Col md={10}>
              <div className="globalBanner-text head">
                <p>Branding Services</p>
                <h1 className="gradient">Branding & Marketing services</h1>
              </div>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
            >
              <Link to="/contact" className="rotate">
                <div className="rotate-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="142"
                    height="143"
                    viewBox="0 0 142 143"
                    fill="none"
                  >
                    <path
                      d="M14.4904 102.335C14.5096 102.727 14.4426 103.072 14.2894 103.388C14.1267 103.704 13.8875 103.943 13.562 104.125L13.1218 103.282C13.3898 103.101 13.5716 102.861 13.6577 102.555C13.7439 102.249 13.6865 101.895 13.4759 101.502C13.2749 101.129 13.0261 100.88 12.7293 100.756C12.4326 100.631 12.1264 100.65 11.8297 100.813C11.5999 100.937 11.4468 101.1 11.3607 101.301C11.2841 101.502 11.2554 101.722 11.2841 101.952C11.3128 102.182 11.3607 102.478 11.4468 102.852C11.5521 103.302 11.6191 103.684 11.6382 103.981C11.6574 104.278 11.5999 104.565 11.466 104.852C11.332 105.139 11.0735 105.379 10.7003 105.58C10.3653 105.752 10.0303 105.819 9.68571 105.79C9.34115 105.752 9.0253 105.618 8.71903 105.388C8.42232 105.158 8.1639 104.843 7.9629 104.45C7.6662 103.885 7.56092 103.34 7.65663 102.833C7.75234 102.325 8.02033 101.923 8.47018 101.627L8.92959 102.498C8.70946 102.651 8.57546 102.88 8.50846 103.187C8.44146 103.493 8.50846 103.828 8.69031 104.182C8.86259 104.517 9.0923 104.737 9.36987 104.862C9.64743 104.986 9.94414 104.957 10.2504 104.804C10.4706 104.689 10.6141 104.536 10.6907 104.335C10.7673 104.134 10.7864 103.933 10.7673 103.704C10.7481 103.483 10.6907 103.187 10.6141 102.804C10.5184 102.344 10.4514 101.971 10.4323 101.665C10.4131 101.368 10.4706 101.071 10.6045 100.775C10.7385 100.478 10.997 100.239 11.3798 100.038C11.6765 99.8847 11.9924 99.8177 12.3369 99.8368C12.6815 99.856 13.0165 99.9804 13.3323 100.201C13.6577 100.421 13.9257 100.746 14.1459 101.158C14.3564 101.56 14.4713 101.952 14.4904 102.344V102.335Z"
                      fill="black"
                    />
                    <path
                      d="M7.60862 99.9612C7.00563 99.8751 6.46008 99.655 5.99109 99.2817C5.5221 98.9084 5.16797 98.4298 4.92869 97.8364C4.65112 97.1473 4.57456 96.4678 4.70855 95.8169C4.83298 95.1661 5.1584 94.6014 5.68481 94.1228L6.07723 95.1182C5.76138 95.4436 5.56996 95.8169 5.49339 96.2381C5.41682 96.6496 5.47425 97.0899 5.6561 97.5493C5.83795 97.9896 6.09637 98.3533 6.44093 98.6213C6.78549 98.8893 7.19706 99.052 7.65647 99.0998C8.11589 99.1477 8.60402 99.0711 9.11129 98.8701C9.61856 98.6691 10.0205 98.3916 10.3173 98.0374C10.614 97.6833 10.7958 97.2909 10.8628 96.8602C10.9298 96.4295 10.8724 95.9892 10.7001 95.5394C10.5182 95.0799 10.2598 94.7258 9.92484 94.4674C9.58985 94.2185 9.19743 94.0845 8.73801 94.0558L8.34559 93.0604C9.04429 93.0604 9.66642 93.2423 10.2024 93.6251C10.7384 94.008 11.1499 94.544 11.4275 95.2426C11.6668 95.8361 11.7338 96.4199 11.6572 97.0133C11.5711 97.6067 11.3414 98.1427 10.9585 98.6213C10.5757 99.0998 10.078 99.4635 9.45585 99.7028C8.83373 99.9517 8.22117 100.028 7.61819 99.9517L7.60862 99.9612Z"
                      fill="black"
                    />
                    <path
                      d="M1.86572 87.9875L8.46026 86.169L8.69954 87.0304L5.71333 87.8535L6.64173 91.213L9.63751 90.3899L9.87679 91.2513L3.28226 93.0698L3.04298 92.2084L5.94304 91.414L5.01464 88.0545L2.11457 88.8585L1.87529 87.9971L1.86572 87.9875Z"
                      fill="black"
                    />
                    <path
                      d="M2.0193 85.0111L4.26852 84.6091L3.82825 82.1589L4.55566 82.0249L4.99593 84.4751L7.31215 84.054L6.81445 81.307L7.54186 81.173L8.1927 84.8005L1.44503 86.016L0.794189 82.3886L1.5216 82.2546L2.0193 85.0015V85.0111Z"
                      fill="black"
                    />
                    <path
                      d="M0.592907 76.5023C0.822615 75.9376 1.18632 75.4781 1.67445 75.1431C2.16258 74.8082 2.75599 74.6072 3.45469 74.5497C4.15338 74.4923 4.76594 74.5784 5.31149 74.8273C5.84748 75.0761 6.27818 75.459 6.59403 75.9758C6.90988 76.4927 7.1013 77.1244 7.1683 77.8709L7.35015 79.9957L0.53548 80.5891L0.353628 78.4643C0.28663 77.7178 0.37277 77.0669 0.602478 76.4927L0.592907 76.5023ZM5.56034 75.9854C5.05307 75.5643 4.38309 75.392 3.54083 75.459C2.68899 75.5356 2.04772 75.8227 1.61702 76.33C1.18632 76.8372 1.00447 77.5264 1.08104 78.4069L1.18632 79.6416L6.54617 79.1726L6.44089 77.9379C6.36432 77.0574 6.07719 76.4065 5.56991 75.9854H5.56034Z"
                      fill="black"
                    />
                    <path
                      d="M0.00932703 72.2624L4.33549 72.3294C4.94805 72.3389 5.39789 72.1954 5.6946 71.9082C5.99131 71.6115 6.14444 71.2 6.15402 70.6735C6.15402 70.1471 6.02002 69.7356 5.73288 69.4389C5.44575 69.1326 4.9959 68.9794 4.38335 68.9699L0.0571829 68.9029L0.0763252 68.0128L4.39292 68.0798C4.96719 68.0798 5.43618 68.2138 5.8286 68.4435C6.21144 68.6827 6.49858 68.989 6.68043 69.3814C6.86228 69.7739 6.94842 70.2141 6.94842 70.6927C6.94842 71.1808 6.84314 71.6115 6.64215 71.9944C6.45072 72.3772 6.15402 72.6835 5.7616 72.9036C5.36918 73.1238 4.89062 73.229 4.31635 73.2195L-0.000244141 73.1525L0.0188982 72.2624H0.00932703Z"
                      fill="black"
                    />
                    <path
                      d="M6.40289 65.8207L6.6326 63.4375L7.36001 63.5045L7.04416 66.7778L0.229492 66.1174L0.315633 65.2273L6.40289 65.8207Z"
                      fill="black"
                    />
                    <path
                      d="M1.58821 60.7675L3.84701 61.1504L4.26814 58.6906L4.99555 58.815L4.57442 61.2748L6.90021 61.6672L7.3692 58.9203L8.09661 59.0447L7.48405 62.6722L0.726807 61.5236L1.33936 57.8962L2.06677 58.0206L1.59778 60.7675H1.58821Z"
                      fill="black"
                    />
                    <path
                      d="M8.64206 51.0622L7.77108 53.9144L9.06319 54.8907L8.78562 55.7904L3.00464 51.4355L3.31092 50.4496L10.5371 50.0859L10.2596 50.9856L8.64206 51.0622ZM7.87636 51.0909L4.21061 51.2632L7.14895 53.455L7.87636 51.0909Z"
                      fill="black"
                    />
                    <path
                      d="M8.69036 37.6528L14.7968 40.6294L14.4043 41.4334L9.84847 39.2129L13.4185 43.4625L13.141 44.0272L7.5801 43.8453L12.1456 46.0658L11.7531 46.8698L5.64673 43.8932L6.06786 43.0318L12.2221 43.2328L8.26923 38.5142L8.69036 37.6528Z"
                      fill="black"
                    />
                    <path
                      d="M10.7953 35.5089L12.7382 36.7149L14.059 34.5997L14.6812 34.9921L13.3603 37.1073L15.3607 38.3516L16.8347 35.9875L17.4568 36.3799L15.5138 39.5097L9.69458 35.8918L11.6375 32.762L12.2597 33.1544L10.7857 35.5185L10.7953 35.5089Z"
                      fill="black"
                    />
                    <path
                      d="M13.7629 31.0008L15.6102 32.3504L17.0841 30.3404L17.6775 30.7807L16.2036 32.7907L18.0987 34.188L19.7449 31.9388L20.3383 32.3791L18.1561 35.3462L12.6335 31.288L14.8158 28.3209L15.4092 28.7612L13.7629 31.0104V31.0008Z"
                      fill="black"
                    />
                    <path
                      d="M18.6724 23.7653L19.2275 24.2438L18.012 25.6603L22.6444 29.6515L22.0606 30.3311L17.4281 26.3399L16.203 27.7564L15.6575 27.2779L18.682 23.7653H18.6724Z"
                      fill="black"
                    />
                    <path
                      d="M20.2811 22.1286L25.1911 26.895L24.5689 27.5363L19.6589 22.7699L20.2811 22.1286Z"
                      fill="black"
                    />
                    <path
                      d="M30.1484 22.2914L29.4784 22.8848L23.1614 21.2195L26.7985 25.2776L26.1285 25.871L21.5535 20.76L22.2234 20.1666L28.5308 21.8224L24.9034 17.7738L25.5734 17.1804L30.1484 22.2819V22.2914Z"
                      fill="black"
                    />
                    <path
                      d="M32.5897 14.462C32.2068 14.2514 31.7953 14.1557 31.3837 14.1844C30.9626 14.2131 30.5606 14.3663 30.1682 14.6438C29.7758 14.9214 29.4886 15.2564 29.3068 15.6584C29.1249 16.0604 29.0579 16.4911 29.1153 16.9505C29.1728 17.4099 29.3642 17.8597 29.6705 18.3C29.9768 18.7403 30.3405 19.0561 30.7616 19.2571C31.1732 19.4581 31.6039 19.5443 32.0441 19.4964C32.4844 19.4486 32.896 19.2954 33.2884 19.0179C33.8339 18.635 34.1594 18.1469 34.2838 17.5726C34.4082 16.9983 34.2934 16.4145 33.9584 15.8115L31.7378 17.4003L31.3167 16.8069L34.3125 14.6725L34.7145 15.2277C35.0016 15.7158 35.1548 16.2422 35.1835 16.7973C35.2122 17.3525 35.1069 17.8885 34.858 18.3862C34.6188 18.8934 34.2455 19.3241 33.7574 19.6687C33.2405 20.042 32.6854 20.2525 32.0824 20.31C31.4794 20.3674 30.9052 20.2717 30.35 20.0228C29.7949 19.7644 29.3259 19.372 28.9335 18.8264C28.5411 18.2809 28.3209 17.7066 28.2539 17.094C28.1965 16.4815 28.2922 15.9072 28.5411 15.3617C28.7899 14.8161 29.1823 14.3567 29.6992 13.993C30.2926 13.5719 30.9147 13.3422 31.5847 13.3134C32.2451 13.2847 32.8768 13.457 33.4607 13.8399L32.5897 14.462Z"
                      fill="black"
                    />
                    <path
                      d="M44.3147 13.4476L43.5203 13.8496L37.8541 10.6145L40.3139 15.4767L39.5195 15.8787L36.4185 9.76268L37.2129 9.36069L42.879 12.5862L40.4192 7.73359L41.2136 7.3316L44.3147 13.4476Z"
                      fill="black"
                    />
                    <path
                      d="M46.6688 12.0693C46.0754 11.9736 45.5394 11.7247 45.0705 11.3323C44.6015 10.9399 44.2473 10.4326 44.0176 9.80092C43.7879 9.17879 43.7209 8.55667 43.8166 7.95369C43.9124 7.3507 44.1612 6.81472 44.544 6.3553C44.9269 5.89588 45.4246 5.55132 46.018 5.33119C46.621 5.11105 47.2144 5.04405 47.8078 5.13976C48.4012 5.23547 48.9372 5.48432 49.4062 5.86717C49.8752 6.25959 50.2293 6.76686 50.459 7.39856C50.6983 8.03026 50.7653 8.64281 50.6696 9.24579C50.5739 9.84878 50.3346 10.3752 49.9422 10.8442C49.5593 11.3036 49.0616 11.6482 48.4587 11.8779C47.8652 12.098 47.2718 12.165 46.6784 12.0693H46.6688ZM49.2722 10.3943C49.5498 10.0498 49.722 9.64778 49.789 9.18837C49.8465 8.72895 49.789 8.24082 49.5976 7.73355C49.4062 7.21671 49.1382 6.80515 48.7841 6.49887C48.4395 6.19259 48.0471 6.00117 47.6068 5.9246C47.1761 5.84803 46.7263 5.89588 46.2764 6.06817C45.8266 6.24045 45.4533 6.4893 45.1757 6.83386C44.8982 7.17842 44.7259 7.57084 44.6685 8.03983C44.6015 8.49924 44.6685 8.98737 44.8599 9.50421C45.0513 10.0115 45.3193 10.423 45.6734 10.7293C46.018 11.0356 46.42 11.227 46.8507 11.3036C47.2814 11.3802 47.7312 11.3323 48.1811 11.1696C48.6309 11.0069 48.9946 10.7485 49.2722 10.4039V10.3943Z"
                      fill="black"
                    />
                    <path
                      d="M59.4366 1.71326L59.025 8.82463L58.0488 9.04477L55.2731 4.02948L54.8903 9.76261L53.9236 9.99232L50.5354 3.73277L51.4638 3.52221L54.2107 8.85335L54.5266 2.83308L55.5028 2.61295L58.3168 7.9058L58.4986 1.93339L59.4366 1.72283V1.71326Z"
                      fill="black"
                    />
                    <path
                      d="M70.5777 8.84376L69.6876 8.8629L69.5344 0.0191423L70.4245 0L70.5681 8.84376H70.5777Z"
                      fill="black"
                    />
                    <path
                      d="M80.7612 8.41288C80.4358 8.19274 80.2061 7.92475 80.053 7.6089C79.8998 7.29305 79.852 6.94849 79.919 6.58479L80.8569 6.7475C80.8378 7.07292 80.9144 7.36005 81.1058 7.61847C81.2972 7.87689 81.6131 8.0396 82.0533 8.11617C82.4745 8.19274 82.819 8.14489 83.0966 7.9726C83.3741 7.80989 83.5464 7.56104 83.6039 7.22605C83.6517 6.96763 83.6134 6.7475 83.4986 6.55607C83.3837 6.37422 83.2306 6.22108 83.0296 6.09666C82.8286 5.97223 82.5606 5.83823 82.216 5.67552C81.7949 5.4841 81.4599 5.30225 81.2111 5.13954C80.9622 4.97683 80.7708 4.75669 80.6176 4.46956C80.4741 4.19199 80.4358 3.83786 80.5124 3.41673C80.5794 3.05303 80.7325 2.73718 80.9622 2.48833C81.2015 2.23948 81.4982 2.06719 81.8619 1.97148C82.2256 1.87577 82.6276 1.8662 83.0679 1.94277C83.6996 2.05762 84.1877 2.30647 84.5418 2.68932C84.8864 3.07217 85.0395 3.53158 85.0012 4.06757L84.0346 3.89529C84.0441 3.6273 83.9484 3.37844 83.7474 3.13917C83.5464 2.89989 83.2497 2.74675 82.8477 2.67975C82.484 2.61275 82.1682 2.66061 81.9002 2.80417C81.6322 2.95731 81.4695 3.19659 81.4121 3.54115C81.3738 3.79 81.4121 4.00057 81.5173 4.17285C81.6322 4.3547 81.7758 4.49827 81.9672 4.61313C82.1586 4.72798 82.4266 4.87155 82.7712 5.04383C83.1923 5.24482 83.5273 5.42667 83.7761 5.59896C84.025 5.76167 84.226 5.99137 84.3791 6.26894C84.5323 6.5465 84.5705 6.90063 84.494 7.33134C84.4365 7.65676 84.293 7.95346 84.0728 8.21188C83.8527 8.47031 83.5464 8.66173 83.1732 8.78615C82.7999 8.91058 82.3787 8.92972 81.9193 8.84358C81.4791 8.76701 81.0866 8.61387 80.7708 8.39374L80.7612 8.41288Z"
                      fill="black"
                    />
                    <path
                      d="M86.8386 4.4026C87.2789 3.9719 87.7861 3.68476 88.37 3.53162C88.9538 3.38805 89.5472 3.39763 90.1598 3.56991C90.8776 3.7709 91.4519 4.12503 91.8922 4.62273C92.3324 5.12044 92.5813 5.72342 92.6483 6.43168L91.6146 6.13498C91.5476 5.68513 91.3658 5.30229 91.0882 4.99601C90.8011 4.68016 90.4278 4.46002 89.9492 4.32603C89.4898 4.19203 89.0495 4.18246 88.6188 4.29731C88.1881 4.41217 87.8244 4.63231 87.499 4.9673C87.1736 5.30229 86.9439 5.73299 86.7907 6.2594C86.6376 6.78582 86.6185 7.27395 86.7142 7.72379C86.8099 8.17364 87.0109 8.56605 87.3172 8.8819C87.6234 9.19775 88.0063 9.42746 88.4657 9.55189C88.9443 9.68588 89.3845 9.69545 89.7865 9.59017C90.1885 9.48489 90.5426 9.24561 90.8298 8.90105L91.8635 9.19775C91.4423 9.75288 90.9063 10.1357 90.2747 10.3271C89.643 10.5186 88.9634 10.509 88.2456 10.308C87.633 10.1357 87.1162 9.82945 86.7046 9.40832C86.2835 8.97762 86.0059 8.47034 85.8623 7.87693C85.7188 7.28352 85.7379 6.67096 85.9198 6.02012C86.1016 5.37886 86.4079 4.84287 86.8482 4.41217L86.8386 4.4026Z"
                      fill="black"
                    />
                    <path
                      d="M99.8466 7.1689L97.2623 13.505L96.4392 13.17L97.6069 10.2987L94.3814 8.97785L93.2137 11.8492L92.3906 11.5142L94.9748 5.1781L95.798 5.51309L94.659 8.2983L97.8845 9.61912L99.0139 6.83391L99.8466 7.1689Z"
                      fill="black"
                    />
                    <path
                      d="M102.104 9.09242L101.052 11.1215L103.262 12.27L102.927 12.9209L100.717 11.7723L99.635 13.8684L102.114 15.151L101.779 15.8018L98.5056 14.1077L101.655 8.02045L104.928 9.71454L104.593 10.3654L102.114 9.08285L102.104 9.09242Z"
                      fill="black"
                    />
                    <path
                      d="M109.714 13.1604C110.02 13.6868 110.164 14.2515 110.126 14.8449C110.087 15.4383 109.886 16.0317 109.513 16.6156C109.14 17.209 108.68 17.6397 108.164 17.9173C107.647 18.1948 107.072 18.3001 106.469 18.2331C105.866 18.1661 105.244 17.9364 104.613 17.5344L102.813 16.3859L106.508 10.624L108.307 11.7726C108.939 12.1746 109.408 12.6435 109.714 13.17V13.1604ZM107.092 17.41C107.733 17.2664 108.288 16.8357 108.738 16.1274C109.197 15.4096 109.36 14.7205 109.216 14.0696C109.082 13.4188 108.642 12.8541 107.905 12.3755L106.862 11.7056L103.962 16.2423L105.005 16.9123C105.752 17.3908 106.441 17.5535 107.092 17.41Z"
                      fill="black"
                    />
                    <path
                      d="M113.417 15.2756L110.718 18.6638C110.336 19.1424 110.173 19.5827 110.221 20.0038C110.269 20.4249 110.498 20.7886 110.919 21.1236C111.331 21.449 111.742 21.5926 112.154 21.5448C112.566 21.4969 112.968 21.2385 113.35 20.7599L116.049 17.3717L116.748 17.9268L114.059 21.3055C113.705 21.7553 113.312 22.052 112.891 22.2147C112.47 22.3774 112.049 22.4062 111.628 22.32C111.206 22.2243 110.804 22.0233 110.422 21.7266C110.039 21.4203 109.761 21.0758 109.57 20.6833C109.378 20.2909 109.321 19.8698 109.388 19.4295C109.455 18.9892 109.666 18.5394 110.02 18.0896L112.709 14.7109L113.408 15.2661L113.417 15.2756Z"
                      fill="black"
                    />
                    <path
                      d="M114.633 24.2915L116.375 25.9377L115.877 26.4641L113.485 24.2149L118.174 19.2283L118.825 19.8409L114.633 24.301V24.2915Z"
                      fill="black"
                    />
                    <path
                      d="M121.562 23.5739L119.878 25.1244L121.572 26.9525L121.026 27.4502L119.332 25.6221L117.6 27.2205L119.495 29.2687L118.949 29.7664L116.451 27.0578L121.486 22.4062L123.984 25.1148L123.438 25.6125L121.543 23.5643L121.562 23.5739Z"
                      fill="black"
                    />
                    <path
                      d="M124.941 35.0783L123.209 32.6472L121.649 33.0779L121.103 32.3122L128.081 30.3884L128.674 31.2307L124.549 37.184L124.003 36.4183L124.922 35.0879L124.941 35.0783ZM125.382 34.4466L127.487 31.4317L123.955 32.4271L125.391 34.4466H125.382Z"
                      fill="black"
                    />
                    <path
                      d="M135.547 43.3002L129.469 46.3342L129.067 45.5303L133.604 43.2715L128.062 43.5107L127.784 42.946L131.316 38.6486L126.77 40.917L126.368 40.113L132.446 37.0789L132.876 37.9403L128.971 42.6972L135.116 42.4388L135.537 43.2906L135.547 43.3002Z"
                      fill="black"
                    />
                    <path
                      d="M135.968 46.2957L133.824 47.0997L134.704 49.435L134.015 49.6935L133.135 47.3581L130.924 48.1812L131.9 50.7942L131.211 51.0526L129.919 47.607L136.341 45.2046L137.633 48.6502L136.944 48.9086L135.968 46.2957Z"
                      fill="black"
                    />
                    <path
                      d="M137.718 51.3685L135.517 52.0002L136.206 54.393L135.498 54.594L134.809 52.2012L132.54 52.852L133.316 55.5319L132.607 55.7329L131.583 52.1916L138.168 50.2869L139.192 53.8283L138.484 54.0293L137.709 51.3493L137.718 51.3685Z"
                      fill="black"
                    />
                    <path
                      d="M140.456 59.6952L139.748 59.8387L139.374 58.0107L133.383 59.2358L133.201 58.3648L139.192 57.1397L138.819 55.302L139.527 55.1584L140.456 59.6952Z"
                      fill="black"
                    />
                    <path
                      d="M140.772 61.9542L133.996 62.9304L133.872 62.0499L140.648 61.0736L140.772 61.9542Z"
                      fill="black"
                    />
                    <path
                      d="M134.618 69.6875L134.551 68.7974L139.729 64.8254L134.293 65.2178L134.226 64.3277L141.059 63.83L141.126 64.7201L135.958 68.6921L141.385 68.2997L141.452 69.1898L134.618 69.6875Z"
                      fill="black"
                    />
                    <path
                      d="M139.317 76.3873C139.719 76.215 140.045 75.947 140.275 75.5929C140.504 75.2387 140.629 74.8272 140.648 74.3486C140.667 73.8701 140.571 73.4394 140.37 73.0469C140.169 72.6545 139.863 72.3387 139.461 72.109C139.059 71.8697 138.59 71.7453 138.054 71.7261C137.518 71.707 137.039 71.8027 136.628 72.0037C136.216 72.2047 135.891 72.5014 135.661 72.8747C135.432 73.2479 135.298 73.6786 135.288 74.1572C135.259 74.8272 135.441 75.3823 135.824 75.8321C136.207 76.282 136.733 76.55 137.413 76.6457L137.518 73.9179L138.245 73.9466L138.111 77.622L137.422 77.5932C136.858 77.5167 136.35 77.3252 135.901 77.0094C135.441 76.6935 135.087 76.282 134.838 75.7843C134.589 75.2866 134.465 74.7315 134.494 74.1285C134.522 73.4968 134.685 72.9225 135.001 72.4057C135.317 71.8888 135.747 71.4964 136.293 71.2093C136.839 70.9221 137.441 70.7977 138.102 70.8168C138.762 70.836 139.365 71.0178 139.882 71.3433C140.399 71.6687 140.801 72.0994 141.079 72.6354C141.356 73.1714 141.481 73.7552 141.461 74.3869C141.433 75.1143 141.232 75.746 140.849 76.2916C140.466 76.8371 139.949 77.2295 139.279 77.4592L139.317 76.3873Z"
                      fill="black"
                    />
                    <path
                      d="M132.972 86.2744L133.134 85.3938L139.155 82.8766L133.804 81.8621L133.967 80.9815L140.705 82.2545L140.542 83.135L134.532 85.6522L139.872 86.6668L139.71 87.5473L132.972 86.2744Z"
                      fill="black"
                    />
                    <path
                      d="M132.627 88.9927C133.068 88.5811 133.585 88.3036 134.188 88.1696C134.791 88.0356 135.403 88.0643 136.044 88.2653C136.686 88.4663 137.212 88.7821 137.633 89.232C138.054 89.6818 138.332 90.1987 138.456 90.7825C138.581 91.3663 138.562 91.9693 138.37 92.5723C138.179 93.1849 137.863 93.7017 137.423 94.1133C136.982 94.5248 136.466 94.7928 135.872 94.9268C135.279 95.0608 134.657 95.0225 134.015 94.8215C133.374 94.6205 132.838 94.3047 132.426 93.8548C132.005 93.4146 131.737 92.8977 131.603 92.3043C131.469 91.7109 131.508 91.1175 131.69 90.5049C131.881 89.9019 132.187 89.3851 132.627 88.9735V88.9927ZM132.369 92.0842C132.465 92.5149 132.685 92.8977 133.01 93.2327C133.336 93.5677 133.766 93.807 134.283 93.9697C134.81 94.1324 135.298 94.1707 135.757 94.0846C136.217 93.9984 136.6 93.807 136.925 93.5007C137.25 93.204 137.48 92.8212 137.624 92.3617C137.767 91.9023 137.786 91.4525 137.691 91.0218C137.595 90.5911 137.375 90.2178 137.049 89.8828C136.724 89.5478 136.293 89.3085 135.776 89.1458C135.26 88.9831 134.771 88.9448 134.312 89.0405C133.853 89.1363 133.46 89.3181 133.135 89.6244C132.809 89.9307 132.58 90.3039 132.436 90.7634C132.293 91.2228 132.273 91.663 132.379 92.0937L132.369 92.0842Z"
                      fill="black"
                    />
                    <path
                      d="M133.038 105.426L127.659 100.756L128.081 99.8464L133.747 100.708L129.44 96.8985L129.842 95.9892L136.867 97.1282L136.465 97.9896L130.569 96.908L135.144 100.842L134.723 101.751L128.818 100.756L133.44 104.546L133.038 105.417V105.426Z"
                      fill="black"
                    />
                    <path
                      d="M120.606 109.877L121.142 109.159L128.196 114.5L127.66 115.208L120.606 109.877Z"
                      fill="black"
                    />
                    <path
                      d="M114.701 118.233C115.074 118.108 115.428 118.089 115.772 118.166C116.117 118.242 116.414 118.414 116.672 118.682L115.964 119.324C115.725 119.113 115.447 118.998 115.122 118.989C114.806 118.989 114.48 119.132 114.145 119.429C113.83 119.716 113.657 120.022 113.619 120.348C113.581 120.673 113.676 120.951 113.897 121.2C114.069 121.391 114.27 121.506 114.49 121.525C114.71 121.544 114.921 121.525 115.141 121.439C115.361 121.353 115.638 121.228 115.973 121.056C116.385 120.836 116.73 120.683 117.017 120.587C117.294 120.491 117.591 120.472 117.907 120.53C118.223 120.587 118.519 120.769 118.807 121.085C119.055 121.362 119.209 121.669 119.266 122.013C119.323 122.358 119.266 122.702 119.122 123.047C118.979 123.391 118.74 123.717 118.405 124.014C117.926 124.444 117.438 124.683 116.911 124.722C116.395 124.76 115.935 124.597 115.543 124.243L116.27 123.583C116.471 123.755 116.73 123.832 117.045 123.813C117.361 123.793 117.668 123.65 117.955 123.382C118.232 123.133 118.395 122.855 118.433 122.549C118.481 122.252 118.385 121.965 118.146 121.716C117.974 121.535 117.792 121.429 117.581 121.41C117.371 121.391 117.16 121.41 116.959 121.496C116.758 121.582 116.471 121.697 116.127 121.879C115.705 122.09 115.361 122.243 115.074 122.339C114.787 122.434 114.49 122.453 114.174 122.406C113.858 122.348 113.552 122.166 113.265 121.841C113.035 121.592 112.892 121.305 112.825 120.97C112.758 120.635 112.786 120.281 112.92 119.907C113.054 119.534 113.294 119.199 113.638 118.874C113.973 118.568 114.327 118.357 114.701 118.233Z"
                      fill="black"
                    />
                    <path
                      d="M114.164 125.478C114.241 126.081 114.164 126.665 113.915 127.22C113.676 127.775 113.303 128.235 112.795 128.618C112.192 129.058 111.561 129.307 110.9 129.345C110.24 129.383 109.608 129.221 109.015 128.838L109.876 128.196C110.278 128.417 110.68 128.512 111.101 128.474C111.522 128.436 111.934 128.273 112.326 127.976C112.709 127.689 112.987 127.345 113.159 126.943C113.331 126.541 113.379 126.11 113.312 125.65C113.245 125.191 113.044 124.741 112.719 124.301C112.393 123.861 112.02 123.545 111.609 123.344C111.197 123.143 110.766 123.066 110.326 123.114C109.886 123.162 109.474 123.325 109.091 123.612C108.689 123.909 108.412 124.253 108.259 124.636C108.106 125.028 108.067 125.44 108.163 125.88L107.302 126.521C107.12 125.842 107.139 125.191 107.378 124.579C107.617 123.966 108.029 123.43 108.632 122.99C109.139 122.607 109.694 122.387 110.288 122.32C110.881 122.253 111.456 122.339 112.02 122.588C112.585 122.837 113.054 123.229 113.456 123.765C113.858 124.301 114.088 124.875 114.164 125.478Z"
                      fill="black"
                    />
                    <path
                      d="M104.019 134.111L100.573 128.196L101.349 127.746L102.909 130.426L105.924 128.675L104.363 125.995L105.139 125.545L108.584 131.46L107.809 131.91L106.297 129.316L103.282 131.068L104.794 133.661L104.019 134.111Z"
                      fill="black"
                    />
                    <path
                      d="M101.138 134.714L100.172 132.637L97.9129 133.69L97.5971 133.02L99.8559 131.967L98.8605 129.833L96.3337 131.01L96.0178 130.34L99.3486 128.78L102.258 134.991L98.9179 136.552L98.602 135.882L101.129 134.695L101.138 134.714Z"
                      fill="black"
                    />
                    <path
                      d="M93.2701 138.275C92.6575 138.198 92.1311 137.968 91.6813 137.576C91.2314 137.183 90.8869 136.667 90.6476 136.006C90.4083 135.346 90.3413 134.724 90.437 134.14C90.5327 133.556 90.8007 133.049 91.2218 132.608C91.643 132.168 92.2077 131.824 92.9064 131.565L94.9067 130.847L97.223 137.289L95.2226 138.007C94.5239 138.255 93.8635 138.351 93.2605 138.275H93.2701ZM91.4994 133.613C91.2218 134.207 91.2218 134.906 91.509 135.7C91.7961 136.504 92.246 137.049 92.8489 137.337C93.4519 137.624 94.1698 137.624 94.9929 137.337L96.1606 136.915L94.3325 131.852L93.1648 132.273C92.3321 132.57 91.777 133.02 91.4994 133.623V133.613Z"
                      fill="black"
                    />
                    <path
                      d="M89.3368 139.921L88.3031 135.719C88.1596 135.126 87.9012 134.724 87.547 134.513C87.1833 134.303 86.7526 134.255 86.2358 134.379C85.7285 134.504 85.3648 134.743 85.1447 135.107C84.9245 135.461 84.8862 135.939 85.0394 136.533L86.0731 140.735L85.2021 140.945L84.1684 136.753C84.0344 136.198 84.0248 135.71 84.1588 135.279C84.2833 134.848 84.5225 134.494 84.848 134.217C85.1734 133.939 85.5849 133.738 86.0539 133.623C86.5229 133.508 86.9728 133.499 87.3939 133.585C87.815 133.681 88.1883 133.882 88.4946 134.207C88.8104 134.532 89.0306 134.973 89.1645 135.518L90.1982 139.71L89.3273 139.921H89.3368Z"
                      fill="black"
                    />
                    <path
                      d="M81.4504 135.375L79.0863 135.748L78.9714 135.03L82.2161 134.513L83.2976 141.271L82.4171 141.414L81.4504 135.375Z"
                      fill="black"
                    />
                    <path
                      d="M77.7748 141.299L77.5738 139.021L75.0853 139.242L75.0183 138.505L77.5068 138.284L77.3058 135.939L74.5301 136.179L74.4631 135.442L78.1289 135.126L78.7223 141.95L75.0565 142.266L74.9896 141.529L77.7652 141.29L77.7748 141.299Z"
                      fill="black"
                    />
                    <path
                      d="M66.6058 136.944L69.592 137.059L70.2046 135.556L71.1425 135.595L68.4052 142.294L67.3715 142.256L65.1797 135.355L66.1177 135.394L66.6058 136.935V136.944ZM66.8355 137.681L67.9362 141.184L69.3049 137.777L66.8355 137.681Z"
                      fill="black"
                    />
                    <path
                      d="M53.627 140.332L54.9383 133.661L55.8188 133.834L54.8425 138.811L58.0393 134.274L58.6519 134.398L59.8961 139.816L60.8724 134.839L61.7529 135.011L60.4417 141.682L59.4941 141.5L58.1063 135.508L54.5554 140.533L53.6174 140.352L53.627 140.332Z"
                      fill="black"
                    />
                    <path
                      d="M51.0045 138.839L51.6744 136.648L49.2912 135.92L49.5114 135.212L51.8946 135.939L52.5837 133.69L49.9133 132.867L50.1335 132.159L53.6557 133.24L51.6457 139.796L48.1235 138.715L48.3437 138.007L51.014 138.83L51.0045 138.839Z"
                      fill="black"
                    />
                    <path
                      d="M45.9216 137.126L46.7639 135.001L44.4381 134.092L44.7061 133.403L47.0319 134.312L47.8933 132.12L45.2995 131.096L45.5675 130.407L48.9939 131.757L46.4863 138.131L43.0598 136.781L43.3278 136.092L45.9216 137.116V137.126Z"
                      fill="black"
                    />
                    <path
                      d="M37.6528 134.216L37.9687 133.566L39.6436 134.389L42.3331 128.895L43.1371 129.287L40.4476 134.781L42.1321 135.604L41.8163 136.255L37.6528 134.226V134.216Z"
                      fill="black"
                    />
                    <path
                      d="M35.6526 133.078L39.0121 127.115L39.7873 127.555L36.4279 133.518L35.6526 133.078Z"
                      fill="black"
                    />
                    <path
                      d="M33.3072 123.487L34.0538 123.985V130.512L37.0495 125.966L37.7961 126.464L34.0155 132.178L33.2689 131.68V125.162L30.2732 129.699L29.5266 129.211L33.3072 123.497V123.487Z"
                      fill="black"
                    />
                    <path
                      d="M25.1234 123.123C25.0181 123.554 25.0277 123.965 25.1616 124.367C25.2956 124.769 25.5541 125.124 25.9178 125.42C26.2815 125.727 26.6835 125.918 27.1237 125.995C27.564 126.071 27.9947 126.023 28.4254 125.851C28.8561 125.679 29.239 125.382 29.5835 124.97C29.9281 124.559 30.1482 124.128 30.2344 123.669C30.3301 123.219 30.2918 122.788 30.1387 122.367C29.9855 121.956 29.7271 121.592 29.3538 121.286C28.837 120.855 28.2914 120.663 27.7076 120.692C27.1237 120.721 26.5782 120.979 26.0901 121.448L28.1861 123.2L27.7172 123.755L24.8841 121.4L25.3244 120.874C25.7263 120.472 26.1953 120.194 26.7218 120.022C27.2482 119.859 27.7937 119.821 28.3488 119.926C28.904 120.032 29.4112 120.281 29.8707 120.663C30.3588 121.065 30.7129 121.554 30.9235 122.118C31.1341 122.683 31.1915 123.267 31.0862 123.87C30.9809 124.473 30.7129 125.028 30.2822 125.545C29.8515 126.062 29.3538 126.425 28.7796 126.636C28.2053 126.846 27.6214 126.904 27.028 126.799C26.4346 126.693 25.8986 126.435 25.4105 126.033C24.8554 125.564 24.4725 125.018 24.2715 124.387C24.0705 123.755 24.0801 123.104 24.3098 122.434L25.1329 123.123H25.1234Z"
                      fill="black"
                    />
                    <path
                      d="M21.4681 110.662L22.0233 111.361L15.132 116.902L14.5769 116.204L21.4681 110.662Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="rotate-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="105"
                    height="103"
                    viewBox="0 0 105 103"
                    fill="none"
                  >
                    <g opacity="0.5" clipPath="url(#clip0_375_8051)">
                      <g opacity="0.27">
                        <mask
                          id="mask0_375_8051"
                          maskUnits="userSpaceOnUse"
                          x="5"
                          y="2"
                          width="100"
                          height="99"
                        >
                          <path
                            d="M102.811 62.6276C108.912 36.2256 92.4008 9.88917 65.933 3.80349C39.4651 -2.28219 13.063 14.1875 6.96211 40.5895C0.861249 66.9915 17.372 93.3279 43.8398 99.4136C70.3077 105.499 96.7098 89.0297 102.811 62.6276Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_375_8051)">
                          <path
                            d="M42.2323 0.246094C28.6298 14.6485 14.0243 28.0584 0.413818 42.5163"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M48.4567 0.198486C33.302 16.3952 16.3565 32.1314 0.38208 48.1297"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M54.2113 0.206421C36.2231 18.1498 18.1076 36.2124 0.262695 54.0287"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M59.918 0.34137C40.3141 19.8965 20.4475 39.7137 0.310303 59.7849"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M66.9064 0.285828C44.9863 21.6433 22.7876 43.2071 0.374023 65.4141"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M71.4909 0.365234C48.4963 23.9775 25.7086 46.121 2.33198 70.0191C1.75095 70.5986 0.580923 70.5986 0.270508 71.4005"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M77.8427 0.262024C63.6909 15.236 48.4965 28.638 33.302 43.7947C22.1987 55.45 11.0954 65.3585 0.46167 76.9979"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M81.7825 1.4212C54.9197 28.638 26.8788 54.8624 0.38208 82.8176"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M81.7268 7.19324C54.9196 33.8861 27.4598 61.2776 0.477539 88.3435"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M84.6717 10.3293C56.6706 38.5466 28.0408 65.9381 0.493408 94.2109"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M90.4901 10.2976C60.1808 40.2933 30.9619 71.1861 0.23877 100.245"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M95.8864 10.504C64.8528 41.4604 34.4719 71.7657 3.44629 102.714"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M101.562 10.512C70.6951 40.8808 40.3142 71.7657 9.16919 102.77"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.618 13.132C74.7862 42.6275 45.5753 72.9328 14.9158 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.793 18.6262C76.5373 46.7085 49.6664 75.8467 20.6943 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.578 24.9302C78.2964 50.7815 52.5876 77.0138 26.4968 102.762"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.745 30.2736C80.6283 54.2828 56.6706 78.7684 32.3149 102.714"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.682 36.1489C82.3795 58.3638 60.1809 80.5152 38.022 102.786"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.753 41.905C84.7195 62.4368 64.2719 82.8414 43.8879 102.69"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.682 47.7883C86.4785 65.9461 67.7819 84.5961 49.6187 102.738"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.522 53.7509C88.2215 70.019 71.865 86.3348 55.3652 102.762"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.705 59.1577C93.3634 70.424 82.0372 81.7061 70.7747 93.0438C67.5511 96.2831 64.3356 99.5304 61.1279 102.786"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.666 65.6125C92.3208 78.1809 79.4664 91.0033 66.8906 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_375_8051">
                        <rect width="105" height="103" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="rotate-link">
                  let's
                  <br />
                  go
                </div>
                <div className="rotate-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M1.24264 16.4455L16.4454 1.24266M16.4454 1.24266H7.96015M16.4454 1.24266V9.72795"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="globalBanner-text">
                <h5>
                  Bringing Brands to Life with Branding and Internet Marketing
                  Services
                </h5>
                <p className="secondary">
                  Branding services play a crucial role in the success and
                  identity of businesses and organizations. They encompass a
                  wide range of activities and strategies designed to create a
                  distinct and memorable image for a company, product, or
                  service. Effective branding services can help businesses
                  establish a strong presence in the market, build trust and
                  loyalty among their target audience, and differentiate
                  themselves from competitors.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="globalBanner-img">
                <img src={BrandingBanner} alt="" />
              </div>
            </Col>
          </Row>
          <Link className="phNumber">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M31.8617 19.6763C32.2677 12.5406 26.8122 6.42678 19.6765 6.02077C12.5408 5.61475 6.42698 11.0702 6.02097 18.2059C5.61495 25.3416 11.0704 31.4554 18.2061 31.8615C21.6331 32.0565 24.9972 30.882 27.5581 28.5965C30.1187 26.3114 31.6667 23.1027 31.8617 19.6763ZM19.5781 27.2949C19.578 27.2949 19.5779 27.2949 19.5778 27.2949C19.577 27.2949 19.5766 27.2939 19.5772 27.2934C19.5779 27.2928 19.5775 27.2918 19.5767 27.2918L18.6563 27.2362C17.451 27.175 16.3652 26.4886 15.793 25.426C15.2419 24.3328 14.8695 23.1584 14.6899 21.9474C14.5105 20.8335 14.454 19.7032 14.5217 18.577C14.5766 17.5473 14.7343 16.5256 14.992 15.5272C15.2627 14.4453 15.6818 13.4061 16.2376 12.4391C16.8971 11.3107 18.1376 10.6517 19.4419 10.7369L20.526 10.7987C20.8281 10.8153 21.0596 11.0737 21.043 11.3758C21.043 11.3765 21.043 11.3774 21.0429 11.3782L20.8476 14.8011C20.831 15.1032 20.5727 15.3347 20.2706 15.3181C20.2698 15.3181 20.269 15.318 20.2681 15.318L18.2582 15.2033C17.6848 15.1674 17.1716 15.5565 17.0512 16.1183C16.8666 16.9728 16.7497 17.8406 16.7014 18.7136C16.6436 19.6905 16.6725 20.6706 16.7879 21.6424C16.8552 22.2034 17.314 22.6359 17.878 22.67L19.837 22.7665C20.1391 22.7831 20.3706 23.0414 20.354 23.3435C20.354 23.3443 20.3539 23.3451 20.3539 23.346L20.155 26.7779C20.1386 27.08 19.8802 27.3115 19.5781 27.2949Z"
                  fill="black"
                />
              </svg>
            </span>
            (033) 4804-7123
          </Link>
        </Container>
        <div className="innerLine">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>

      <section className="businessIntro services">
        <Container>
          <Row>
            <Col sm={10}>
              <h3 className="text-white">
                Making Every Brand Reflect Their Message Worldwide
              </h3>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={5}>
              <div className="businessIntro-image">
                <img src={BrandingSub} alt="" />
              </div>
            </Col>
            <Col md={7} className="mt-4 mt-md-0">
              <p className="primary">
                Service branding is a marketing strategy in which a provider’s
                services are transformed into products that can be uniquely
                marketed to maximize reach and sales. Service branding is
                critical in contributing to value creation and economic growth
                at the corporate level. Spreading your brand’s message to the
                world will help you gain more potential traffic.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceCarousel bottomSpace">
        <Container>
          <Row className="justify-content-center">
            <Col xl={9}>
              <h3>Gain More Business Recognition with Branding Services</h3>
            </Col>
          </Row>
          <div className="imageSlider">
            <Swiper
              spaceBetween={0}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 5,
                slideShadows: true,
              }}
              pagination={false}
              modules={[EffectCoverflow, Pagination, Autoplay]}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              className="mySlider"
            >
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={BrandSlider1} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Corporate Branding</h4>
                    <p>
                      Corporate branding extends beyond website design and
                      advertisements. It includes how the company conducts
                      itself professionally, like partnering with NGOs or
                      responding to current events. Corporate branding
                      frequently extends to a company’s recruiting efforts and
                      culture, shaping how the public perceives the brand.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={BrandSlider2} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Product Branding</h4>
                    <p>
                      The action of branding a specific product is known as
                      product branding. Product branding transforms how the
                      world perceives your product through deliberate aesthetic
                      choices, just as personal branding includes cultivating a
                      public vocabulary and aesthetic for yourself.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={BrandSlider3} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Online Branding</h4>
                    <p>
                      Online branding is branding that occurs online. Unlike
                      other types of branding, online branding is a broad
                      category that refers to all types of branding that takes
                      place over the internet. It’s how people position
                      themselves on social media, the type of online ads a
                      service provider runs, and all of the design decisions
                      that go into email newsletters, landing pages, responsive
                      web design, etc.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={BrandSlider2} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Offline Branding</h4>
                    <p>
                      Offline branding, like online branding, can include
                      personal branding, product branding, corporate branding,
                      and geographic branding. Offline branding includes
                      merchandise and print products. Retail branding is offline
                      branding. Personal branding is important to bring to a
                      client meeting or industry conference.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={BrandSlider2} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Trend Awareness</h4>
                    <p>
                      Staying active on social media helps businesses keep up
                      with industry trends and consumer preferences. Besides,
                      most of your customers are staying online now on social
                      media.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={BrandSlider2} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Measurable Results</h4>
                    <p>
                      Metrics and analytics tools allow for the tracking of
                      campaign effectiveness. This helps in gaining competitive
                      advantage over your competitors.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>

      <section className="points dark">
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="text-white">
                Powering Businesses with Digital Branding
              </h3>
            </Col>
            <Col className="position-relative">
              <div className="development">
                <img src={BrandingList} alt="" />
              </div>
            </Col>
          </Row>
          <ul>
            <li>
              <div className="d-flex align-items-center">
                <span>Display More Commitment</span>
              </div>
              <p className="primary">
                The promise you make to your audience, customers, or clients is
                your branding strategy. It’s what they can expect every time
                they interact with your products or services, and it keeps you
                consistent. It’s easy to stay in line and keep giving your
                customers what they want when you know what kind of experience
                you’re selling them.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <span>Drive More Emotional Reactions</span>
              </div>
              <p className="primary">
                Whether you’re selling complex products or seemingly
                insignificant services, appealing to people’s emotions is the
                most effective branding strategy. This is due to the fact that
                humans are highly emotional creatures who rely on instincts and
                feelings to make decisions and navigate a wide range of
                situations.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <span>Create Financial Value</span>
              </div>
              <p className="primary">
                Your story, and how you tell it, will influence your sales
                figures, market opportunities, and the talent pool you attract.
                Similarly, it will better equip you to attract shareholders and
                investors who can provide you with the resources and support you
                need to expand your company’s brand, avoid financial distress,
                and gain a competitive advantage.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <span>Solidifies Mission</span>
              </div>
              <p className="primary">
                Your brand’s identity helps to solidify your mission.When you
                have faith in your brand, which is your company’s personality
                and identity, you can stand firm in your mission. Be a business
                with a purpose, and make sure others know about it!
              </p>
            </li>
          </ul>
          <Row className="d-none d-md-block">
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center mt-5"
            >
              <div className="rotate white">
                <div className="rotate-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="142"
                    height="143"
                    viewBox="0 0 142 143"
                    fill="none"
                  >
                    <path
                      d="M14.4912 103.024C14.5103 103.416 14.4433 103.761 14.2902 104.077C14.1275 104.392 13.8882 104.632 13.5628 104.814L13.1225 103.971C13.3905 103.789 13.5723 103.55 13.6585 103.244C13.7446 102.938 13.6872 102.583 13.4766 102.191C13.2756 101.818 13.0268 101.569 12.7301 101.444C12.4334 101.32 12.1271 101.339 11.8304 101.502C11.6007 101.626 11.4475 101.789 11.3614 101.99C11.2848 102.191 11.2561 102.411 11.2848 102.641C11.3135 102.871 11.3614 103.167 11.4475 103.541C11.5528 103.99 11.6198 104.373 11.639 104.67C11.6581 104.967 11.6007 105.254 11.4667 105.541C11.3327 105.828 11.0743 106.067 10.701 106.268C10.366 106.441 10.031 106.508 9.68645 106.479C9.34189 106.441 9.02604 106.307 8.71976 106.077C8.42305 105.847 8.16463 105.531 7.96364 105.139C7.66693 104.574 7.56165 104.029 7.65736 103.521C7.75307 103.014 8.02106 102.612 8.47091 102.315L8.93033 103.186C8.71019 103.34 8.57619 103.569 8.50919 103.876C8.4422 104.182 8.50919 104.517 8.69105 104.871C8.86333 105.206 9.09303 105.426 9.3706 105.551C9.64816 105.675 9.94487 105.646 10.2511 105.493C10.4713 105.378 10.6149 105.225 10.6914 105.024C10.768 104.823 10.7871 104.622 10.768 104.392C10.7488 104.172 10.6914 103.876 10.6149 103.493C10.5191 103.033 10.4521 102.66 10.433 102.354C10.4139 102.057 10.4713 101.76 10.6053 101.464C10.7393 101.167 10.9977 100.928 11.3805 100.727C11.6772 100.574 11.9931 100.507 12.3377 100.526C12.6822 100.545 13.0172 100.669 13.3331 100.889C13.6585 101.109 13.9265 101.435 14.1466 101.846C14.3572 102.248 14.472 102.641 14.4912 103.033V103.024Z"
                      fill="white"
                    />
                    <path
                      d="M7.60886 100.65C7.00588 100.564 6.46032 100.344 5.99133 99.9703C5.52235 99.597 5.16821 99.1184 4.92893 98.525C4.65137 97.8359 4.5748 97.1564 4.7088 96.5055C4.83322 95.8547 5.15864 95.29 5.68505 94.8114L6.07747 95.8068C5.76162 96.1322 5.5702 96.5055 5.49363 96.9267C5.41706 97.3382 5.47449 97.7785 5.65634 98.2379C5.83819 98.6782 6.09661 99.0419 6.44118 99.3099C6.78574 99.5779 7.1973 99.7406 7.65672 99.7884C8.11613 99.8363 8.60426 99.7597 9.11153 99.5587C9.6188 99.3577 10.0208 99.0802 10.3175 98.726C10.6142 98.3719 10.7961 97.9795 10.8631 97.5488C10.9301 97.1181 10.8726 96.6778 10.7003 96.228C10.5185 95.7685 10.2601 95.4144 9.92508 95.156C9.59009 94.9071 9.19767 94.7731 8.73826 94.7444L8.34584 93.749C9.04453 93.749 9.66666 93.9309 10.2026 94.3137C10.7386 94.6966 11.1502 95.2326 11.4278 95.9312C11.667 96.5247 11.734 97.1085 11.6575 97.7019C11.5713 98.2953 11.3416 98.8313 10.9588 99.3099C10.5759 99.7884 10.0782 100.152 9.4561 100.391C8.83397 100.64 8.22141 100.717 7.61843 100.64L7.60886 100.65Z"
                      fill="white"
                    />
                    <path
                      d="M1.86523 88.6759L8.45977 86.8574L8.69905 87.7188L5.71284 88.5419L6.64125 91.9014L9.63702 91.0783L9.8763 91.9397L3.28177 93.7582L3.04249 92.8968L5.94255 92.1024L5.01415 88.7429L2.11408 89.5469L1.87481 88.6855L1.86523 88.6759Z"
                      fill="white"
                    />
                    <path
                      d="M2.02003 85.6994L4.26926 85.2974L3.82898 82.8472L4.55639 82.7132L4.99666 85.1634L7.31289 84.7423L6.81519 81.9953L7.54259 81.8613L8.19343 85.4888L1.44576 86.7043L0.794922 83.0769L1.52233 82.9429L2.02003 85.6898V85.6994Z"
                      fill="white"
                    />
                    <path
                      d="M0.593395 77.1903C0.823103 76.6256 1.18681 76.1662 1.67494 75.8312C2.16307 75.4962 2.75648 75.2952 3.45517 75.2378C4.15387 75.1804 4.76642 75.2665 5.31198 75.5153C5.84797 75.7642 6.27867 76.147 6.59452 76.6639C6.91037 77.1807 7.10179 77.8124 7.16879 78.559L7.35064 80.6838L0.535968 81.2772L0.354116 79.1524C0.287118 78.4058 0.373259 77.755 0.602967 77.1807L0.593395 77.1903ZM5.56083 76.6735C5.05356 76.2523 4.38358 76.08 3.54131 76.147C2.68948 76.2236 2.04821 76.5107 1.61751 77.018C1.18681 77.5253 1.00496 78.2144 1.08152 79.095L1.18681 80.3296L6.54666 79.8607L6.44138 78.626C6.36481 77.7454 6.07767 77.0946 5.5704 76.6735H5.56083Z"
                      fill="white"
                    />
                    <path
                      d="M0.00957117 72.9508L4.33574 73.0178C4.94829 73.0273 5.39814 72.8838 5.69484 72.5966C5.99155 72.2999 6.14469 71.8884 6.15426 71.362C6.15426 70.8355 6.02026 70.424 5.73313 70.1273C5.44599 69.821 4.99615 69.6679 4.38359 69.6583L0.057427 69.5913L0.0765693 68.7012L4.39317 68.7682C4.96744 68.7682 5.43642 68.9022 5.82884 69.1319C6.21169 69.3712 6.49882 69.6774 6.68067 70.0698C6.86253 70.4623 6.94867 70.9025 6.94867 71.3811C6.94867 71.8692 6.84338 72.2999 6.64239 72.6828C6.45097 73.0656 6.15426 73.3719 5.76184 73.592C5.36942 73.8122 4.89087 73.9175 4.3166 73.9079L0 73.8409L0.0191423 72.9508H0.00957117Z"
                      fill="white"
                    />
                    <path
                      d="M6.40192 66.5092L6.63163 64.126L7.35904 64.193L7.04319 67.4663L0.228516 66.8059L0.314656 65.9158L6.40192 66.5092Z"
                      fill="white"
                    />
                    <path
                      d="M1.58797 61.4563L3.84676 61.8392L4.26789 59.3794L4.9953 59.5038L4.57417 61.9636L6.89996 62.356L7.36895 59.6091L8.09636 59.7335L7.48381 63.361L0.726562 62.2124L1.33912 58.585L2.06653 58.7094L1.59754 61.4563H1.58797Z"
                      fill="white"
                    />
                    <path
                      d="M8.64132 51.7507L7.77035 54.6029L9.06245 55.5791L8.78489 56.4788L3.00391 52.1239L3.31018 51.1381L10.5364 50.7744L10.2589 51.6741L8.64132 51.7507ZM7.87563 51.7794L4.20987 51.9517L7.14822 54.1435L7.87563 51.7794Z"
                      fill="white"
                    />
                    <path
                      d="M8.69012 38.3408L14.7965 41.3175L14.4041 42.1214L9.84823 39.9009L13.4183 44.1505L13.1407 44.7152L7.57986 44.5334L12.1453 46.7539L11.7529 47.5579L5.64648 44.5812L6.06762 43.7198L12.2219 43.9208L8.26898 39.2022L8.69012 38.3408Z"
                      fill="white"
                    />
                    <path
                      d="M10.796 36.1971L12.7389 37.4031L14.0598 35.2879L14.6819 35.6803L13.3611 37.7955L15.3614 39.0398L16.8354 36.6757L17.4575 37.0681L15.5146 40.1979L9.69531 36.58L11.6383 33.4502L12.2604 33.8426L10.7864 36.2067L10.796 36.1971Z"
                      fill="white"
                    />
                    <path
                      d="M13.7622 31.6897L15.6094 33.0392L17.0834 31.0293L17.6768 31.4696L16.2029 33.4795L18.0979 34.8769L19.7442 32.6277L20.3376 33.0679L18.1554 36.035L12.6328 31.9768L14.815 29.0098L15.4085 29.45L13.7622 31.6993V31.6897Z"
                      fill="white"
                    />
                    <path
                      d="M18.6731 24.4541L19.2282 24.9327L18.0127 26.3492L22.6452 30.3404L22.0613 31.0199L17.4289 27.0287L16.2038 28.4453L15.6582 27.9667L18.6827 24.4541H18.6731Z"
                      fill="white"
                    />
                    <path
                      d="M20.2803 22.8174L25.1903 27.5838L24.5682 28.2251L19.6582 23.4587L20.2803 22.8174Z"
                      fill="white"
                    />
                    <path
                      d="M30.1476 22.9801L29.4777 23.5736L23.1607 21.9082L26.7977 25.9663L26.1278 26.5598L21.5527 21.4488L22.2227 20.8553L28.5301 22.5112L24.9026 18.4626L25.5726 17.8691L30.1476 22.9706V22.9801Z"
                      fill="white"
                    />
                    <path
                      d="M32.5902 15.1506C32.2073 14.9401 31.7958 14.8444 31.3842 14.8731C30.9631 14.9018 30.5611 15.0549 30.1687 15.3325C29.7762 15.6101 29.4891 15.945 29.3073 16.347C29.1254 16.749 29.0584 17.1797 29.1158 17.6391C29.1733 18.0986 29.3647 18.5484 29.671 18.9887C29.9772 19.4289 30.3409 19.7448 30.7621 19.9458C31.1736 20.1468 31.6043 20.2329 32.0446 20.1851C32.4849 20.1372 32.8964 19.9841 33.2889 19.7065C33.8344 19.3237 34.1598 18.8355 34.2843 18.2613C34.4087 17.687 34.2938 17.1032 33.9588 16.5002L31.7383 18.089L31.3172 17.4956L34.313 15.3612L34.715 15.9163C35.0021 16.4045 35.1552 16.9309 35.184 17.486C35.2127 18.0411 35.1074 18.5771 34.8585 19.0748C34.6193 19.5821 34.246 20.0128 33.7579 20.3574C33.241 20.7306 32.6859 20.9412 32.0829 20.9986C31.4799 21.056 30.9056 20.9603 30.3505 20.7115C29.7954 20.4531 29.3264 20.0606 28.934 19.5151C28.5416 18.9695 28.3214 18.3953 28.2544 17.7827C28.197 17.1702 28.2927 16.5959 28.5416 16.0503C28.7904 15.5048 29.1828 15.0454 29.6997 14.6816C30.2931 14.2605 30.9152 14.0308 31.5852 14.0021C32.2456 13.9734 32.8773 14.1457 33.4611 14.5285L32.5902 15.1506Z"
                      fill="white"
                    />
                    <path
                      d="M44.3142 14.1365L43.5198 14.5385L37.8536 11.3034L40.3134 16.1656L39.519 16.5676L36.418 10.4516L37.2124 10.0496L42.8785 13.2751L40.4187 8.4225L41.2131 8.02051L44.3142 14.1365Z"
                      fill="white"
                    />
                    <path
                      d="M46.6679 12.7581C46.0745 12.6624 45.5385 12.4136 45.0695 12.0212C44.6005 11.6287 44.2464 11.1215 44.0167 10.4898C43.7869 9.86764 43.72 9.24551 43.8157 8.64253C43.9114 8.03955 44.1602 7.50356 44.5431 7.04414C44.9259 6.58473 45.4236 6.24017 46.017 6.02003C46.62 5.79989 47.2134 5.73289 47.8068 5.82861C48.4003 5.92432 48.9362 6.17317 49.4052 6.55601C49.8742 6.94843 50.2283 7.4557 50.4581 8.0874C50.6973 8.7191 50.7643 9.33165 50.6686 9.93464C50.5729 10.5376 50.3336 11.064 49.9412 11.533C49.5584 11.9924 49.0607 12.337 48.4577 12.5667C47.8643 12.7868 47.2709 12.8538 46.6774 12.7581H46.6679ZM49.2712 11.0832C49.5488 10.7386 49.7211 10.3366 49.7881 9.87721C49.8455 9.41779 49.7881 8.92966 49.5966 8.42239C49.4052 7.90555 49.1372 7.49399 48.7831 7.18771C48.4385 6.88143 48.0461 6.69001 47.6058 6.61344C47.1751 6.53687 46.7253 6.58473 46.2755 6.75701C45.8256 6.92929 45.4523 7.17814 45.1748 7.5227C44.8972 7.86726 44.7249 8.25968 44.6675 8.72867C44.6005 9.18808 44.6675 9.67621 44.8589 10.1931C45.0503 10.7003 45.3183 11.1119 45.6725 11.4182C46.017 11.7244 46.419 11.9159 46.8497 11.9924C47.2804 12.069 47.7303 12.0212 48.1801 11.8584C48.63 11.6957 48.9937 11.4373 49.2712 11.0927V11.0832Z"
                      fill="white"
                    />
                    <path
                      d="M59.4363 2.40137L59.0248 9.51274L58.0485 9.73288L55.2729 4.71759L54.89 10.4507L53.9233 10.6804L50.5352 4.42088L51.4636 4.21032L54.2105 9.54146L54.5263 3.52119L55.5026 3.30106L58.3165 8.59391L58.4984 2.6215L59.4363 2.41094V2.40137Z"
                      fill="white"
                    />
                    <path
                      d="M70.5784 9.53223L69.6883 9.55138L69.5352 0.707619L70.4253 0.688477L70.5688 9.53223H70.5784Z"
                      fill="white"
                    />
                    <path
                      d="M80.7605 9.10123C80.4351 8.8811 80.2054 8.6131 80.0522 8.29725C79.8991 7.98141 79.8512 7.63684 79.9182 7.27314L80.8562 7.43585C80.8371 7.76127 80.9136 8.0484 81.105 8.30683C81.2965 8.56525 81.6123 8.72796 82.0526 8.80453C82.4737 8.8811 82.8183 8.83324 83.0959 8.66096C83.3734 8.49825 83.5457 8.2494 83.6031 7.91441C83.651 7.65599 83.6127 7.43585 83.4978 7.24443C83.383 7.06257 83.2298 6.90944 83.0289 6.78501C82.8279 6.66059 82.5599 6.52659 82.2153 6.36388C81.7942 6.17246 81.4592 5.9906 81.2103 5.82789C80.9615 5.66518 80.7701 5.44505 80.6169 5.15791C80.4733 4.88035 80.4351 4.52622 80.5116 4.10508C80.5786 3.74138 80.7318 3.42553 80.9615 3.17668C81.2008 2.92783 81.4975 2.75555 81.8612 2.65984C82.2249 2.56413 82.6269 2.55456 83.0671 2.63112C83.6988 2.74598 84.187 2.99483 84.5411 3.37768C84.8857 3.76052 85.0388 4.21994 85.0005 4.75592L84.0338 4.58364C84.0434 4.31565 83.9477 4.0668 83.7467 3.82752C83.5457 3.58824 83.249 3.4351 82.847 3.3681C82.4833 3.30111 82.1674 3.34896 81.8995 3.49253C81.6315 3.64567 81.4688 3.88495 81.4113 4.22951C81.373 4.47836 81.4113 4.68893 81.5166 4.86121C81.6315 5.04306 81.775 5.18663 81.9665 5.30148C82.1579 5.41633 82.4259 5.5599 82.7704 5.73218C83.1916 5.93318 83.5266 6.11503 83.7754 6.28731C84.0243 6.45002 84.2252 6.67973 84.3784 6.95729C84.5315 7.23486 84.5698 7.58899 84.4932 8.01969C84.4358 8.34511 84.2922 8.64182 84.0721 8.90024C83.852 9.15866 83.5457 9.35008 83.1724 9.47451C82.7991 9.59893 82.378 9.61808 81.9186 9.53194C81.4783 9.45537 81.0859 9.30223 80.7701 9.08209L80.7605 9.10123Z"
                      fill="white"
                    />
                    <path
                      d="M86.8388 5.09107C87.2791 4.66037 87.7864 4.37324 88.3702 4.2201C88.9541 4.07653 89.5475 4.0861 90.16 4.25838C90.8779 4.45938 91.4521 4.81351 91.8924 5.31121C92.3327 5.80891 92.5815 6.4119 92.6485 7.12016L91.6149 6.82346C91.5479 6.37361 91.366 5.99076 91.0884 5.68449C90.8013 5.36864 90.428 5.1485 89.9495 5.01451C89.4901 4.88051 89.0498 4.87094 88.6191 4.98579C88.1884 5.10065 87.8247 5.32078 87.4993 5.65577C87.1738 5.99076 86.9441 6.42147 86.791 6.94788C86.6379 7.47429 86.6187 7.96242 86.7144 8.41227C86.8101 8.86211 87.0111 9.25453 87.3174 9.57038C87.6237 9.88623 88.0065 10.1159 88.4659 10.2404C88.9445 10.3744 89.3848 10.3839 89.7868 10.2786C90.1888 10.1734 90.5429 9.93408 90.83 9.58952L91.8637 9.88623C91.4426 10.4414 90.9066 10.8242 90.2749 11.0156C89.6432 11.207 88.9636 11.1975 88.2458 10.9965C87.6333 10.8242 87.1164 10.5179 86.7048 10.0968C86.2837 9.66609 86.0062 9.15882 85.8626 8.56541C85.719 7.972 85.7382 7.35944 85.92 6.7086C86.1019 6.06733 86.4081 5.53135 86.8484 5.10065L86.8388 5.09107Z"
                      fill="white"
                    />
                    <path
                      d="M99.8466 7.85701L97.2623 14.1931L96.4392 13.8581L97.6069 10.9868L94.3814 9.66596L93.2137 12.5373L92.3906 12.2023L94.9748 5.86621L95.798 6.2012L94.659 8.98641L97.8845 10.3072L99.0139 7.52202L99.8466 7.85701Z"
                      fill="white"
                    />
                    <path
                      d="M102.105 9.78095L101.052 11.81L103.263 12.9586L102.928 13.6094L100.717 12.4609L99.6353 14.557L102.114 15.8395L101.779 16.4903L98.5059 14.7962L101.655 8.70898L104.928 10.4031L104.593 11.0539L102.114 9.77138L102.105 9.78095Z"
                      fill="white"
                    />
                    <path
                      d="M109.713 13.8489C110.02 14.3753 110.163 14.94 110.125 15.5334C110.087 16.1268 109.886 16.7202 109.512 17.3041C109.139 17.8975 108.68 18.3282 108.163 18.6057C107.646 18.8833 107.072 18.9886 106.469 18.9216C105.866 18.8546 105.244 18.6249 104.612 18.2229L102.812 17.0743L106.507 11.3125L108.306 12.461C108.938 12.863 109.407 13.332 109.713 13.8584V13.8489ZM107.091 18.0985C107.732 17.9549 108.287 17.5242 108.737 16.8159C109.196 16.0981 109.359 15.409 109.216 14.7581C109.082 14.1073 108.641 13.5426 107.904 13.064L106.861 12.394L103.961 16.9308L105.004 17.6008C105.751 18.0793 106.44 18.242 107.091 18.0985Z"
                      fill="white"
                    />
                    <path
                      d="M113.418 15.9641L110.719 19.3523C110.336 19.8309 110.173 20.2711 110.221 20.6923C110.269 21.1134 110.498 21.4771 110.92 21.8121C111.331 22.1375 111.743 22.2811 112.154 22.2332C112.566 22.1854 112.968 21.927 113.351 21.4484L116.05 18.0602L116.748 18.6153L114.059 21.9939C113.705 22.4438 113.312 22.7405 112.891 22.9032C112.47 23.0659 112.049 23.0946 111.628 23.0085C111.207 22.9128 110.805 22.7118 110.422 22.4151C110.039 22.1088 109.761 21.7642 109.57 21.3718C109.379 20.9794 109.321 20.5583 109.388 20.118C109.455 19.6777 109.666 19.2279 110.02 18.778L112.709 15.3994L113.408 15.9545L113.418 15.9641Z"
                      fill="white"
                    />
                    <path
                      d="M114.633 24.9801L116.375 26.6264L115.877 27.1528L113.484 24.9036L118.174 19.917L118.825 20.5295L114.633 24.9897V24.9801Z"
                      fill="white"
                    />
                    <path
                      d="M121.562 24.2624L119.878 25.8129L121.572 27.641L121.026 28.1387L119.332 26.3106L117.6 27.909L119.495 29.9573L118.949 30.455L116.451 27.7463L121.486 23.0947L123.984 25.8034L123.438 26.3011L121.543 24.2528L121.562 24.2624Z"
                      fill="white"
                    />
                    <path
                      d="M124.942 35.767L123.209 33.3359L121.649 33.7666L121.104 33.001L128.081 31.0771L128.674 31.9194L124.549 37.8727L124.004 37.107L124.922 35.7766L124.942 35.767ZM125.382 35.1353L127.487 32.1204L123.956 33.1158L125.391 35.1353H125.382Z"
                      fill="white"
                    />
                    <path
                      d="M135.546 43.9888L129.468 47.0229L129.066 46.2189L133.603 43.9601L128.061 44.1994L127.784 43.6347L131.315 39.3372L126.769 41.6056L126.367 40.8016L132.445 37.7676L132.876 38.629L128.971 43.3859L135.115 43.1274L135.536 43.9793L135.546 43.9888Z"
                      fill="white"
                    />
                    <path
                      d="M135.967 46.9847L133.823 47.7886L134.704 50.124L134.014 50.3824L133.134 48.0471L130.923 48.8702L131.899 51.4831L131.21 51.7415L129.918 48.2959L136.34 45.8936L137.632 49.3392L136.943 49.5976L135.967 46.9847Z"
                      fill="white"
                    />
                    <path
                      d="M137.719 52.0571L135.518 52.6888L136.207 55.0816L135.499 55.2826L134.809 52.8898L132.541 53.5407L133.316 56.2206L132.608 56.4216L131.584 52.8802L138.169 50.9756L139.193 54.5169L138.485 54.7179L137.71 52.038L137.719 52.0571Z"
                      fill="white"
                    />
                    <path
                      d="M140.456 60.3834L139.748 60.527L139.375 58.6989L133.383 59.924L133.201 59.053L139.193 57.8279L138.819 55.9902L139.528 55.8467L140.456 60.3834Z"
                      fill="white"
                    />
                    <path
                      d="M140.772 62.6423L133.996 63.6185L133.871 62.738L140.647 61.7617L140.772 62.6423Z"
                      fill="white"
                    />
                    <path
                      d="M134.617 70.3761L134.55 69.486L139.728 65.514L134.292 65.9064L134.225 65.0163L141.058 64.5186L141.125 65.4087L135.957 69.3807L141.384 68.9883L141.451 69.8784L134.617 70.3761Z"
                      fill="white"
                    />
                    <path
                      d="M139.318 77.0762C139.72 76.904 140.046 76.636 140.275 76.2818C140.505 75.9277 140.629 75.5161 140.649 75.0376C140.668 74.559 140.572 74.1283 140.371 73.7359C140.17 73.3435 139.864 73.0276 139.462 72.7979C139.06 72.5586 138.591 72.4342 138.055 72.4151C137.519 72.3959 137.04 72.4917 136.629 72.6926C136.217 72.8936 135.892 73.1903 135.662 73.5636C135.432 73.9369 135.298 74.3676 135.289 74.8462C135.26 75.5161 135.442 76.0713 135.825 76.5211C136.208 76.971 136.734 77.2389 137.414 77.3347L137.519 74.6069L138.246 74.6356L138.112 78.3109L137.423 78.2822C136.858 78.2056 136.351 78.0142 135.901 77.6984C135.442 77.3825 135.088 76.971 134.839 76.4733C134.59 75.9756 134.466 75.4204 134.494 74.8174C134.523 74.1857 134.686 73.6115 135.002 73.0946C135.317 72.5778 135.748 72.1854 136.294 71.8982C136.839 71.6111 137.442 71.4867 138.103 71.5058C138.763 71.525 139.366 71.7068 139.883 72.0322C140.4 72.3577 140.802 72.7884 141.079 73.3243C141.357 73.8603 141.481 74.4442 141.462 75.0759C141.433 75.8033 141.232 76.435 140.85 76.9805C140.467 77.5261 139.95 77.9185 139.28 78.1482L139.318 77.0762Z"
                      fill="white"
                    />
                    <path
                      d="M132.971 86.9628L133.133 86.0822L139.154 83.565L133.803 82.5505L133.966 81.6699L140.704 82.9429L140.541 83.8234L134.531 86.3406L139.872 87.3552L139.709 88.2357L132.971 86.9628Z"
                      fill="white"
                    />
                    <path
                      d="M132.627 89.6816C133.068 89.27 133.585 88.9925 134.188 88.8585C134.791 88.7245 135.403 88.7532 136.044 88.9542C136.686 89.1552 137.212 89.471 137.633 89.9209C138.054 90.3707 138.332 90.8876 138.456 91.4714C138.581 92.0552 138.562 92.6582 138.37 93.2612C138.179 93.8738 137.863 94.3906 137.423 94.8022C136.982 95.2137 136.466 95.4817 135.872 95.6157C135.279 95.7497 134.657 95.7114 134.015 95.5104C133.374 95.3094 132.838 94.9936 132.426 94.5437C132.005 94.1035 131.737 93.5866 131.603 92.9932C131.469 92.3998 131.508 91.8064 131.69 91.1938C131.881 90.5909 132.187 90.074 132.627 89.6624V89.6816ZM132.369 92.7731C132.465 93.2038 132.685 93.5866 133.01 93.9216C133.336 94.2566 133.766 94.4959 134.283 94.6586C134.81 94.8213 135.298 94.8596 135.757 94.7735C136.217 94.6873 136.6 94.4959 136.925 94.1896C137.25 93.8929 137.48 93.5101 137.624 93.0506C137.767 92.5912 137.786 92.1414 137.691 91.7107C137.595 91.28 137.375 90.9067 137.049 90.5717C136.724 90.2367 136.293 89.9974 135.776 89.8347C135.26 89.672 134.771 89.6337 134.312 89.7294C133.853 89.8252 133.46 90.007 133.135 90.3133C132.809 90.6196 132.58 90.9928 132.436 91.4523C132.293 91.9117 132.273 92.3519 132.379 92.7827L132.369 92.7731Z"
                      fill="white"
                    />
                    <path
                      d="M133.039 106.115L127.66 101.444L128.081 100.535L133.747 101.396L129.44 97.587L129.842 96.6777L136.868 97.8167L136.466 98.6781L130.57 97.5966L135.145 101.53L134.724 102.44L128.818 101.444L133.441 105.234L133.039 106.105V106.115Z"
                      fill="white"
                    />
                    <path
                      d="M120.605 110.565L121.141 109.848L128.195 115.188L127.659 115.897L120.605 110.565Z"
                      fill="white"
                    />
                    <path
                      d="M114.7 118.921C115.073 118.797 115.427 118.777 115.772 118.854C116.116 118.931 116.413 119.103 116.671 119.371L115.963 120.012C115.724 119.801 115.446 119.687 115.121 119.677C114.805 119.677 114.48 119.821 114.145 120.117C113.829 120.404 113.657 120.711 113.618 121.036C113.58 121.362 113.676 121.639 113.896 121.888C114.068 122.079 114.269 122.194 114.489 122.213C114.709 122.233 114.92 122.213 115.14 122.127C115.36 122.041 115.638 121.917 115.973 121.744C116.384 121.524 116.729 121.371 117.016 121.275C117.294 121.18 117.59 121.161 117.906 121.218C118.222 121.275 118.519 121.457 118.806 121.773C119.055 122.051 119.208 122.357 119.265 122.702C119.323 123.046 119.265 123.391 119.122 123.735C118.978 124.08 118.739 124.405 118.404 124.702C117.925 125.133 117.437 125.372 116.911 125.41C116.394 125.448 115.934 125.286 115.542 124.932L116.269 124.271C116.47 124.443 116.729 124.52 117.045 124.501C117.361 124.482 117.667 124.338 117.954 124.07C118.232 123.821 118.394 123.544 118.433 123.238C118.48 122.941 118.385 122.654 118.145 122.405C117.973 122.223 117.791 122.118 117.581 122.099C117.37 122.079 117.16 122.099 116.959 122.185C116.758 122.271 116.47 122.386 116.126 122.568C115.705 122.778 115.36 122.931 115.073 123.027C114.786 123.123 114.489 123.142 114.173 123.094C113.858 123.037 113.551 122.855 113.264 122.529C113.034 122.28 112.891 121.993 112.824 121.658C112.757 121.323 112.786 120.969 112.92 120.596C113.054 120.223 113.293 119.888 113.637 119.562C113.972 119.256 114.327 119.045 114.7 118.921Z"
                      fill="white"
                    />
                    <path
                      d="M114.164 126.167C114.24 126.77 114.164 127.354 113.915 127.909C113.676 128.464 113.302 128.923 112.795 129.306C112.192 129.746 111.561 129.995 110.9 130.033C110.24 130.072 109.608 129.909 109.015 129.526L109.876 128.885C110.278 129.105 110.68 129.201 111.101 129.162C111.522 129.124 111.934 128.961 112.326 128.665C112.709 128.378 112.987 128.033 113.159 127.631C113.331 127.229 113.379 126.798 113.312 126.339C113.245 125.88 113.044 125.43 112.719 124.989C112.393 124.549 112.02 124.233 111.608 124.032C111.197 123.831 110.766 123.755 110.326 123.803C109.886 123.85 109.474 124.013 109.091 124.3C108.689 124.597 108.412 124.942 108.258 125.324C108.105 125.717 108.067 126.128 108.163 126.569L107.301 127.21C107.12 126.53 107.139 125.88 107.378 125.267C107.617 124.654 108.029 124.118 108.632 123.678C109.139 123.295 109.694 123.075 110.288 123.008C110.881 122.941 111.455 123.027 112.02 123.276C112.585 123.525 113.054 123.917 113.456 124.453C113.858 124.989 114.087 125.564 114.164 126.167Z"
                      fill="white"
                    />
                    <path
                      d="M104.018 134.8L100.572 128.885L101.348 128.435L102.908 131.115L105.923 129.363L104.362 126.683L105.138 126.233L108.583 132.148L107.808 132.598L106.296 130.004L103.281 131.756L104.793 134.35L104.018 134.8Z"
                      fill="white"
                    />
                    <path
                      d="M101.138 135.403L100.171 133.326L97.9127 134.379L97.5968 133.709L99.8556 132.656L98.8602 130.522L96.3334 131.699L96.0176 131.029L99.3483 129.469L102.258 135.68L98.9176 137.241L98.6018 136.571L101.129 135.384L101.138 135.403Z"
                      fill="white"
                    />
                    <path
                      d="M93.2696 138.963C92.657 138.887 92.1306 138.657 91.6808 138.265C91.2309 137.872 90.8864 137.355 90.6471 136.695C90.4078 136.035 90.3408 135.412 90.4365 134.829C90.5322 134.245 90.8002 133.737 91.2214 133.297C91.6425 132.857 92.2072 132.512 92.9059 132.254L94.9062 131.536L97.2225 137.978L95.2221 138.695C94.5234 138.944 93.863 139.04 93.26 138.963H93.2696ZM91.4989 134.302C91.2214 134.896 91.2214 135.594 91.5085 136.389C91.7956 137.193 92.2455 137.738 92.8484 138.025C93.4514 138.313 94.1693 138.313 94.9924 138.025L96.1601 137.604L94.332 132.541L93.1643 132.962C92.3316 133.259 91.7765 133.709 91.4989 134.312V134.302Z"
                      fill="white"
                    />
                    <path
                      d="M89.3363 140.609L88.3027 136.407C88.1591 135.814 87.9007 135.412 87.5465 135.202C87.1828 134.991 86.7521 134.943 86.2353 135.068C85.728 135.192 85.3643 135.431 85.1442 135.795C84.924 136.149 84.8857 136.628 85.0389 137.221L86.0726 141.423L85.2016 141.633L84.1679 137.441C84.0339 136.886 84.0243 136.398 84.1583 135.967C84.2828 135.537 84.522 135.182 84.8475 134.905C85.1729 134.627 85.5844 134.426 86.0534 134.311C86.5224 134.197 86.9723 134.187 87.3934 134.273C87.8145 134.369 88.1878 134.57 88.4941 134.895C88.8099 135.221 89.0301 135.661 89.1641 136.206L90.1977 140.399L89.3268 140.609H89.3363Z"
                      fill="white"
                    />
                    <path
                      d="M81.4496 136.064L79.0856 136.437L78.9707 135.719L82.2153 135.202L83.2969 141.959L82.4163 142.103L81.4496 136.064Z"
                      fill="white"
                    />
                    <path
                      d="M77.7745 141.988L77.5735 139.71L75.085 139.93L75.018 139.193L77.5065 138.973L77.3055 136.628L74.5299 136.867L74.4629 136.13L78.1286 135.814L78.7221 142.639L75.0563 142.955L74.9893 142.218L77.7649 141.978L77.7745 141.988Z"
                      fill="white"
                    />
                    <path
                      d="M66.6058 137.633L69.592 137.748L70.2046 136.245L71.1425 136.283L68.4052 142.983L67.3715 142.945L65.1797 136.044L66.1177 136.082L66.6058 137.623V137.633ZM66.8355 138.37L67.9362 141.873L69.3049 138.465L66.8355 138.37Z"
                      fill="white"
                    />
                    <path
                      d="M53.6268 141.021L54.938 134.35L55.8186 134.522L54.8423 139.499L58.0391 134.962L58.6516 135.087L59.8959 140.504L60.8721 135.527L61.7527 135.699L60.4414 142.37L59.4939 142.188L58.1061 136.197L54.5552 141.222L53.6172 141.04L53.6268 141.021Z"
                      fill="white"
                    />
                    <path
                      d="M51.004 139.528L51.6739 137.337L49.2907 136.609L49.5109 135.901L51.8941 136.628L52.5832 134.379L49.9129 133.556L50.133 132.848L53.6552 133.929L51.6452 140.485L48.123 139.404L48.3432 138.696L51.0135 139.519L51.004 139.528Z"
                      fill="white"
                    />
                    <path
                      d="M45.9223 137.815L46.7646 135.69L44.4388 134.781L44.7068 134.091L47.0326 135.001L47.894 132.809L45.3002 131.785L45.5682 131.096L48.9947 132.445L46.487 138.82L43.0605 137.47L43.3285 136.781L45.9223 137.805V137.815Z"
                      fill="white"
                    />
                    <path
                      d="M37.6523 134.905L37.9682 134.254L39.6431 135.077L42.3326 129.583L43.1366 129.975L40.4471 135.469L42.1317 136.292L41.8158 136.943L37.6523 134.914V134.905Z"
                      fill="white"
                    />
                    <path
                      d="M35.6523 133.767L39.0118 127.804L39.7871 128.244L36.4276 134.207L35.6523 133.767Z"
                      fill="white"
                    />
                    <path
                      d="M33.308 124.176L34.0545 124.673V131.201L37.0503 126.655L37.7968 127.152L34.0162 132.866L33.2697 132.369V125.851L30.2739 130.387L29.5273 129.899L33.308 124.185V124.176Z"
                      fill="white"
                    />
                    <path
                      d="M25.1236 123.811C25.0183 124.242 25.0279 124.654 25.1619 125.056C25.2959 125.458 25.5543 125.812 25.918 126.108C26.2817 126.415 26.6837 126.606 27.124 126.683C27.5643 126.759 27.995 126.711 28.4257 126.539C28.8564 126.367 29.2392 126.07 29.5838 125.659C29.9283 125.247 30.1485 124.816 30.2346 124.357C30.3303 123.907 30.292 123.476 30.1389 123.055C29.9858 122.644 29.7273 122.28 29.3541 121.974C28.8372 121.543 28.2917 121.352 27.7078 121.38C27.124 121.409 26.5784 121.667 26.0903 122.136L28.1864 123.888L27.7174 124.443L24.8843 122.089L25.3246 121.562C25.7266 121.16 26.1956 120.883 26.722 120.71C27.2484 120.548 27.794 120.509 28.3491 120.615C28.9042 120.72 29.4115 120.969 29.8709 121.352C30.359 121.754 30.7132 122.242 30.9237 122.806C31.1343 123.371 31.1917 123.955 31.0864 124.558C30.9812 125.161 30.7132 125.716 30.2825 126.233C29.8518 126.75 29.3541 127.113 28.7798 127.324C28.2055 127.534 27.6217 127.592 27.0283 127.487C26.4349 127.381 25.8989 127.123 25.4107 126.721C24.8556 126.252 24.4728 125.706 24.2718 125.075C24.0708 124.443 24.0804 123.792 24.3101 123.122L25.1332 123.811H25.1236Z"
                      fill="white"
                    />
                    <path
                      d="M21.4674 111.351L22.0225 112.049L15.1313 117.591L14.5762 116.892L21.4674 111.351Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="rotate-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="105"
                    height="103"
                    viewBox="0 0 105 103"
                    fill="none"
                  >
                    <g opacity="0.5" clipPath="url(#clip0_375_8051)">
                      <g opacity="0.27">
                        <mask
                          id="mask0_375_8051"
                          maskUnits="userSpaceOnUse"
                          x="5"
                          y="2"
                          width="100"
                          height="99"
                        >
                          <path
                            d="M102.811 62.6276C108.912 36.2256 92.4008 9.88917 65.933 3.80349C39.4651 -2.28219 13.063 14.1875 6.96211 40.5895C0.861249 66.9915 17.372 93.3279 43.8398 99.4136C70.3077 105.499 96.7098 89.0297 102.811 62.6276Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_375_8051)">
                          <path
                            d="M42.2323 0.246094C28.6298 14.6485 14.0243 28.0584 0.413818 42.5163"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M48.4567 0.198486C33.302 16.3952 16.3565 32.1314 0.38208 48.1297"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M54.2113 0.206421C36.2231 18.1498 18.1076 36.2124 0.262695 54.0287"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M59.918 0.34137C40.3141 19.8965 20.4475 39.7137 0.310303 59.7849"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M66.9064 0.285828C44.9863 21.6433 22.7876 43.2071 0.374023 65.4141"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M71.4909 0.365234C48.4963 23.9775 25.7086 46.121 2.33198 70.0191C1.75095 70.5986 0.580923 70.5986 0.270508 71.4005"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M77.8427 0.262024C63.6909 15.236 48.4965 28.638 33.302 43.7947C22.1987 55.45 11.0954 65.3585 0.46167 76.9979"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M81.7825 1.4212C54.9197 28.638 26.8788 54.8624 0.38208 82.8176"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M81.7268 7.19324C54.9196 33.8861 27.4598 61.2776 0.477539 88.3435"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M84.6717 10.3293C56.6706 38.5466 28.0408 65.9381 0.493408 94.2109"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M90.4901 10.2976C60.1808 40.2933 30.9619 71.1861 0.23877 100.245"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M95.8864 10.504C64.8528 41.4604 34.4719 71.7657 3.44629 102.714"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M101.562 10.512C70.6951 40.8808 40.3142 71.7657 9.16919 102.77"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.618 13.132C74.7862 42.6275 45.5753 72.9328 14.9158 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.793 18.6262C76.5373 46.7085 49.6664 75.8467 20.6943 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.578 24.9302C78.2964 50.7815 52.5876 77.0138 26.4968 102.762"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.745 30.2736C80.6283 54.2828 56.6706 78.7684 32.3149 102.714"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.682 36.1489C82.3795 58.3638 60.1809 80.5152 38.022 102.786"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.753 41.905C84.7195 62.4368 64.2719 82.8414 43.8879 102.69"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.682 47.7883C86.4785 65.9461 67.7819 84.5961 49.6187 102.738"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.522 53.7509C88.2215 70.019 71.865 86.3348 55.3652 102.762"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.705 59.1577C93.3634 70.424 82.0372 81.7061 70.7747 93.0438C67.5511 96.2831 64.3356 99.5304 61.1279 102.786"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.666 65.6125C92.3208 78.1809 79.4664 91.0033 66.8906 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_375_8051">
                        <rect width="105" height="103" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="rotate-link">
                  let's
                  <br />
                  go
                </div>
                <div className="rotate-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M1.24264 16.4458L16.4454 1.24297M16.4454 1.24297H7.96015M16.4454 1.24297V9.72825"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="newsLetter">
        <Container>
          <h3>Get More of Techwens Straight to Your Inbox!</h3>
          <Row>
            <Col md={5}>
              <p className="primary">
                Website development technologies are rapidly evolving and
                changing. Various new tools, features, and plugins are being
                developed to enhance the look, experience.
              </p>
            </Col>
            <Col md={5}>
              <p className="text-white mb-2">NEWSLETTER</p>
              <InputGroup className="footer-input">
                <Form.Control
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_280_20993"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <path d="M23.3333 0H0V23.3333H23.3333V0Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_280_20993)">
                      <mask
                        id="mask1_280_20993"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <path
                          d="M0.000244141 0.000185013H23.3336V23.3335H0.000244141V0.000185013Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask1_280_20993)">
                        <path
                          d="M1.47953 7.39379C0.455463 7.73513 0.414539 8.39511 1.38866 8.86018L8.4731 12.2437C9.44713 12.7089 10.6249 13.8866 11.0902 14.8607L14.4735 21.9452C14.9388 22.9193 15.5987 22.8784 15.9401 21.8544L22.5498 2.02535C22.8911 1.00128 22.3325 0.442692 21.3084 0.784215L1.47953 7.39379Z"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.97241 13.3613L22.4412 0.892499"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.94834 11.5154L4.47861 17.6139C4.13723 18.638 4.69577 19.1966 5.71993 18.8553L11.8186 16.3855"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </InputGroup.Text>
              </InputGroup>
              <p className="primary mt-2">
                Subscribe to our newsletter right away
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Branding;
