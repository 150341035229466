import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import DevelopProduct1 from "../../Assets/Images/HomeImages/Develop_Product1.png";
import DevelopProduct2 from "../../Assets/Images/HomeImages/Develop_Product2.png";
import DevelopProduct3 from "../../Assets/Images/HomeImages/Develop_Product3.png";
import DevelopProduct4 from "../../Assets/Images/HomeImages/Develop_Product4.png";
const developmentProducts = [
  {
    heading: "Research and Development",
    image: DevelopProduct1,
  },
  {
    heading: "UI/UX Design",
    image: DevelopProduct2,
  },
  {
    heading: "Coding Implementation",
    image: DevelopProduct3,
  },
  {
    heading: "Code Testing",
    image: DevelopProduct4,
  },
  {
    heading: "Graphics Design",
    image: DevelopProduct3,
  },
  {
    heading: "Live",
    image: DevelopProduct1,
  },
];

const DevelopProduct = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Develop Products Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="products">
        <div className="products-wrapper">
          <Container>
            <Row className="">
              <Col xs={7} xxl={9}>
                <h3>
                  Designing and Developing Innovation With High-End Research
                </h3>
              </Col>
              <Col xs={5} xxl={3} className="d-flex justify-content-md-center">
                <Link to="/service" className="secondary-button">
                  Explore
                  <br />
                  Now
                </Link>
              </Col>
              <Col xs={12} md={8} xxl={9}>
                <p className="secondary mt-2 mt-md-0">
                  Check out what we do and schedule a call with us without
                  wasting any more time.
                </p>
              </Col>
            </Row>
            <div className="products-swiper">
              <Swiper
                spaceBetween={5}
                navigation={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
              >
                {developmentProducts.map((products, index) => (
                  <SwiperSlide key={index}>
                    {" "}
                    <div className="OnDemand-services-card">
                      <div>
                        <img src={products.image} alt="" />
                      </div>
                      <p>{products.heading}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default DevelopProduct;
