import React, { useEffect, useRef } from "react";
import { Col, Container, Row, Nav, Tab, InputGroup } from "react-bootstrap";
import Business from "../../../Assets/Images/AboutImages/AboutBanner.png";
import EcommerceImage from "../../../Assets/Images/HomeImages/EcommerceImage.png";
import serviceDevelopment from "../../../Assets/Images/ServiceImages/serviceDevelopment.png";
import WebTecnology1 from "../../../Assets/Images/ServiceImages/WebTecnology1.png";
import WebTecnology2 from "../../../Assets/Images/ServiceImages/WebTecnology2.png";
import WebTecnology3 from "../../../Assets/Images/ServiceImages/WebTecnology3.png";
import WebTecnology4 from "../../../Assets/Images/ServiceImages/WebTecnology4.png";
import WebTecnology5 from "../../../Assets/Images/ServiceImages/WebTecnology5.png";
import WebSlider1 from "../../../Assets/Images/ServiceImages/WebSlider1.png";
import WebSlider2 from "../../../Assets/Images/ServiceImages/WebSlider2.png";
import WebSlider3 from "../../../Assets/Images/ServiceImages/WebSlider3.png";
import "../../../Style/Modules/About.scss";
import "../../../Style/Modules/Common.scss";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import WebBanner from "../../../Assets/Images/ServiceImages/WebBanner.png";
import WebSub from "../../../Assets/Images/ServiceImages/WebSub.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const Web = () => {
  const scrollToRef = useRef();
  useEffect(() => {
    // Scroll to the target element
    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="globalBanner inner" ref={scrollToRef}>
        <Container>
          <Row>
            <Col md={10}>
              <div className="globalBanner-text head">
                <p>Website Development Services</p>
                <h1 className="gradient">Custom Website Development</h1>
              </div>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
            >
              <Link to="/contact" className="rotate">
                <div className="rotate-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="142"
                    height="143"
                    viewBox="0 0 142 143"
                    fill="none"
                  >
                    <path
                      d="M14.4904 102.335C14.5096 102.727 14.4426 103.072 14.2894 103.388C14.1267 103.704 13.8875 103.943 13.562 104.125L13.1218 103.282C13.3898 103.101 13.5716 102.861 13.6577 102.555C13.7439 102.249 13.6865 101.895 13.4759 101.502C13.2749 101.129 13.0261 100.88 12.7293 100.756C12.4326 100.631 12.1264 100.65 11.8297 100.813C11.5999 100.937 11.4468 101.1 11.3607 101.301C11.2841 101.502 11.2554 101.722 11.2841 101.952C11.3128 102.182 11.3607 102.478 11.4468 102.852C11.5521 103.302 11.6191 103.684 11.6382 103.981C11.6574 104.278 11.5999 104.565 11.466 104.852C11.332 105.139 11.0735 105.379 10.7003 105.58C10.3653 105.752 10.0303 105.819 9.68571 105.79C9.34115 105.752 9.0253 105.618 8.71903 105.388C8.42232 105.158 8.1639 104.843 7.9629 104.45C7.6662 103.885 7.56092 103.34 7.65663 102.833C7.75234 102.325 8.02033 101.923 8.47018 101.627L8.92959 102.498C8.70946 102.651 8.57546 102.88 8.50846 103.187C8.44146 103.493 8.50846 103.828 8.69031 104.182C8.86259 104.517 9.0923 104.737 9.36987 104.862C9.64743 104.986 9.94414 104.957 10.2504 104.804C10.4706 104.689 10.6141 104.536 10.6907 104.335C10.7673 104.134 10.7864 103.933 10.7673 103.704C10.7481 103.483 10.6907 103.187 10.6141 102.804C10.5184 102.344 10.4514 101.971 10.4323 101.665C10.4131 101.368 10.4706 101.071 10.6045 100.775C10.7385 100.478 10.997 100.239 11.3798 100.038C11.6765 99.8847 11.9924 99.8177 12.3369 99.8368C12.6815 99.856 13.0165 99.9804 13.3323 100.201C13.6577 100.421 13.9257 100.746 14.1459 101.158C14.3564 101.56 14.4713 101.952 14.4904 102.344V102.335Z"
                      fill="black"
                    />
                    <path
                      d="M7.60862 99.9612C7.00563 99.8751 6.46008 99.655 5.99109 99.2817C5.5221 98.9084 5.16797 98.4298 4.92869 97.8364C4.65112 97.1473 4.57456 96.4678 4.70855 95.8169C4.83298 95.1661 5.1584 94.6014 5.68481 94.1228L6.07723 95.1182C5.76138 95.4436 5.56996 95.8169 5.49339 96.2381C5.41682 96.6496 5.47425 97.0899 5.6561 97.5493C5.83795 97.9896 6.09637 98.3533 6.44093 98.6213C6.78549 98.8893 7.19706 99.052 7.65647 99.0998C8.11589 99.1477 8.60402 99.0711 9.11129 98.8701C9.61856 98.6691 10.0205 98.3916 10.3173 98.0374C10.614 97.6833 10.7958 97.2909 10.8628 96.8602C10.9298 96.4295 10.8724 95.9892 10.7001 95.5394C10.5182 95.0799 10.2598 94.7258 9.92484 94.4674C9.58985 94.2185 9.19743 94.0845 8.73801 94.0558L8.34559 93.0604C9.04429 93.0604 9.66642 93.2423 10.2024 93.6251C10.7384 94.008 11.1499 94.544 11.4275 95.2426C11.6668 95.8361 11.7338 96.4199 11.6572 97.0133C11.5711 97.6067 11.3414 98.1427 10.9585 98.6213C10.5757 99.0998 10.078 99.4635 9.45585 99.7028C8.83373 99.9517 8.22117 100.028 7.61819 99.9517L7.60862 99.9612Z"
                      fill="black"
                    />
                    <path
                      d="M1.86572 87.9875L8.46026 86.169L8.69954 87.0304L5.71333 87.8535L6.64173 91.213L9.63751 90.3899L9.87679 91.2513L3.28226 93.0698L3.04298 92.2084L5.94304 91.414L5.01464 88.0545L2.11457 88.8585L1.87529 87.9971L1.86572 87.9875Z"
                      fill="black"
                    />
                    <path
                      d="M2.0193 85.0111L4.26852 84.6091L3.82825 82.1589L4.55566 82.0249L4.99593 84.4751L7.31215 84.054L6.81445 81.307L7.54186 81.173L8.1927 84.8005L1.44503 86.016L0.794189 82.3886L1.5216 82.2546L2.0193 85.0015V85.0111Z"
                      fill="black"
                    />
                    <path
                      d="M0.592907 76.5023C0.822615 75.9376 1.18632 75.4781 1.67445 75.1431C2.16258 74.8082 2.75599 74.6072 3.45469 74.5497C4.15338 74.4923 4.76594 74.5784 5.31149 74.8273C5.84748 75.0761 6.27818 75.459 6.59403 75.9758C6.90988 76.4927 7.1013 77.1244 7.1683 77.8709L7.35015 79.9957L0.53548 80.5891L0.353628 78.4643C0.28663 77.7178 0.37277 77.0669 0.602478 76.4927L0.592907 76.5023ZM5.56034 75.9854C5.05307 75.5643 4.38309 75.392 3.54083 75.459C2.68899 75.5356 2.04772 75.8227 1.61702 76.33C1.18632 76.8372 1.00447 77.5264 1.08104 78.4069L1.18632 79.6416L6.54617 79.1726L6.44089 77.9379C6.36432 77.0574 6.07719 76.4065 5.56991 75.9854H5.56034Z"
                      fill="black"
                    />
                    <path
                      d="M0.00932703 72.2624L4.33549 72.3294C4.94805 72.3389 5.39789 72.1954 5.6946 71.9082C5.99131 71.6115 6.14444 71.2 6.15402 70.6735C6.15402 70.1471 6.02002 69.7356 5.73288 69.4389C5.44575 69.1326 4.9959 68.9794 4.38335 68.9699L0.0571829 68.9029L0.0763252 68.0128L4.39292 68.0798C4.96719 68.0798 5.43618 68.2138 5.8286 68.4435C6.21144 68.6827 6.49858 68.989 6.68043 69.3814C6.86228 69.7739 6.94842 70.2141 6.94842 70.6927C6.94842 71.1808 6.84314 71.6115 6.64215 71.9944C6.45072 72.3772 6.15402 72.6835 5.7616 72.9036C5.36918 73.1238 4.89062 73.229 4.31635 73.2195L-0.000244141 73.1525L0.0188982 72.2624H0.00932703Z"
                      fill="black"
                    />
                    <path
                      d="M6.40289 65.8207L6.6326 63.4375L7.36001 63.5045L7.04416 66.7778L0.229492 66.1174L0.315633 65.2273L6.40289 65.8207Z"
                      fill="black"
                    />
                    <path
                      d="M1.58821 60.7675L3.84701 61.1504L4.26814 58.6906L4.99555 58.815L4.57442 61.2748L6.90021 61.6672L7.3692 58.9203L8.09661 59.0447L7.48405 62.6722L0.726807 61.5236L1.33936 57.8962L2.06677 58.0206L1.59778 60.7675H1.58821Z"
                      fill="black"
                    />
                    <path
                      d="M8.64206 51.0622L7.77108 53.9144L9.06319 54.8907L8.78562 55.7904L3.00464 51.4355L3.31092 50.4496L10.5371 50.0859L10.2596 50.9856L8.64206 51.0622ZM7.87636 51.0909L4.21061 51.2632L7.14895 53.455L7.87636 51.0909Z"
                      fill="black"
                    />
                    <path
                      d="M8.69036 37.6528L14.7968 40.6294L14.4043 41.4334L9.84847 39.2129L13.4185 43.4625L13.141 44.0272L7.5801 43.8453L12.1456 46.0658L11.7531 46.8698L5.64673 43.8932L6.06786 43.0318L12.2221 43.2328L8.26923 38.5142L8.69036 37.6528Z"
                      fill="black"
                    />
                    <path
                      d="M10.7953 35.5089L12.7382 36.7149L14.059 34.5997L14.6812 34.9921L13.3603 37.1073L15.3607 38.3516L16.8347 35.9875L17.4568 36.3799L15.5138 39.5097L9.69458 35.8918L11.6375 32.762L12.2597 33.1544L10.7857 35.5185L10.7953 35.5089Z"
                      fill="black"
                    />
                    <path
                      d="M13.7629 31.0008L15.6102 32.3504L17.0841 30.3404L17.6775 30.7807L16.2036 32.7907L18.0987 34.188L19.7449 31.9388L20.3383 32.3791L18.1561 35.3462L12.6335 31.288L14.8158 28.3209L15.4092 28.7612L13.7629 31.0104V31.0008Z"
                      fill="black"
                    />
                    <path
                      d="M18.6724 23.7653L19.2275 24.2438L18.012 25.6603L22.6444 29.6515L22.0606 30.3311L17.4281 26.3399L16.203 27.7564L15.6575 27.2779L18.682 23.7653H18.6724Z"
                      fill="black"
                    />
                    <path
                      d="M20.2811 22.1286L25.1911 26.895L24.5689 27.5363L19.6589 22.7699L20.2811 22.1286Z"
                      fill="black"
                    />
                    <path
                      d="M30.1484 22.2914L29.4784 22.8848L23.1614 21.2195L26.7985 25.2776L26.1285 25.871L21.5535 20.76L22.2234 20.1666L28.5308 21.8224L24.9034 17.7738L25.5734 17.1804L30.1484 22.2819V22.2914Z"
                      fill="black"
                    />
                    <path
                      d="M32.5897 14.462C32.2068 14.2514 31.7953 14.1557 31.3837 14.1844C30.9626 14.2131 30.5606 14.3663 30.1682 14.6438C29.7758 14.9214 29.4886 15.2564 29.3068 15.6584C29.1249 16.0604 29.0579 16.4911 29.1153 16.9505C29.1728 17.4099 29.3642 17.8597 29.6705 18.3C29.9768 18.7403 30.3405 19.0561 30.7616 19.2571C31.1732 19.4581 31.6039 19.5443 32.0441 19.4964C32.4844 19.4486 32.896 19.2954 33.2884 19.0179C33.8339 18.635 34.1594 18.1469 34.2838 17.5726C34.4082 16.9983 34.2934 16.4145 33.9584 15.8115L31.7378 17.4003L31.3167 16.8069L34.3125 14.6725L34.7145 15.2277C35.0016 15.7158 35.1548 16.2422 35.1835 16.7973C35.2122 17.3525 35.1069 17.8885 34.858 18.3862C34.6188 18.8934 34.2455 19.3241 33.7574 19.6687C33.2405 20.042 32.6854 20.2525 32.0824 20.31C31.4794 20.3674 30.9052 20.2717 30.35 20.0228C29.7949 19.7644 29.3259 19.372 28.9335 18.8264C28.5411 18.2809 28.3209 17.7066 28.2539 17.094C28.1965 16.4815 28.2922 15.9072 28.5411 15.3617C28.7899 14.8161 29.1823 14.3567 29.6992 13.993C30.2926 13.5719 30.9147 13.3422 31.5847 13.3134C32.2451 13.2847 32.8768 13.457 33.4607 13.8399L32.5897 14.462Z"
                      fill="black"
                    />
                    <path
                      d="M44.3147 13.4476L43.5203 13.8496L37.8541 10.6145L40.3139 15.4767L39.5195 15.8787L36.4185 9.76268L37.2129 9.36069L42.879 12.5862L40.4192 7.73359L41.2136 7.3316L44.3147 13.4476Z"
                      fill="black"
                    />
                    <path
                      d="M46.6688 12.0693C46.0754 11.9736 45.5394 11.7247 45.0705 11.3323C44.6015 10.9399 44.2473 10.4326 44.0176 9.80092C43.7879 9.17879 43.7209 8.55667 43.8166 7.95369C43.9124 7.3507 44.1612 6.81472 44.544 6.3553C44.9269 5.89588 45.4246 5.55132 46.018 5.33119C46.621 5.11105 47.2144 5.04405 47.8078 5.13976C48.4012 5.23547 48.9372 5.48432 49.4062 5.86717C49.8752 6.25959 50.2293 6.76686 50.459 7.39856C50.6983 8.03026 50.7653 8.64281 50.6696 9.24579C50.5739 9.84878 50.3346 10.3752 49.9422 10.8442C49.5593 11.3036 49.0616 11.6482 48.4587 11.8779C47.8652 12.098 47.2718 12.165 46.6784 12.0693H46.6688ZM49.2722 10.3943C49.5498 10.0498 49.722 9.64778 49.789 9.18837C49.8465 8.72895 49.789 8.24082 49.5976 7.73355C49.4062 7.21671 49.1382 6.80515 48.7841 6.49887C48.4395 6.19259 48.0471 6.00117 47.6068 5.9246C47.1761 5.84803 46.7263 5.89588 46.2764 6.06817C45.8266 6.24045 45.4533 6.4893 45.1757 6.83386C44.8982 7.17842 44.7259 7.57084 44.6685 8.03983C44.6015 8.49924 44.6685 8.98737 44.8599 9.50421C45.0513 10.0115 45.3193 10.423 45.6734 10.7293C46.018 11.0356 46.42 11.227 46.8507 11.3036C47.2814 11.3802 47.7312 11.3323 48.1811 11.1696C48.6309 11.0069 48.9946 10.7485 49.2722 10.4039V10.3943Z"
                      fill="black"
                    />
                    <path
                      d="M59.4366 1.71326L59.025 8.82463L58.0488 9.04477L55.2731 4.02948L54.8903 9.76261L53.9236 9.99232L50.5354 3.73277L51.4638 3.52221L54.2107 8.85335L54.5266 2.83308L55.5028 2.61295L58.3168 7.9058L58.4986 1.93339L59.4366 1.72283V1.71326Z"
                      fill="black"
                    />
                    <path
                      d="M70.5777 8.84376L69.6876 8.8629L69.5344 0.0191423L70.4245 0L70.5681 8.84376H70.5777Z"
                      fill="black"
                    />
                    <path
                      d="M80.7612 8.41288C80.4358 8.19274 80.2061 7.92475 80.053 7.6089C79.8998 7.29305 79.852 6.94849 79.919 6.58479L80.8569 6.7475C80.8378 7.07292 80.9144 7.36005 81.1058 7.61847C81.2972 7.87689 81.6131 8.0396 82.0533 8.11617C82.4745 8.19274 82.819 8.14489 83.0966 7.9726C83.3741 7.80989 83.5464 7.56104 83.6039 7.22605C83.6517 6.96763 83.6134 6.7475 83.4986 6.55607C83.3837 6.37422 83.2306 6.22108 83.0296 6.09666C82.8286 5.97223 82.5606 5.83823 82.216 5.67552C81.7949 5.4841 81.4599 5.30225 81.2111 5.13954C80.9622 4.97683 80.7708 4.75669 80.6176 4.46956C80.4741 4.19199 80.4358 3.83786 80.5124 3.41673C80.5794 3.05303 80.7325 2.73718 80.9622 2.48833C81.2015 2.23948 81.4982 2.06719 81.8619 1.97148C82.2256 1.87577 82.6276 1.8662 83.0679 1.94277C83.6996 2.05762 84.1877 2.30647 84.5418 2.68932C84.8864 3.07217 85.0395 3.53158 85.0012 4.06757L84.0346 3.89529C84.0441 3.6273 83.9484 3.37844 83.7474 3.13917C83.5464 2.89989 83.2497 2.74675 82.8477 2.67975C82.484 2.61275 82.1682 2.66061 81.9002 2.80417C81.6322 2.95731 81.4695 3.19659 81.4121 3.54115C81.3738 3.79 81.4121 4.00057 81.5173 4.17285C81.6322 4.3547 81.7758 4.49827 81.9672 4.61313C82.1586 4.72798 82.4266 4.87155 82.7712 5.04383C83.1923 5.24482 83.5273 5.42667 83.7761 5.59896C84.025 5.76167 84.226 5.99137 84.3791 6.26894C84.5323 6.5465 84.5705 6.90063 84.494 7.33134C84.4365 7.65676 84.293 7.95346 84.0728 8.21188C83.8527 8.47031 83.5464 8.66173 83.1732 8.78615C82.7999 8.91058 82.3787 8.92972 81.9193 8.84358C81.4791 8.76701 81.0866 8.61387 80.7708 8.39374L80.7612 8.41288Z"
                      fill="black"
                    />
                    <path
                      d="M86.8386 4.4026C87.2789 3.9719 87.7861 3.68476 88.37 3.53162C88.9538 3.38805 89.5472 3.39763 90.1598 3.56991C90.8776 3.7709 91.4519 4.12503 91.8922 4.62273C92.3324 5.12044 92.5813 5.72342 92.6483 6.43168L91.6146 6.13498C91.5476 5.68513 91.3658 5.30229 91.0882 4.99601C90.8011 4.68016 90.4278 4.46002 89.9492 4.32603C89.4898 4.19203 89.0495 4.18246 88.6188 4.29731C88.1881 4.41217 87.8244 4.63231 87.499 4.9673C87.1736 5.30229 86.9439 5.73299 86.7907 6.2594C86.6376 6.78582 86.6185 7.27395 86.7142 7.72379C86.8099 8.17364 87.0109 8.56605 87.3172 8.8819C87.6234 9.19775 88.0063 9.42746 88.4657 9.55189C88.9443 9.68588 89.3845 9.69545 89.7865 9.59017C90.1885 9.48489 90.5426 9.24561 90.8298 8.90105L91.8635 9.19775C91.4423 9.75288 90.9063 10.1357 90.2747 10.3271C89.643 10.5186 88.9634 10.509 88.2456 10.308C87.633 10.1357 87.1162 9.82945 86.7046 9.40832C86.2835 8.97762 86.0059 8.47034 85.8623 7.87693C85.7188 7.28352 85.7379 6.67096 85.9198 6.02012C86.1016 5.37886 86.4079 4.84287 86.8482 4.41217L86.8386 4.4026Z"
                      fill="black"
                    />
                    <path
                      d="M99.8466 7.1689L97.2623 13.505L96.4392 13.17L97.6069 10.2987L94.3814 8.97785L93.2137 11.8492L92.3906 11.5142L94.9748 5.1781L95.798 5.51309L94.659 8.2983L97.8845 9.61912L99.0139 6.83391L99.8466 7.1689Z"
                      fill="black"
                    />
                    <path
                      d="M102.104 9.09242L101.052 11.1215L103.262 12.27L102.927 12.9209L100.717 11.7723L99.635 13.8684L102.114 15.151L101.779 15.8018L98.5056 14.1077L101.655 8.02045L104.928 9.71454L104.593 10.3654L102.114 9.08285L102.104 9.09242Z"
                      fill="black"
                    />
                    <path
                      d="M109.714 13.1604C110.02 13.6868 110.164 14.2515 110.126 14.8449C110.087 15.4383 109.886 16.0317 109.513 16.6156C109.14 17.209 108.68 17.6397 108.164 17.9173C107.647 18.1948 107.072 18.3001 106.469 18.2331C105.866 18.1661 105.244 17.9364 104.613 17.5344L102.813 16.3859L106.508 10.624L108.307 11.7726C108.939 12.1746 109.408 12.6435 109.714 13.17V13.1604ZM107.092 17.41C107.733 17.2664 108.288 16.8357 108.738 16.1274C109.197 15.4096 109.36 14.7205 109.216 14.0696C109.082 13.4188 108.642 12.8541 107.905 12.3755L106.862 11.7056L103.962 16.2423L105.005 16.9123C105.752 17.3908 106.441 17.5535 107.092 17.41Z"
                      fill="black"
                    />
                    <path
                      d="M113.417 15.2756L110.718 18.6638C110.336 19.1424 110.173 19.5827 110.221 20.0038C110.269 20.4249 110.498 20.7886 110.919 21.1236C111.331 21.449 111.742 21.5926 112.154 21.5448C112.566 21.4969 112.968 21.2385 113.35 20.7599L116.049 17.3717L116.748 17.9268L114.059 21.3055C113.705 21.7553 113.312 22.052 112.891 22.2147C112.47 22.3774 112.049 22.4062 111.628 22.32C111.206 22.2243 110.804 22.0233 110.422 21.7266C110.039 21.4203 109.761 21.0758 109.57 20.6833C109.378 20.2909 109.321 19.8698 109.388 19.4295C109.455 18.9892 109.666 18.5394 110.02 18.0896L112.709 14.7109L113.408 15.2661L113.417 15.2756Z"
                      fill="black"
                    />
                    <path
                      d="M114.633 24.2915L116.375 25.9377L115.877 26.4641L113.485 24.2149L118.174 19.2283L118.825 19.8409L114.633 24.301V24.2915Z"
                      fill="black"
                    />
                    <path
                      d="M121.562 23.5739L119.878 25.1244L121.572 26.9525L121.026 27.4502L119.332 25.6221L117.6 27.2205L119.495 29.2687L118.949 29.7664L116.451 27.0578L121.486 22.4062L123.984 25.1148L123.438 25.6125L121.543 23.5643L121.562 23.5739Z"
                      fill="black"
                    />
                    <path
                      d="M124.941 35.0783L123.209 32.6472L121.649 33.0779L121.103 32.3122L128.081 30.3884L128.674 31.2307L124.549 37.184L124.003 36.4183L124.922 35.0879L124.941 35.0783ZM125.382 34.4466L127.487 31.4317L123.955 32.4271L125.391 34.4466H125.382Z"
                      fill="black"
                    />
                    <path
                      d="M135.547 43.3002L129.469 46.3342L129.067 45.5303L133.604 43.2715L128.062 43.5107L127.784 42.946L131.316 38.6486L126.77 40.917L126.368 40.113L132.446 37.0789L132.876 37.9403L128.971 42.6972L135.116 42.4388L135.537 43.2906L135.547 43.3002Z"
                      fill="black"
                    />
                    <path
                      d="M135.968 46.2957L133.824 47.0997L134.704 49.435L134.015 49.6935L133.135 47.3581L130.924 48.1812L131.9 50.7942L131.211 51.0526L129.919 47.607L136.341 45.2046L137.633 48.6502L136.944 48.9086L135.968 46.2957Z"
                      fill="black"
                    />
                    <path
                      d="M137.718 51.3685L135.517 52.0002L136.206 54.393L135.498 54.594L134.809 52.2012L132.54 52.852L133.316 55.5319L132.607 55.7329L131.583 52.1916L138.168 50.2869L139.192 53.8283L138.484 54.0293L137.709 51.3493L137.718 51.3685Z"
                      fill="black"
                    />
                    <path
                      d="M140.456 59.6952L139.748 59.8387L139.374 58.0107L133.383 59.2358L133.201 58.3648L139.192 57.1397L138.819 55.302L139.527 55.1584L140.456 59.6952Z"
                      fill="black"
                    />
                    <path
                      d="M140.772 61.9542L133.996 62.9304L133.872 62.0499L140.648 61.0736L140.772 61.9542Z"
                      fill="black"
                    />
                    <path
                      d="M134.618 69.6875L134.551 68.7974L139.729 64.8254L134.293 65.2178L134.226 64.3277L141.059 63.83L141.126 64.7201L135.958 68.6921L141.385 68.2997L141.452 69.1898L134.618 69.6875Z"
                      fill="black"
                    />
                    <path
                      d="M139.317 76.3873C139.719 76.215 140.045 75.947 140.275 75.5929C140.504 75.2387 140.629 74.8272 140.648 74.3486C140.667 73.8701 140.571 73.4394 140.37 73.0469C140.169 72.6545 139.863 72.3387 139.461 72.109C139.059 71.8697 138.59 71.7453 138.054 71.7261C137.518 71.707 137.039 71.8027 136.628 72.0037C136.216 72.2047 135.891 72.5014 135.661 72.8747C135.432 73.2479 135.298 73.6786 135.288 74.1572C135.259 74.8272 135.441 75.3823 135.824 75.8321C136.207 76.282 136.733 76.55 137.413 76.6457L137.518 73.9179L138.245 73.9466L138.111 77.622L137.422 77.5932C136.858 77.5167 136.35 77.3252 135.901 77.0094C135.441 76.6935 135.087 76.282 134.838 75.7843C134.589 75.2866 134.465 74.7315 134.494 74.1285C134.522 73.4968 134.685 72.9225 135.001 72.4057C135.317 71.8888 135.747 71.4964 136.293 71.2093C136.839 70.9221 137.441 70.7977 138.102 70.8168C138.762 70.836 139.365 71.0178 139.882 71.3433C140.399 71.6687 140.801 72.0994 141.079 72.6354C141.356 73.1714 141.481 73.7552 141.461 74.3869C141.433 75.1143 141.232 75.746 140.849 76.2916C140.466 76.8371 139.949 77.2295 139.279 77.4592L139.317 76.3873Z"
                      fill="black"
                    />
                    <path
                      d="M132.972 86.2744L133.134 85.3938L139.155 82.8766L133.804 81.8621L133.967 80.9815L140.705 82.2545L140.542 83.135L134.532 85.6522L139.872 86.6668L139.71 87.5473L132.972 86.2744Z"
                      fill="black"
                    />
                    <path
                      d="M132.627 88.9927C133.068 88.5811 133.585 88.3036 134.188 88.1696C134.791 88.0356 135.403 88.0643 136.044 88.2653C136.686 88.4663 137.212 88.7821 137.633 89.232C138.054 89.6818 138.332 90.1987 138.456 90.7825C138.581 91.3663 138.562 91.9693 138.37 92.5723C138.179 93.1849 137.863 93.7017 137.423 94.1133C136.982 94.5248 136.466 94.7928 135.872 94.9268C135.279 95.0608 134.657 95.0225 134.015 94.8215C133.374 94.6205 132.838 94.3047 132.426 93.8548C132.005 93.4146 131.737 92.8977 131.603 92.3043C131.469 91.7109 131.508 91.1175 131.69 90.5049C131.881 89.9019 132.187 89.3851 132.627 88.9735V88.9927ZM132.369 92.0842C132.465 92.5149 132.685 92.8977 133.01 93.2327C133.336 93.5677 133.766 93.807 134.283 93.9697C134.81 94.1324 135.298 94.1707 135.757 94.0846C136.217 93.9984 136.6 93.807 136.925 93.5007C137.25 93.204 137.48 92.8212 137.624 92.3617C137.767 91.9023 137.786 91.4525 137.691 91.0218C137.595 90.5911 137.375 90.2178 137.049 89.8828C136.724 89.5478 136.293 89.3085 135.776 89.1458C135.26 88.9831 134.771 88.9448 134.312 89.0405C133.853 89.1363 133.46 89.3181 133.135 89.6244C132.809 89.9307 132.58 90.3039 132.436 90.7634C132.293 91.2228 132.273 91.663 132.379 92.0937L132.369 92.0842Z"
                      fill="black"
                    />
                    <path
                      d="M133.038 105.426L127.659 100.756L128.081 99.8464L133.747 100.708L129.44 96.8985L129.842 95.9892L136.867 97.1282L136.465 97.9896L130.569 96.908L135.144 100.842L134.723 101.751L128.818 100.756L133.44 104.546L133.038 105.417V105.426Z"
                      fill="black"
                    />
                    <path
                      d="M120.606 109.877L121.142 109.159L128.196 114.5L127.66 115.208L120.606 109.877Z"
                      fill="black"
                    />
                    <path
                      d="M114.701 118.233C115.074 118.108 115.428 118.089 115.772 118.166C116.117 118.242 116.414 118.414 116.672 118.682L115.964 119.324C115.725 119.113 115.447 118.998 115.122 118.989C114.806 118.989 114.48 119.132 114.145 119.429C113.83 119.716 113.657 120.022 113.619 120.348C113.581 120.673 113.676 120.951 113.897 121.2C114.069 121.391 114.27 121.506 114.49 121.525C114.71 121.544 114.921 121.525 115.141 121.439C115.361 121.353 115.638 121.228 115.973 121.056C116.385 120.836 116.73 120.683 117.017 120.587C117.294 120.491 117.591 120.472 117.907 120.53C118.223 120.587 118.519 120.769 118.807 121.085C119.055 121.362 119.209 121.669 119.266 122.013C119.323 122.358 119.266 122.702 119.122 123.047C118.979 123.391 118.74 123.717 118.405 124.014C117.926 124.444 117.438 124.683 116.911 124.722C116.395 124.76 115.935 124.597 115.543 124.243L116.27 123.583C116.471 123.755 116.73 123.832 117.045 123.813C117.361 123.793 117.668 123.65 117.955 123.382C118.232 123.133 118.395 122.855 118.433 122.549C118.481 122.252 118.385 121.965 118.146 121.716C117.974 121.535 117.792 121.429 117.581 121.41C117.371 121.391 117.16 121.41 116.959 121.496C116.758 121.582 116.471 121.697 116.127 121.879C115.705 122.09 115.361 122.243 115.074 122.339C114.787 122.434 114.49 122.453 114.174 122.406C113.858 122.348 113.552 122.166 113.265 121.841C113.035 121.592 112.892 121.305 112.825 120.97C112.758 120.635 112.786 120.281 112.92 119.907C113.054 119.534 113.294 119.199 113.638 118.874C113.973 118.568 114.327 118.357 114.701 118.233Z"
                      fill="black"
                    />
                    <path
                      d="M114.164 125.478C114.241 126.081 114.164 126.665 113.915 127.22C113.676 127.775 113.303 128.235 112.795 128.618C112.192 129.058 111.561 129.307 110.9 129.345C110.24 129.383 109.608 129.221 109.015 128.838L109.876 128.196C110.278 128.417 110.68 128.512 111.101 128.474C111.522 128.436 111.934 128.273 112.326 127.976C112.709 127.689 112.987 127.345 113.159 126.943C113.331 126.541 113.379 126.11 113.312 125.65C113.245 125.191 113.044 124.741 112.719 124.301C112.393 123.861 112.02 123.545 111.609 123.344C111.197 123.143 110.766 123.066 110.326 123.114C109.886 123.162 109.474 123.325 109.091 123.612C108.689 123.909 108.412 124.253 108.259 124.636C108.106 125.028 108.067 125.44 108.163 125.88L107.302 126.521C107.12 125.842 107.139 125.191 107.378 124.579C107.617 123.966 108.029 123.43 108.632 122.99C109.139 122.607 109.694 122.387 110.288 122.32C110.881 122.253 111.456 122.339 112.02 122.588C112.585 122.837 113.054 123.229 113.456 123.765C113.858 124.301 114.088 124.875 114.164 125.478Z"
                      fill="black"
                    />
                    <path
                      d="M104.019 134.111L100.573 128.196L101.349 127.746L102.909 130.426L105.924 128.675L104.363 125.995L105.139 125.545L108.584 131.46L107.809 131.91L106.297 129.316L103.282 131.068L104.794 133.661L104.019 134.111Z"
                      fill="black"
                    />
                    <path
                      d="M101.138 134.714L100.172 132.637L97.9129 133.69L97.5971 133.02L99.8559 131.967L98.8605 129.833L96.3337 131.01L96.0178 130.34L99.3486 128.78L102.258 134.991L98.9179 136.552L98.602 135.882L101.129 134.695L101.138 134.714Z"
                      fill="black"
                    />
                    <path
                      d="M93.2701 138.275C92.6575 138.198 92.1311 137.968 91.6813 137.576C91.2314 137.183 90.8869 136.667 90.6476 136.006C90.4083 135.346 90.3413 134.724 90.437 134.14C90.5327 133.556 90.8007 133.049 91.2218 132.608C91.643 132.168 92.2077 131.824 92.9064 131.565L94.9067 130.847L97.223 137.289L95.2226 138.007C94.5239 138.255 93.8635 138.351 93.2605 138.275H93.2701ZM91.4994 133.613C91.2218 134.207 91.2218 134.906 91.509 135.7C91.7961 136.504 92.246 137.049 92.8489 137.337C93.4519 137.624 94.1698 137.624 94.9929 137.337L96.1606 136.915L94.3325 131.852L93.1648 132.273C92.3321 132.57 91.777 133.02 91.4994 133.623V133.613Z"
                      fill="black"
                    />
                    <path
                      d="M89.3368 139.921L88.3031 135.719C88.1596 135.126 87.9012 134.724 87.547 134.513C87.1833 134.303 86.7526 134.255 86.2358 134.379C85.7285 134.504 85.3648 134.743 85.1447 135.107C84.9245 135.461 84.8862 135.939 85.0394 136.533L86.0731 140.735L85.2021 140.945L84.1684 136.753C84.0344 136.198 84.0248 135.71 84.1588 135.279C84.2833 134.848 84.5225 134.494 84.848 134.217C85.1734 133.939 85.5849 133.738 86.0539 133.623C86.5229 133.508 86.9728 133.499 87.3939 133.585C87.815 133.681 88.1883 133.882 88.4946 134.207C88.8104 134.532 89.0306 134.973 89.1645 135.518L90.1982 139.71L89.3273 139.921H89.3368Z"
                      fill="black"
                    />
                    <path
                      d="M81.4504 135.375L79.0863 135.748L78.9714 135.03L82.2161 134.513L83.2976 141.271L82.4171 141.414L81.4504 135.375Z"
                      fill="black"
                    />
                    <path
                      d="M77.7748 141.299L77.5738 139.021L75.0853 139.242L75.0183 138.505L77.5068 138.284L77.3058 135.939L74.5301 136.179L74.4631 135.442L78.1289 135.126L78.7223 141.95L75.0565 142.266L74.9896 141.529L77.7652 141.29L77.7748 141.299Z"
                      fill="black"
                    />
                    <path
                      d="M66.6058 136.944L69.592 137.059L70.2046 135.556L71.1425 135.595L68.4052 142.294L67.3715 142.256L65.1797 135.355L66.1177 135.394L66.6058 136.935V136.944ZM66.8355 137.681L67.9362 141.184L69.3049 137.777L66.8355 137.681Z"
                      fill="black"
                    />
                    <path
                      d="M53.627 140.332L54.9383 133.661L55.8188 133.834L54.8425 138.811L58.0393 134.274L58.6519 134.398L59.8961 139.816L60.8724 134.839L61.7529 135.011L60.4417 141.682L59.4941 141.5L58.1063 135.508L54.5554 140.533L53.6174 140.352L53.627 140.332Z"
                      fill="black"
                    />
                    <path
                      d="M51.0045 138.839L51.6744 136.648L49.2912 135.92L49.5114 135.212L51.8946 135.939L52.5837 133.69L49.9133 132.867L50.1335 132.159L53.6557 133.24L51.6457 139.796L48.1235 138.715L48.3437 138.007L51.014 138.83L51.0045 138.839Z"
                      fill="black"
                    />
                    <path
                      d="M45.9216 137.126L46.7639 135.001L44.4381 134.092L44.7061 133.403L47.0319 134.312L47.8933 132.12L45.2995 131.096L45.5675 130.407L48.9939 131.757L46.4863 138.131L43.0598 136.781L43.3278 136.092L45.9216 137.116V137.126Z"
                      fill="black"
                    />
                    <path
                      d="M37.6528 134.216L37.9687 133.566L39.6436 134.389L42.3331 128.895L43.1371 129.287L40.4476 134.781L42.1321 135.604L41.8163 136.255L37.6528 134.226V134.216Z"
                      fill="black"
                    />
                    <path
                      d="M35.6526 133.078L39.0121 127.115L39.7873 127.555L36.4279 133.518L35.6526 133.078Z"
                      fill="black"
                    />
                    <path
                      d="M33.3072 123.487L34.0538 123.985V130.512L37.0495 125.966L37.7961 126.464L34.0155 132.178L33.2689 131.68V125.162L30.2732 129.699L29.5266 129.211L33.3072 123.497V123.487Z"
                      fill="black"
                    />
                    <path
                      d="M25.1234 123.123C25.0181 123.554 25.0277 123.965 25.1616 124.367C25.2956 124.769 25.5541 125.124 25.9178 125.42C26.2815 125.727 26.6835 125.918 27.1237 125.995C27.564 126.071 27.9947 126.023 28.4254 125.851C28.8561 125.679 29.239 125.382 29.5835 124.97C29.9281 124.559 30.1482 124.128 30.2344 123.669C30.3301 123.219 30.2918 122.788 30.1387 122.367C29.9855 121.956 29.7271 121.592 29.3538 121.286C28.837 120.855 28.2914 120.663 27.7076 120.692C27.1237 120.721 26.5782 120.979 26.0901 121.448L28.1861 123.2L27.7172 123.755L24.8841 121.4L25.3244 120.874C25.7263 120.472 26.1953 120.194 26.7218 120.022C27.2482 119.859 27.7937 119.821 28.3488 119.926C28.904 120.032 29.4112 120.281 29.8707 120.663C30.3588 121.065 30.7129 121.554 30.9235 122.118C31.1341 122.683 31.1915 123.267 31.0862 123.87C30.9809 124.473 30.7129 125.028 30.2822 125.545C29.8515 126.062 29.3538 126.425 28.7796 126.636C28.2053 126.846 27.6214 126.904 27.028 126.799C26.4346 126.693 25.8986 126.435 25.4105 126.033C24.8554 125.564 24.4725 125.018 24.2715 124.387C24.0705 123.755 24.0801 123.104 24.3098 122.434L25.1329 123.123H25.1234Z"
                      fill="black"
                    />
                    <path
                      d="M21.4681 110.662L22.0233 111.361L15.132 116.902L14.5769 116.204L21.4681 110.662Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="rotate-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="105"
                    height="103"
                    viewBox="0 0 105 103"
                    fill="none"
                  >
                    <g opacity="0.5" clipPath="url(#clip0_375_8051)">
                      <g opacity="0.27">
                        <mask
                          id="mask0_375_8051"
                          maskUnits="userSpaceOnUse"
                          x="5"
                          y="2"
                          width="100"
                          height="99"
                        >
                          <path
                            d="M102.811 62.6276C108.912 36.2256 92.4008 9.88917 65.933 3.80349C39.4651 -2.28219 13.063 14.1875 6.96211 40.5895C0.861249 66.9915 17.372 93.3279 43.8398 99.4136C70.3077 105.499 96.7098 89.0297 102.811 62.6276Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_375_8051)">
                          <path
                            d="M42.2323 0.246094C28.6298 14.6485 14.0243 28.0584 0.413818 42.5163"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M48.4567 0.198486C33.302 16.3952 16.3565 32.1314 0.38208 48.1297"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M54.2113 0.206421C36.2231 18.1498 18.1076 36.2124 0.262695 54.0287"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M59.918 0.34137C40.3141 19.8965 20.4475 39.7137 0.310303 59.7849"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M66.9064 0.285828C44.9863 21.6433 22.7876 43.2071 0.374023 65.4141"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M71.4909 0.365234C48.4963 23.9775 25.7086 46.121 2.33198 70.0191C1.75095 70.5986 0.580923 70.5986 0.270508 71.4005"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M77.8427 0.262024C63.6909 15.236 48.4965 28.638 33.302 43.7947C22.1987 55.45 11.0954 65.3585 0.46167 76.9979"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M81.7825 1.4212C54.9197 28.638 26.8788 54.8624 0.38208 82.8176"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M81.7268 7.19324C54.9196 33.8861 27.4598 61.2776 0.477539 88.3435"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M84.6717 10.3293C56.6706 38.5466 28.0408 65.9381 0.493408 94.2109"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M90.4901 10.2976C60.1808 40.2933 30.9619 71.1861 0.23877 100.245"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M95.8864 10.504C64.8528 41.4604 34.4719 71.7657 3.44629 102.714"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M101.562 10.512C70.6951 40.8808 40.3142 71.7657 9.16919 102.77"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.618 13.132C74.7862 42.6275 45.5753 72.9328 14.9158 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.793 18.6262C76.5373 46.7085 49.6664 75.8467 20.6943 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.578 24.9302C78.2964 50.7815 52.5876 77.0138 26.4968 102.762"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.745 30.2736C80.6283 54.2828 56.6706 78.7684 32.3149 102.714"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.682 36.1489C82.3795 58.3638 60.1809 80.5152 38.022 102.786"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.753 41.905C84.7195 62.4368 64.2719 82.8414 43.8879 102.69"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.682 47.7883C86.4785 65.9461 67.7819 84.5961 49.6187 102.738"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.522 53.7509C88.2215 70.019 71.865 86.3348 55.3652 102.762"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.705 59.1577C93.3634 70.424 82.0372 81.7061 70.7747 93.0438C67.5511 96.2831 64.3356 99.5304 61.1279 102.786"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M104.666 65.6125C92.3208 78.1809 79.4664 91.0033 66.8906 102.794"
                            stroke="black"
                            strokeWidth="0.73"
                            strokeMiterlimit="10"
                          />
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_375_8051">
                        <rect width="105" height="103" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="rotate-link">
                  let's
                  <br />
                  go
                </div>
                <div className="rotate-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M1.24264 16.4455L16.4454 1.24266M16.4454 1.24266H7.96015M16.4454 1.24266V9.72795"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="globalBanner-text">
                <h5>
                  Bridging Businesses and Bytes with Custom Website Development
                  Services
                </h5>
                <p className="secondary">
                  Elevate your online presence with our unmatchable website
                  development services. We create stunning, responsive sites
                  tailored to your unique needs. Let us bring your vision to
                  life and drive results for your business. Contact us today for
                  a digital transformation that truly stands out.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="globalBanner-img">
                <img src={WebBanner} alt="" />
              </div>
            </Col>
          </Row>
          <Link className="phNumber">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M31.8617 19.6763C32.2677 12.5406 26.8122 6.42678 19.6765 6.02077C12.5408 5.61475 6.42698 11.0702 6.02097 18.2059C5.61495 25.3416 11.0704 31.4554 18.2061 31.8615C21.6331 32.0565 24.9972 30.882 27.5581 28.5965C30.1187 26.3114 31.6667 23.1027 31.8617 19.6763ZM19.5781 27.2949C19.578 27.2949 19.5779 27.2949 19.5778 27.2949C19.577 27.2949 19.5766 27.2939 19.5772 27.2934C19.5779 27.2928 19.5775 27.2918 19.5767 27.2918L18.6563 27.2362C17.451 27.175 16.3652 26.4886 15.793 25.426C15.2419 24.3328 14.8695 23.1584 14.6899 21.9474C14.5105 20.8335 14.454 19.7032 14.5217 18.577C14.5766 17.5473 14.7343 16.5256 14.992 15.5272C15.2627 14.4453 15.6818 13.4061 16.2376 12.4391C16.8971 11.3107 18.1376 10.6517 19.4419 10.7369L20.526 10.7987C20.8281 10.8153 21.0596 11.0737 21.043 11.3758C21.043 11.3765 21.043 11.3774 21.0429 11.3782L20.8476 14.8011C20.831 15.1032 20.5727 15.3347 20.2706 15.3181C20.2698 15.3181 20.269 15.318 20.2681 15.318L18.2582 15.2033C17.6848 15.1674 17.1716 15.5565 17.0512 16.1183C16.8666 16.9728 16.7497 17.8406 16.7014 18.7136C16.6436 19.6905 16.6725 20.6706 16.7879 21.6424C16.8552 22.2034 17.314 22.6359 17.878 22.67L19.837 22.7665C20.1391 22.7831 20.3706 23.0414 20.354 23.3435C20.354 23.3443 20.3539 23.3451 20.3539 23.346L20.155 26.7779C20.1386 27.08 19.8802 27.3115 19.5781 27.2949Z"
                  fill="black"
                />
              </svg>
            </span>
            (033) 4804-7123
          </Link>
        </Container>
        <div className="innerLine">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>

      <section className="businessIntro services">
        <Container>
          <Row>
            <Col sm={12}>
              <h3 className="text-white">
                Providing Web Development Services that Work
              </h3>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={5}>
              <div className="businessIntro-image">
                <img src={WebSub} alt="" />
              </div>
            </Col>
            <Col md={7} className="mt-4 mt-md-0">
              <p className="primary">
                Website development, refers to the tasks involved in creating,
                constructing, and maintaining websites and web applications that
                run online through a browser. However, it may also include web
                design, web programming, and database management.
              </p>
              <p className="primary">
                With the best web development services, it is possible to
                escalate your business and drive good revenue right away. An
                attractive web design always welcomes better traffic.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceCarousel">
        <Container>
          <Row className="justify-content-center">
            <Col xl={9}>
              <h3>Helping Businesses Build a Robust Presence Online</h3>
            </Col>
          </Row>
          <div className="imageSlider">
            <Swiper
              spaceBetween={0}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 5,
                slideShadows: true,
              }}
              pagination={false}
              modules={[EffectCoverflow, Pagination, Autoplay]}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              className="mySlider"
            >
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={WebSlider1} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Full Stack Web Development</h4>
                    <p>
                      Full-stack development is a comprehensive solution in
                      which the developer handles both front-end and back-end
                      development. To avoid double hiring, software companies
                      usually take a chance on full-stack developers who can
                      efficiently manage both the client and server sides. The
                      programming languages used in this process are React.JS
                      and Angular.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={WebSlider2} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>ECommerce Web Development</h4>
                    <p>
                      Because of the convenience of online shopping, millennials
                      prefer it. It has been discovered that online shopping
                      generates more profits than traditional retail stores.
                      Businesses are eager to sell their products online,
                      resulting in high demand for eCommerce website development
                      services.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={WebSlider3} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>CMS Website Development</h4>
                    <p>
                      WordPress, Jumla, and Drupal are examples of CMS (Content
                      Management Systems). With attractive website design, SEO
                      friendliness, and additional plugins tailored to your
                      needs, website development on these platforms is
                      relatively simple. These platforms are simple to use and
                      enable multiple users to manage data by creating, editing,
                      or publishing content.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={WebSlider1} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Static Web Service</h4>
                    <p>
                      The static web service, which is just HTML and rarely has
                      its content modified, is one of the less common types of
                      web services. Such static websites are appropriate for
                      straightforward informational websites.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideWrapper">
                  <div className="slideImage">
                    <div className="slideImageBox">
                      <img src={WebSlider2} alt="" />
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>PHP Development</h4>
                    <p>
                      Although MySQL is frequently used, PHP is a free,
                      open-source programming language that is also well-suited
                      for web development. It has a range of libraries,
                      packages, and frameworks (like Laravel or Zend). However,
                      PHP is also known for its ambiguity and shoddy design,
                      which can easily result in subpar code.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>

      <section className="proccess">
        <Container>
          <Row className="justify-content-center">
            <Col xl={9}>
              <h3 className="text-center">
                Know How Web Development Process Takes Place
              </h3>
            </Col>
          </Row>
        </Container>
        <Container className="proccess-list">
          <Row>
            <Col className="proccess-details" md={6}>
              <h3 className="gradient">01</h3>
              <div>
                <div className="proccess-details-head">Identifying Goal</div>
                <p>
                  Planning is the stage in which the technology stack and
                  software development methodology are chosen, the deliverables
                  are defined, and the timeline and resources required to
                  complete the project are estimated. Other critical decisions
                  include developing the content structure and sitemap,
                  wireframing, planning the layout, and designing the UI and UX.
                </p>
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <div className="proccess-image" x>
                <img src={WebTecnology1} alt="" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="order-2 order-md-1 mt-4 mt-md-0">
              <div className="proccess-image" x>
                <img src={WebTecnology2} alt="" />
              </div>
            </Col>
            <Col className="proccess-details order-1 order-md-2" md={6}>
              <h3 className="gradient">02</h3>
              <div>
                <div className="proccess-details-head">Structure Designing</div>
                <p>
                  After developing wireframes and creating a sitemap, it’s time
                  to start working on the website design. The creative UI
                  designers come in, working with the client’s approval and the
                  project’s requirements. The wireframes are turned into
                  typography, color graphics, animations, and buttons. One of
                  the key factors considered for the design is the target
                  audience.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="proccess-details" md={6}>
              <h3 className="gradient">03</h3>
              <div>
                <div className="proccess-details-head">
                  Building and Developing
                </div>
                <p>
                  Once all project stakeholders have approved the design, the
                  next step is to develop the website itself, which is a
                  significant undertaking. There is also the task of content
                  creation, along with frontend and backend development.
                </p>
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <div className="proccess-image" x>
                <img src={WebTecnology3} alt="" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="order-2 order-md-1 mt-4 mt-md-0">
              <div className="proccess-image" x>
                <img src={WebTecnology4} alt="" />
              </div>
            </Col>
            <Col className="proccess-details order-1 order-md-2" md={6}>
              <h3 className="gradient">04</h3>
              <div>
                <div className="proccess-details-head">Testing the Website</div>
                <p>
                  Before deploying the website to a server, there should be a
                  series of rigorous, meticulous, and repeated tests to
                  demonstrate the absence of bugs. The quality assurance team
                  examines functionality, usability, compatibility, and
                  performance, among other things. Aside from the tools that
                  help in website testing, only manual testing doesn’t require
                  anything.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="proccess-details" md={6}>
              <h3 className="gradient">05</h3>
              <div>
                <div className="proccess-details-head">
                  Launching and Delivery
                </div>
                <p>
                  After launching a website, there is still work to do. Website
                  maintenance, general updates, and new features are
                  unavoidable. However, this can be made easier by soliciting
                  feedback from website visitors. The goal of maintenance is to
                  support the project on an ongoing basis.
                </p>
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <div className="proccess-image">
                <img src={WebTecnology5} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="industry serviceInner">
        <div className="industry-wrapper">
          <Container>
            <Row>
              <Col sm={12}>
                <p className="secondary">What we cater</p>
                <h3>
                  Industries Benefiting from Custom Software Application
                  Development Services
                </h3>
              </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="industry-tab">
                <Col md={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={EcommerceImage} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Tours and Travel Agencies</h5>
                          <p className="secondary">
                            The travel industry is the first to benefit from
                            custom web design programming. Families no longer
                            visit travel agents to plan vacations. Customers in
                            the age of instant gratification sit at their
                            computers and begin planning a vacation.
                          </p>

                          <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={EcommerceImage} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Small Businesses of Different Niches</h5>
                          <p className="secondary">
                            Small sized businesses have fewer employees, it is
                            common for the entire team to wear multiple hats.
                            Hiring the right experts on an ongoing basis
                            simplifies and streamlines the whole process.
                          </p>

                          <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={EcommerceImage} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Food Chains</h5>
                          <p className="secondary">
                            If your restaurant has a strong web presence,
                            potential customers can research your menu well in
                            advance. Similarly, restaurants with appealing web
                            designs are frequently active on social media
                            channels like Facebook, Instagram, and Twitter.
                          </p>

                          <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
                <Col md={3}>
                  <Nav variant="pills" className="flex-md-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <span>01</span>
                        Tours and Travel Agencies
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <span>02</span>
                        Small Businesses of Multiple Kinds
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <span>03</span>
                        Food Chains
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </section>

      <section className="points dark">
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="text-white">
                Design the Digital Future with Techwens
              </h3>
            </Col>
            <Col className="position-relative">
              <div className="development">
                <img src={serviceDevelopment} alt="" />
              </div>
            </Col>
          </Row>
          <ul className="mt-4 mt-md-0">
            <li>
              <div className="d-flex align-items-center">
                <span>Enhanced Visibility on Search Engines</span>
              </div>

              <p className="primary">
                Only when the website appears among the top results on search
                engines can it be considered a smart sales and marketing tool
                for your company.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <span>Making it ADA Compliance</span>
              </div>
              <p className="primary">
                ADA compliance is a legal requirement, particularly in the
                United States. With ADA compliance, a website is made accessible
                to people with disabilities.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <span>Implementing Progressive Web Application</span>
              </div>
              <p className="primary">
                ADA compliance is a legal requirement, particularly in the
                United States. With ADA compliance, a website is made accessible
                to people with disabilities.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center">
                <span>Use of Latest Technologies</span>
              </div>
              <p className="primary">
                Website development technologies are rapidly evolving and
                changing. Various new tools, features, and plugins are being
                developed to enhance the look, experience, security, and
                performance of websites.
              </p>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
};

export default Web;
