import React from "react";

import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import StickyProject from "../../Assets/Images/HomeImages/StickyProject.png";
import StickyProject2 from "../../Assets/Images/HomeImages/StickyProject2.png";

const ProjectCase = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Project Case Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="project">
        <Container>
          <p className="project-head">Our all Project Case Study's</p>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={7} lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject}></img>
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2}></img>
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>
                        Sticky <br />
                        Status
                      </h2>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject} />
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2} />
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>Goeen</h2>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject} />
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2} />
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>
                        Knight <br />
                        Hunt
                      </h2>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="forth">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject} />
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2} />
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>
                        Carbon <br />
                        Credit
                      </h2>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject} />
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2} />
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>
                        pay
                        <br />
                        roll
                      </h2>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject} />
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2} />
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>
                        trek <br />
                        rader
                      </h2>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="seventh">
                    <div className="d-flex align-items-end position-relative">
                      <div className="stickyOne">
                        <img src={StickyProject} />
                      </div>
                      <div className="stickyTwo">
                        <img src={StickyProject2} />
                      </div>
                      <p>
                        View the full case study of our recent featured and
                        unique projects that throws light on our team's hardwork
                        and extreme dedication.
                      </p>
                      {/* <Link className="primary-button">
                        View
                        <br />
                        all Projects
                      </Link> */}
                      <h2>
                        Blinds &<br />
                        Shades
                      </h2>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col sm={5} lg={3} className="mt-5 mt-sm-0">
                <div className="project-wrapper">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <span>01</span>
                        Sticky Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <span>02</span>
                        Goeen
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <span>03</span>
                        Knight Hunt
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="forth">
                        <span>04</span>
                        Carbon Credit
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">
                        <span>05</span>
                        Payroll Management
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sixth">
                        <span>06</span>
                        Trekrader
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seventh">
                        <span>07</span>
                        Blind & Shades
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default ProjectCase;
