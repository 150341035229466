import React, { useEffect, useRef } from "react";
import "../../Style/Modules/Home.scss";
import "../../Style/Modules/Common.scss";
import "../../Style/Modules/Responsive.scss";
import Banner from "./Banner";
import Service from "./Service";
import Industry from "./Industry";
import DemandServices from "./DemandServices";
import Technologies from "./Technologies";
import GrowthStage from "./GrowthStage";
import ProjectCase from "./ProjectCase";
import DevelopProduct from "./DevelopProduct";
import Blog from "./Blog";
import Contact from "./Contact";

const Home = () => {
  const scrollToRef = useRef();
  useEffect(() => {
    // Scroll to the target element
    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <section ref={scrollToRef}>
        {/* ~~~~~~~~~~~~~~~~~~~~ Banner Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <Banner />

        {/* ~~~~~~~~~~~~~~~~~~~~ Home Service Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <Service />

        {/* ~~~~~~~~~~~~~~~~~~~~ Industry Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <Industry />

        {/* ~~~~~~~~~~~~~~~~~~~~ Demand Services Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <DemandServices />

        {/* ~~~~~~~~~~~~~~~~~~~~ Technologies Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <Technologies />

        {/* ~~~~~~~~~~~~~~~~~~~~ Stage Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <GrowthStage />

        {/* ~~~~~~~~~~~~~~~~~~~~ Project Case Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <ProjectCase />

        {/* ~~~~~~~~~~~~~~~~~~~~ Develop Products Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <DevelopProduct />

        {/* ~~~~~~~~~~~~~~~~~~~~ Blog Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <Blog />

        {/* ~~~~~~~~~~~~~~~~~~~~ Home Contact Section~~~~~~~~~~~~~~~~~~~~~~~*/}
        <Contact />
      </section>
    </>
  );
};

export default Home;
