import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Index";
import About from "../Pages/AboutUs/About";
import Service from "../Pages/Services/Service";
import Contact from "../Pages/Contact/Contact";
import BlogAll from "../Pages/Blog/BlogAll";
import Application from "../Pages/Services/ServicePages/Application";
import Web from "../Pages/Services/ServicePages/Web";
import MobileApp from "../Pages/Services/ServicePages/MobileApp";
import UiUx from "../Pages/Services/ServicePages/UiUx";
import CloudService from "../Pages/Services/ServicePages/CloudService";
import SeoService from "../Pages/Services/ServicePages/SeoService";
import SocialMedia from "../Pages/Services/ServicePages/SocialMedia";
import Branading from "../Pages/Services/ServicePages/Branading";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import BlogDetails from "../Pages/Blog/BlogDetails";
import Career from "../Pages/Career/Career";

const Index = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/blog" element={<BlogAll />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/application" element={<Application />} />
        <Route path="/web" element={<Web />} />
        <Route path="/mobileApp" element={<MobileApp />} />
        <Route path="/uiUx" element={<UiUx />} />
        <Route path="/cloudService" element={<CloudService />} />
        <Route path="/seoService" element={<SeoService />} />
        <Route path="/socialMedia" element={<SocialMedia />} />
        <Route path="/branding" element={<Branading />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Index;
