import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "../../Style/Modules/About.scss";
import api from "../../Api/api";


function BlogDetails() {
  const location = useLocation();
  const post = location.state.post;
  // console.log("location", post);
  return (
    <section className="globalBanner">
      <Container>
        <div className="BlogDetails">
          <div dangerouslySetInnerHTML={{ __html: post?.content?.rendered }} />
        </div>
      </Container>
    </section>
  );
}

export default BlogDetails;
