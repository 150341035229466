import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ServiceImg from "../../Assets/Images/HomeImages/ServiceImg.png";

const Service = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Service Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="homeServices">
        <Container>
          <Row>
            <Col md={8} xl={7} xxl={6} className="d-md-flex">
              <div className="pe-4 d-flex flex-column justify-content-end">
                <p className="services">Services</p>
                <p className="servicesName">
                  Software Development Services to Look For
                </p>
              </div>
              <div className="learnMore ps-md-4">
                <p className="primary">
                  Curating various types of software development services that
                  simultaneously throw light on the field of technology and
                  innovation.
                </p>
                <Link>
                  Learn More
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="10"
                      viewBox="0 0 17 10"
                      fill="none"
                    >
                      <path
                        d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </Col>

            <Col
              md={4}
              xl={5}
              xxl={6}
              className="d-md-flex justify-content-end mt-3 mt-md-0"
            >
              <Link to="/service" className="primary-button">
                View
                <br />
                all Services
              </Link>
            </Col>
          </Row>
          <Row className="homeServices-info">
            <Col md={4}>
              <div className="homeServices-image">
                <img src={ServiceImg}></img>
              </div>
            </Col>
            <Col md={8} className="homeServices-info-about mt-5 mt-md-0">
              <p>
                <span>R&D</span>
                <span>Scoping Session&D</span>
                <span>UI/UX Design</span>
                <span>UX reviewing</span>
              </p>
              <ul>
                <li>
                  <span>1</span>
                  <h5>Web Development</h5>
                  <p>
                    Building websites from scratch or redesigning existing ones.
                    Using programming languages like HTML, CSS, JavaScript, and
                    various web frameworks to build the structure and design of
                    a website.
                  </p>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                    >
                      <path
                        d="M1.12091 29.8372L29.2043 1.75385M29.2043 1.75385L13.5298 1.75385M29.2043 1.75385L29.2043 17.4283"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <span>2</span>
                  <h5>Android & iOS App Development</h5>
                  <p>
                    Creation of mobile applications for the Android and iOS
                    operating systems that involve designing, coding, and
                    testing software applications to run on smartphones and
                    tablets, catering to the specific requirements of each
                    platform to provide users with a seamless and optimized
                    mobile experience.
                  </p>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                    >
                      <path
                        d="M1.12091 29.8372L29.2043 1.75385M29.2043 1.75385L13.5298 1.75385M29.2043 1.75385L29.2043 17.4283"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <span>3</span>
                  <h5> UI/UX Designing</h5>
                  <p>
                    Curating user interfaces and experiences that enhance a
                    digital product's usability and overall satisfaction, such
                    as a website or mobile app, by focusing on the visual and
                    interactive elements that shape the user's interaction with
                    the product.
                  </p>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                    >
                      <path
                        d="M1.12091 29.8372L29.2043 1.75385M29.2043 1.75385L13.5298 1.75385M29.2043 1.75385L29.2043 17.4283"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <span>4</span>
                  <h5>Data Science & Ai</h5>
                  <p>
                    Data Science is the need of the hour. We have a dedicated
                    team to implement the new age AI for your business.
                  </p>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                    >
                      <path
                        d="M1.12091 29.8372L29.2043 1.75385M29.2043 1.75385L13.5298 1.75385M29.2043 1.75385L29.2043 17.4283"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <span>5</span>
                  <h5>Web & Mobile Development</h5>
                  <p>
                    Web and Mobile Development is everything you need to boost
                    your business right away. Hence, count on us to get this job
                    done seamlessly.
                  </p>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                    >
                      <path
                        d="M1.12091 29.8372L29.2043 1.75385M29.2043 1.75385L13.5298 1.75385M29.2043 1.75385L29.2043 17.4283"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Service;
