import React, { useEffect, useState } from "react";
import { Navbar, Nav, Col, Container, Row, Form } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "../../Style/Modules/Header.scss";
import "../../Style/Base/Variable.scss";
import { Link } from "react-router-dom";
// import logo from "../../images/Svg/Svg.jsx";

const Header = () => {
  const [pathName, setPathName] = useState("/");
  const [headerClass, setHeaderClass] = useState("");
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  let [servicePage, setservicePage] = useState(false);

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };
  const ServiceActiveRouteCheck = (path) => {
    setToggleMobileMenu(false);
    setservicePage(true);
    return location.pathname === path;
  };

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setHeaderClass("scrollHeader");
    } else {
      setHeaderClass("");
    }
  };
  const location = useLocation(); // Use useLocation hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setPathName(location.pathname); // Use location.pathname to get the current pathname
  }, [location.pathname]);

  return (
    <>
      <header className={headerClass}>
        <Container>
          <Row>
            <Col>
              <Navbar
                expand="lg"
                className={`${pathName === "/" ? "" : "dark"}`}
              >
                <Link to="/">
                  <Navbar.Brand>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="67"
                      height="56"
                      viewBox="0 0 67 56"
                      fill="none"
                      className="logo"
                    >
                      <path
                        d="M47.3753 30.6174C47.3137 30.2694 47.1907 29.9214 47.0061 29.6313C47.1292 29.9214 47.2522 30.2114 47.3137 30.5014L47.3753 30.6174Z"
                        fill="#011138"
                      />
                      <path
                        d="M37.5928 29.2834L30.6404 39.2022L33.5321 49.6431C33.9628 51.1512 33.5936 52.6593 32.7323 53.8194C32.7938 53.7034 32.8553 53.6454 32.9784 53.5294L40.1154 43.4366L37.2237 32.9957C36.793 31.6616 36.9776 30.3855 37.5928 29.2834Z"
                        fill="#011138"
                      />
                      <path
                        d="M31.8711 51.2671L31.8088 51.3251C31.8711 51.3251 31.8711 51.2671 31.8711 51.2671Z"
                        fill="#011138"
                      />
                      <path
                        d="M47.6206 55.6755C47.9898 55.6755 48.4204 55.6175 48.7896 55.5015C50.3893 55.0955 51.6813 54.0514 52.2966 52.7173L51.8659 53.3553C50.8815 54.8055 49.2818 55.6175 47.6206 55.6755Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M45.283 27.6594C46.0829 28.1814 46.6366 28.8195 47.0058 29.5735C45.9598 27.4274 43.4373 26.2673 40.9147 26.8473C39.4381 27.1953 38.3306 28.1234 37.6538 29.2835L37.9614 28.8775C39.6226 26.6153 42.8835 26.0933 45.283 27.6594Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M48.913 36.4181L47.3133 30.5596L47.2518 30.4436C47.1902 30.1536 47.0672 29.8635 46.9441 29.5735C46.575 28.8195 45.9597 28.1234 45.2214 27.6594C42.8219 26.0932 39.561 26.6153 37.8998 28.8775L37.5922 29.2835C36.9769 30.3276 36.7308 31.6617 37.1 32.9378L39.9917 43.3786L41.5914 49.2951C41.4683 48.251 41.7144 47.1489 42.3912 46.1628L48.913 36.4181Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M31.9324 51.2091L31.8701 51.2671C31.8701 51.2091 31.9324 51.2091 31.9324 51.2091Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M9.35196 7.87988H5.10666C2.27646 7.87988 0 10.0261 0 12.6943C0 15.3625 2.27646 17.5086 5.10666 17.5086H11.9976C11.9976 17.4506 9.35196 7.87988 9.35196 7.87988Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M34.5155 8.22776C33.9002 7.99574 33.285 7.87973 32.5467 7.87973H22.2718L21.1643 3.7034C20.426 1.03519 17.5343 -0.530931 14.7656 0.165124C11.9354 0.861179 10.2742 3.52939 11.0126 6.1976L11.4432 7.87973L13.9658 17.1605L14.0273 17.5085L23.3178 52.1952C24.0561 54.8634 26.8863 56.4296 29.7165 55.7335C30.8855 55.4435 31.8699 54.8054 32.5467 53.9934L32.6082 53.9354L32.6697 53.8774C33.5311 52.7173 33.9002 51.2092 33.4696 49.701L30.0856 36.476L24.8559 17.5085H32.4851C35.3153 17.5085 37.5918 15.3623 37.5918 12.6941C37.6533 10.664 36.3613 8.92381 34.5155 8.22776Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M42.3914 51.9632L42.2068 51.2091C42.3298 52.7172 43.1912 54.1093 44.6063 54.9214C45.4061 55.3854 46.329 55.6175 47.1904 55.6755C44.9755 55.5595 43.0066 54.1093 42.3914 51.9632Z"
                        fill="#00D392"
                      />
                      <path
                        d="M66.2631 29.2836C66.2016 29.2256 66.14 29.2256 66.0785 29.1676C65.5248 28.9356 64.848 29.0516 64.4173 29.4576L64.3558 29.5156L63.9251 30.1536L63.0022 31.5458L60.6642 35.142C60.6642 35.2 60.6027 35.2 60.6027 35.258L59.6183 36.8242C59.003 37.8103 57.6494 38.1003 56.6035 37.5202C55.5576 36.9402 55.2499 35.6641 55.8652 34.678L56.7266 33.3439L57.6494 31.9518C57.711 31.8358 57.7725 31.7778 57.7725 31.6618C58.0186 31.0237 57.7725 30.2117 57.0957 29.8636C56.4189 29.4576 55.6191 29.5736 55.1269 30.0956C55.0654 30.1536 55.0038 30.2697 54.9423 30.3277L51.0662 36.1281C51.0662 36.1861 51.0046 36.1861 51.0046 36.2441L49.528 38.5063L42.9447 48.2511C42.2679 49.2372 42.0834 50.3392 42.1449 51.3833L42.3295 52.1374C42.9447 54.2836 44.9136 55.7337 47.1285 55.8497H47.4976C49.1589 55.7917 50.7585 54.9796 51.7429 53.5295L52.1736 52.8914L63.8636 35.4901C63.9866 35.258 64.1097 35.026 64.2327 34.794L65.7709 32.3578L66.3861 31.3137L66.6938 30.7917C67.0629 30.3277 66.8168 29.5736 66.2631 29.2836Z"
                        fill="#00D392"
                      />
                      <path
                        d="M57.5271 36.1857C58.1423 36.5337 58.8806 36.3597 59.2498 35.7797L60.6034 33.6335C60.9725 33.0535 60.788 32.3574 60.1727 32.0094C59.5574 31.6614 58.8191 31.8354 58.45 32.4154L57.0964 34.5616C56.7272 35.1416 56.9118 35.8957 57.5271 36.1857Z"
                        fill="#00D392"
                      />
                      <path
                        d="M60.3571 29.1675L63.7411 23.889C64.1102 23.309 64.8485 23.135 65.4638 23.483C66.0791 23.831 66.2636 24.5271 65.8945 25.1071L62.5105 30.3856C62.1414 30.9656 61.4031 31.1396 60.7878 30.7916C60.1726 30.5016 59.988 29.7475 60.3571 29.1675Z"
                        fill="#00D392"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="67"
                      height="56"
                      viewBox="0 0 67 56"
                      fill="none"
                      className="homeLogo"
                    >
                      <path
                        d="M47.3752 30.6174C47.3137 30.2694 47.1906 29.9214 47.006 29.6313C47.1291 29.9214 47.2521 30.2114 47.3137 30.5014L47.3752 30.6174Z"
                        fill="#011138"
                      />
                      <path
                        d="M37.5928 29.2834L30.6403 39.2022L33.532 49.6431C33.9627 51.1512 33.5936 52.6593 32.7322 53.8194C32.7937 53.7034 32.8553 53.6454 32.9783 53.5294L40.1153 43.4366L37.2236 32.9957C36.7929 31.6616 36.9775 30.3855 37.5928 29.2834Z"
                        fill="#8C8C8C"
                      />
                      <path
                        d="M31.8712 51.2671L31.8089 51.3251C31.8712 51.3251 31.8712 51.2671 31.8712 51.2671Z"
                        fill="#011138"
                      />
                      <path
                        d="M47.6206 55.6755C47.9898 55.6755 48.4204 55.6175 48.7896 55.5015C50.3893 55.0955 51.6813 54.0514 52.2966 52.7173L51.8659 53.3553C50.8815 54.8055 49.2818 55.6175 47.6206 55.6755Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M45.2829 27.6594C46.0828 28.1814 46.6365 28.8195 47.0056 29.5735C45.9597 27.4274 43.4371 26.2673 40.9146 26.8473C39.4379 27.1953 38.3305 28.1234 37.6537 29.2835L37.9613 28.8775C39.6225 26.6153 42.8834 26.0933 45.2829 27.6594Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M48.9131 36.4181L47.3134 30.5596L47.2519 30.4436C47.1904 30.1536 47.0673 29.8635 46.9443 29.5735C46.5751 28.8195 45.9598 28.1234 45.2215 27.6594C42.822 26.0932 39.5611 26.6153 37.8999 28.8775L37.5923 29.2835C36.977 30.3276 36.7309 31.6617 37.1001 32.9378L39.9918 43.3786L41.5915 49.2951C41.4684 48.251 41.7145 47.1489 42.3913 46.1628L48.9131 36.4181Z"
                        fill="white"
                      />
                      <path
                        d="M31.9325 51.2091L31.8702 51.2671C31.8702 51.2091 31.9325 51.2091 31.9325 51.2091Z"
                        fill="#0A1D56"
                      />
                      <path
                        d="M9.35196 7.87988H5.10666C2.27646 7.87988 0 10.0261 0 12.6943C0 15.3625 2.27646 17.5086 5.10666 17.5086H11.9976C11.9976 17.4506 9.35196 7.87988 9.35196 7.87988Z"
                        fill="white"
                      />
                      <path
                        d="M34.5154 8.22776C33.9002 7.99574 33.2849 7.87973 32.5466 7.87973H22.2718L21.1643 3.7034C20.426 1.03519 17.5343 -0.530931 14.7656 0.165124C11.9354 0.861179 10.2742 3.52939 11.0125 6.1976L11.4432 7.87973L13.9657 17.1605L14.0273 17.5085L23.3177 52.1952C24.056 54.8634 26.8862 56.4296 29.7164 55.7335C30.8854 55.4435 31.8698 54.8054 32.5466 53.9934L32.6081 53.9354L32.6697 53.8774C33.531 52.7173 33.9002 51.2092 33.4695 49.701L30.0856 36.476L24.8559 17.5085H32.4851C35.3153 17.5085 37.5917 15.3623 37.5917 12.6941C37.6533 10.664 36.3612 8.92381 34.5154 8.22776Z"
                        fill="white"
                      />
                      <path
                        d="M42.3915 51.9632L42.2069 51.2091C42.33 52.7172 43.1913 54.1093 44.6064 54.9214C45.4063 55.3854 46.3292 55.6175 47.1905 55.6755C44.9756 55.5595 43.0067 54.1093 42.3915 51.9632Z"
                        fill="#00D392"
                      />
                      <path
                        d="M66.2632 29.2836C66.2017 29.2256 66.1402 29.2256 66.0786 29.1676C65.5249 28.9356 64.8481 29.0516 64.4174 29.4576L64.3559 29.5156L63.9252 30.1536L63.0023 31.5458L60.6643 35.142C60.6643 35.2 60.6028 35.2 60.6028 35.258L59.6184 36.8242C59.0031 37.8103 57.6496 38.1003 56.6036 37.5202C55.5577 36.9402 55.2501 35.6641 55.8653 34.678L56.7267 33.3439L57.6496 31.9518C57.7111 31.8358 57.7726 31.7778 57.7726 31.6618C58.0187 31.0237 57.7726 30.2117 57.0958 29.8636C56.4191 29.4576 55.6192 29.5736 55.127 30.0956C55.0655 30.1536 55.004 30.2697 54.9424 30.3277L51.0663 36.1281C51.0663 36.1861 51.0048 36.1861 51.0048 36.2441L49.5281 38.5063L42.9448 48.2511C42.2681 49.2372 42.0835 50.3392 42.145 51.3833L42.3296 52.1374C42.9448 54.2836 44.9137 55.7337 47.1286 55.8497H47.4978C49.159 55.7917 50.7587 54.9796 51.7431 53.5295L52.1738 52.8914L63.8637 35.4901C63.9868 35.258 64.1098 35.026 64.2329 34.794L65.771 32.3578L66.3863 31.3137L66.6939 30.7917C67.0631 30.3277 66.8169 29.5736 66.2632 29.2836Z"
                        fill="#00D392"
                      />
                      <path
                        d="M57.527 36.1857C58.1422 36.5337 58.8805 36.3597 59.2497 35.7797L60.6033 33.6335C60.9724 33.0535 60.7878 32.3574 60.1726 32.0094C59.5573 31.6614 58.819 31.8354 58.4498 32.4154L57.0963 34.5616C56.7271 35.1416 56.9117 35.8957 57.527 36.1857Z"
                        fill="#00D392"
                      />
                      <path
                        d="M60.357 29.1675L63.7409 23.889C64.1101 23.309 64.8484 23.135 65.4637 23.483C66.0789 23.831 66.2635 24.5271 65.8944 25.1071L62.5104 30.3856C62.1413 30.9656 61.403 31.1396 60.7877 30.7916C60.1724 30.5016 59.9879 29.7475 60.357 29.1675Z"
                        fill="#00D392"
                      />
                    </svg>
                  </Navbar.Brand>
                </Link>
                <div className="navbar-right">
                  <Link
                    className="navbar-icon"
                    onClick={() => setToggleMobileMenu(!toggleMobileMenu)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="14"
                      viewBox="0 0 24 14"
                      fill="none"
                    >
                      <path
                        d="M2.59232 0C1.21372 0 0.0922852 1.12151 0.0922852 2.50004C0.0922852 3.87857 1.21325 5 2.59232 5C3.97139 5 5.09236 3.87857 5.09236 2.50004C5.09236 1.12151 3.97085 0 2.59232 0Z"
                        fill="white"
                      />
                      <path
                        d="M11.5918 0C10.2133 0 9.0918 1.12151 9.0918 2.50004C9.0918 3.87857 10.2133 5 11.5918 5C12.9704 5 14.0921 3.87857 14.0921 2.50004C14.0921 1.12151 12.9709 0 11.5918 0Z"
                        fill="white"
                      />
                      <path
                        d="M20.5923 5C21.9706 5 23.0923 3.87858 23.0923 2.50008C23.0923 1.12157 21.9715 0 20.5923 0C19.2131 0 18.0923 1.12149 18.0923 2.5C18.0923 3.87851 19.2139 5 20.5923 5Z"
                        fill="white"
                      />
                      <path
                        d="M2.50039 13.7316C3.87885 13.7316 5.00054 12.6104 5.00054 11.2317C5.00054 9.85295 3.87885 8.73157 2.50039 8.73157C1.12192 8.73157 0 9.85303 0 11.2317C0 12.6104 1.12192 13.7316 2.50039 13.7316Z"
                        fill="white"
                      />
                      <path
                        d="M11.4999 13.7316C12.8782 13.7316 13.9998 12.6104 13.9998 11.2317C13.9998 9.85295 12.8791 8.73157 11.4999 8.73157C10.121 8.73157 9 9.85295 9 11.2317C9 12.6104 10.1215 13.7316 11.4999 13.7316Z"
                        fill="white"
                      />
                      <path
                        d="M20.5009 13.7316C21.8796 13.7316 23.0008 12.6104 23.0008 11.2317C23.0008 9.85295 21.8796 8.73157 20.5009 8.73157C19.1221 8.73157 18 9.85295 18 11.2317C18 12.6104 19.1217 13.7316 20.5009 13.7316Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                  <ul className={`${toggleMobileMenu && "open"} navbar-menu`}>
                    <li className="position-relative d-block d-md-none deskMenu">
                      <Link
                        className="mobilemenuClose"
                        onClick={() => {
                          setToggleMobileMenu(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 40 40"
                          fill="#0a1d56"
                        >
                          <path
                            d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56823 3.03617 8.69181 1.52242 12.3463C0.00866576 16.0009 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9992 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C39.9937 14.6976 37.8846 9.61414 34.1352 5.86477C30.3859 2.1154 25.3024 0.0062554 20 0ZM27.7127 25.1436C27.8864 25.3114 28.0249 25.512 28.1202 25.7338C28.2155 25.9556 28.2656 26.1942 28.2677 26.4356C28.2698 26.677 28.2238 26.9165 28.1324 27.1399C28.041 27.3634 27.906 27.5664 27.7353 27.7371C27.5646 27.9078 27.3615 28.0428 27.1381 28.1342C26.9147 28.2256 26.6752 28.2716 26.4338 28.2695C26.1924 28.2674 25.9538 28.2173 25.732 28.122C25.5102 28.0267 25.3095 27.8882 25.1418 27.7145L20 22.5709L14.8582 27.7145C14.5153 28.0457 14.056 28.229 13.5793 28.2249C13.1026 28.2207 12.6465 28.0295 12.3094 27.6924C11.9723 27.3553 11.7811 26.8993 11.777 26.4225C11.7728 25.9458 11.9561 25.4865 12.2873 25.1436L17.4291 20L12.2873 14.8564C12.1136 14.6886 11.9751 14.488 11.8798 14.2662C11.7845 14.0444 11.7344 13.8058 11.7323 13.5644C11.7302 13.3229 11.7762 13.0835 11.8676 12.8601C11.959 12.6366 12.094 12.4336 12.2647 12.2629C12.4355 12.0922 12.6385 11.9572 12.8619 11.8658C13.0854 11.7744 13.3248 11.7284 13.5662 11.7305C13.8076 11.7325 14.0462 11.7827 14.268 11.878C14.4898 11.9733 14.6905 12.1118 14.8582 12.2855L20 17.4291L25.1418 12.2855C25.3095 12.1118 25.5102 11.9733 25.732 11.878C25.9538 11.7827 26.1924 11.7325 26.4338 11.7305C26.6752 11.7284 26.9147 11.7744 27.1381 11.8658C27.3615 11.9572 27.5646 12.0922 27.7353 12.2629C27.906 12.4336 28.041 12.6366 28.1324 12.8601C28.2238 13.0835 28.2698 13.3229 28.2677 13.5644C28.2656 13.8058 28.2155 14.0444 28.1202 14.2662C28.0249 14.488 27.8864 14.6886 27.7127 14.8564L22.5709 20L27.7127 25.1436Z"
                            fill="#0a1d56"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        isActiveRoute("/");
                        setservicePage(false);
                        setToggleMobileMenu(false);
                      }}
                      className={`${isActiveRoute("/") ? "active" : ""}`}
                    >
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      onClick={() => {
                        setToggleMobileMenu(false);
                        isActiveRoute("/about");
                        setservicePage(false);
                      }}
                      className={`${isActiveRoute("/about") ? "active" : ""}`}
                    >
                      <Link to="/about">About</Link>
                    </li>
                    <li
                      className={`${servicePage ? "active" : ""} servicemenu`}
                    >
                      <Link>Service</Link>
                      <div className="serviceInnerPages">
                        <ul>
                          <li
                            onClick={() =>
                              ServiceActiveRouteCheck("/application")
                            }
                          >
                            <Link
                              to="/application"
                              className={`${
                                pathName.includes("/application")
                                  ? "serviceActive"
                                  : ""
                              } `}
                            >
                              Application development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/web"
                              className={`${
                                pathName.includes("/web") ? "serviceActive" : ""
                              } `}
                              onClick={() => ServiceActiveRouteCheck("/web")}
                            >
                              Website development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/mobileApp"
                              className={`${
                                pathName.includes("/mobileApp")
                                  ? "serviceActive"
                                  : ""
                              } `}
                              onClick={() =>
                                ServiceActiveRouteCheck("/mobileApp")
                              }
                            >
                              Mobile app
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/uiUx"
                              className={`${
                                pathName.includes("/uiUx")
                                  ? "serviceActive"
                                  : ""
                              } `}
                              onClick={() => ServiceActiveRouteCheck("/uiUx")}
                            >
                              UI/UX
                            </Link>
                          </li>
                          <li
                            onClick={() =>
                              ServiceActiveRouteCheck("/cloudService")
                            }
                          >
                            <Link
                              to="/cloudService"
                              className={`${
                                pathName.includes("/cloudService")
                                  ? "serviceActive"
                                  : ""
                              } `}
                            >
                              Cloud service
                            </Link>
                          </li>
                          <li
                            onClick={() =>
                              ServiceActiveRouteCheck("/seoService")
                            }
                          >
                            <Link
                              to="/seoService"
                              className={`${
                                pathName.includes("/seoService")
                                  ? "serviceActive"
                                  : ""
                              } `}
                            >
                              SEO service
                            </Link>
                          </li>
                          <li
                            onClick={() =>
                              ServiceActiveRouteCheck("/socialMedia")
                            }
                          >
                            <Link
                              to="/socialMedia"
                              className={`${
                                pathName.includes("/socialMedia")
                                  ? "serviceActive"
                                  : ""
                              } `}
                            >
                              Social media marketing
                            </Link>
                          </li>
                          <li
                            onClick={() => ServiceActiveRouteCheck("/branding")}
                          >
                            <Link
                              to="/branding"
                              className={`${
                                pathName.includes("/branding")
                                  ? "serviceActive"
                                  : ""
                              } `}
                            >
                              Branding
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        isActiveRoute("/blog");
                        setToggleMobileMenu(false);
                        setservicePage(false);
                      }}
                      className={`${isActiveRoute("/blog") ? "active" : ""}`}
                    >
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li
                      onClick={() => {
                        isActiveRoute("/contact");
                        setToggleMobileMenu(false);
                        setservicePage(false);
                      }}
                      className={`${isActiveRoute("/contact") ? "active" : ""}`}
                    >
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                  <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                    />
                  </Form>
                </div>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
