import React from "react";

import { Col, Container, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import EcommerceImage from "../../Assets/Images/HomeImages/EcommerceImage.png";
import RealEstate from "../../Assets/Images/HomeImages/RealEstate.png";
import Fintech from "../../Assets/Images/HomeImages/Fintech.png";
import Travel from "../../Assets/Images/HomeImages/Travel.png";
import Hospitality from "../../Assets/Images/HomeImages/Hospitality.png";
import Education from "../../Assets/Images/HomeImages/Education.png";

const Industry = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Industry Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="industry">
        <div className="industry-wrapper">
          <Container>
            <Row>
              <Col>
                <p className="secondary">What we cater</p>
                <h3>Industries We Cater To</h3>
              </Col>
              <Col className="d-flex justify-content-end mt-3 mt-md-0">
                <Link to="/service" className="secondary-button">
                  Explore
                  <br />
                  Now
                </Link>
              </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="industry-tab">
                <Col md={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={EcommerceImage} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>E-Commerce</h5>
                          <p className="secondary">
                            The e-commerce industry has undergone a remarkable
                            transformation in recent years, evolving into a
                            dynamic and highly competitive market. In this
                            digital landscape, the role of software development
                            services is pivotal, enabling e-commerce businesses
                            to thrive and adapt to ever-changing customer
                            expectations.
                          </p>

                          {/* <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link> */}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={RealEstate} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Real Estate</h5>
                          <p className="secondary">
                            The real estate industry is a highly profitable
                            domain, and to stay competitive, real estate
                            businesses are increasingly turning to software
                            development services. These solutions offer tailored
                            solutions that streamline operations, enhance
                            customer experiences, and drive growth.
                          </p>
                          {/* 
                          <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link> */}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={Fintech} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Fintech</h5>
                          <p className="secondary">
                            The fintech industry is a dynamic where technology
                            and finance converge to reshape the way we manage
                            and interact with our money. As the demand for
                            innovative financial solutions continues to surge,
                            the role of software development services in
                            empowering the fintech sector cannot be overstated.
                          </p>

                          {/* <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link> */}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="forth">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={Hospitality} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Hospitality</h5>
                          <p className="secondary">
                            In the modern digital landscape, the hospitality
                            industry is increasingly reliant on cutting-edge
                            technology to streamline operations, enhance guest
                            experiences, and stay competitive.
                          </p>

                          {/* <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link> */}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={Travel} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Travel</h5>
                          <p className="secondary">
                            In an era marked by digital transformation, the
                            travel industry has not remained untouched. Travel
                            agencies, tour operators, and companies in the
                            hospitality sector are increasingly turning to
                            software development services to enhance their
                            operations, streamline processes, and improve the
                            overall customer experience.
                          </p>

                          {/* <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link> */}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <div className="d-sm-flex">
                        <div className="industry-image">
                          <img src={Education} alt="" />
                        </div>
                        <div className="industry-description">
                          <h5>Education</h5>
                          <p className="secondary">
                            The educational industry has undergone a significant
                            transformation in recent years, with technology
                            playing a pivotal role in reshaping the way students
                            learn and educators teach.
                          </p>

                          {/* <Link>
                            Learn More
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="10"
                                viewBox="0 0 17 10"
                                fill="none"
                              >
                                <path
                                  d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </Link> */}
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
                <Col md={3}>
                  <Nav variant="pills" className="flex-md-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <span>01</span>
                        Ecommerce
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <span>02</span>
                        Real Estate
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <span>03</span>
                        Fintech
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="forth">
                        <span>04</span>
                        Hospitality
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">
                        <span>05</span>
                        Travel
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sixth">
                        <span>06</span>
                        Education
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Industry;
