import React from "react";
import { Col, Container, InputGroup, Row } from "react-bootstrap";
import "../../Style/Modules/Footer.scss";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const Footer = () => {
  return (
    <>
      <footer>
        <Container>
          <Row className="justify-content-center">
            <Col md={9}>
              <div className="footer">
                <Link className="footer-logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="177"
                    height="156"
                    viewBox="0 0 177 156"
                    fill="none"
                  >
                    <path
                      d="M124.679 85.4349C124.452 84.4796 124.128 83.5405 123.707 82.6499C124.064 83.4109 124.355 84.2043 124.582 85.0463L124.679 85.4349Z"
                      fill="#011138"
                    />
                    <path
                      d="M99.0306 81.8398L80.6851 109.48L88.2144 138.561C89.283 142.722 88.3601 146.916 86.0284 150.154C86.2227 149.911 86.4332 149.668 86.6113 149.393L105.346 121.186L97.7353 91.9761C96.8123 88.4138 97.3791 84.8192 99.0144 81.8398H99.0306Z"
                      fill="#B4B4B4"
                    />
                    <path
                      d="M83.924 143.111C83.924 143.111 83.8107 143.257 83.7621 143.321C83.8269 143.257 83.8754 143.176 83.924 143.111Z"
                      fill="#011138"
                    />
                    <path
                      d="M125.343 155.513C126.38 155.481 127.416 155.351 128.452 155.092C132.727 153.991 136.014 150.995 137.665 147.239L136.5 149.085C133.974 153.084 129.731 155.351 125.343 155.529V155.513Z"
                      fill="#0A1D56"
                    />
                    <path
                      d="M119.158 77.2561C121.198 78.681 122.72 80.5593 123.708 82.6481C120.939 76.7056 114.268 73.3862 107.694 75.0864C103.84 76.0741 100.812 78.6001 99.0311 81.8385L99.8569 80.6565C104.245 74.3901 112.892 72.8681 119.158 77.2561Z"
                      fill="#0A1D56"
                    />
                    <path
                      d="M128.807 101.757L124.662 85.4351L124.565 85.0465C124.355 84.2045 124.047 83.4111 123.691 82.6501C122.703 80.5613 121.165 78.683 119.141 77.2581C112.874 72.8701 104.228 74.3921 99.8398 80.6585L99.0141 81.8405C97.3625 84.836 96.8119 88.4144 97.7349 91.9767L105.345 121.187L109.523 137.638C109.247 134.691 109.895 131.647 111.595 128.959L128.824 101.757H128.807Z"
                      fill="white"
                    />
                    <path
                      d="M84.07 142.932C84.07 142.932 83.9728 143.061 83.9243 143.11C83.9728 143.045 84.0214 142.996 84.07 142.932Z"
                      fill="#0A1D56"
                    />
                    <path
                      d="M24.6281 21.9771H13.3908C5.99105 21.9771 0 27.9681 0 35.3679C0 42.7676 5.99105 48.7587 13.3908 48.7587H31.5583L24.6281 21.9771Z"
                      fill="white"
                    />
                    <path
                      d="M90.7892 22.9981C89.2023 22.3342 87.4698 21.9618 85.6401 21.9618H58.5994L55.6039 10.3845C53.677 2.98473 46.1153 -1.46808 38.7156 0.442584C31.2996 2.35324 26.8468 9.91492 28.7737 17.3309L29.9719 21.9618L36.6592 47.8205L36.9021 48.7434L61.3844 145.491C63.2951 152.907 70.8568 157.36 78.2727 155.433C81.3492 154.639 83.9075 152.858 85.721 150.527C85.7696 150.462 85.8344 150.397 85.883 150.316C85.9315 150.251 85.9801 150.203 86.0287 150.138C88.3603 146.916 89.2833 142.706 88.2146 138.544L79.228 101.659L65.5458 48.7434H85.6563C93.056 48.7434 99.0471 42.7524 99.0471 35.3526C99.0471 29.7825 95.6468 25.0059 90.8053 22.9981H90.7892Z"
                      fill="white"
                    />
                    <path
                      d="M111.58 145.134L111.013 142.948C111.401 147.077 113.62 150.995 117.392 153.375C119.53 154.719 121.894 155.399 124.242 155.497C118.364 155.173 113.118 151.125 111.58 145.134Z"
                      fill="#00D392"
                    />
                    <path
                      d="M174.34 81.7272C174.178 81.63 174.016 81.5653 173.854 81.4843C172.348 80.8366 170.616 81.1928 169.499 82.3587C169.45 82.4396 169.385 82.5044 169.337 82.5692L168.268 84.3179C168.268 84.3179 168.268 84.3017 168.268 84.2855L165.872 88.2364L159.686 98.324C159.638 98.4374 159.605 98.5507 159.557 98.6641L156.917 103.02C155.266 105.756 151.687 106.631 148.951 104.979C146.214 103.311 145.34 99.7489 146.992 97.0125L149.291 93.2235L151.687 89.4022C151.849 89.1593 151.963 88.9003 152.076 88.625C152.756 86.7953 152.076 84.6579 150.376 83.5893C148.66 82.5206 146.474 82.8282 145.13 84.2046C144.919 84.4151 144.741 84.6418 144.563 84.9008L134.427 101.012C134.362 101.109 134.33 101.222 134.265 101.32L130.298 107.57L113.069 134.772C111.369 137.46 110.722 140.52 110.997 143.451L111.564 145.637C113.118 151.628 118.364 155.692 124.226 156C124.355 156 124.485 156 124.614 156C124.857 156 125.084 156 125.327 156C129.731 155.822 133.957 153.555 136.483 149.556L137.649 147.71L168.349 99.1984C168.738 98.5831 169.061 97.9678 169.337 97.3201L173.45 90.5842L175.133 87.7182L175.959 86.3257C176.575 84.6256 175.927 82.6663 174.324 81.6786L174.34 81.7272Z"
                      fill="#00D392"
                    />
                    <path
                      d="M152.513 97.8046C154.084 98.7437 156.14 98.2418 157.079 96.6549L160.609 90.7448C161.548 89.1742 161.046 87.1178 159.459 86.1787C157.889 85.2396 155.832 85.7415 154.893 87.3283L151.363 93.2384C150.424 94.809 150.926 96.8654 152.513 97.8046Z"
                      fill="#00D392"
                    />
                    <path
                      d="M169.284 63.3066L160.442 78.0742C159.496 79.6548 160.007 81.7013 161.584 82.6453C163.161 83.5893 165.206 83.0733 166.152 81.4928L174.994 66.7252C175.94 65.1447 175.429 63.0981 173.852 62.1541C172.276 61.2101 170.23 61.7261 169.284 63.3066Z"
                      fill="#00D392"
                    />
                  </svg>
                </Link>
                <ul className="footer-list">
                  <li>
                    <ul>
                      <li>Contact</li>
                      <li>
                        <Link></Link>
                        <a href="tel:(033) 4804-7123">(033) 4804-7123</a>
                      </li>
                      <li>
                        <a href="mailto:someone@example.com">
                          support@techwens.com
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>Important Links</li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/career">Career</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <ul>
                      <li>Discover</li>
                      <li>
                        <Link>FAQ</Link>
                      </li>
                      <li>
                        <Link>New</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <ul>
                      <li>Support</li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/contact">schedule a meeting</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="footer-social">
                  <li>
                    <Link
                      to="https://www.facebook.com/Techwens"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21015)">
                          <path
                            d="M32 16C32 7.1625 24.8375 0 16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C16.0938 32 16.1875 32 16.2812 31.9937V19.5438H12.8438V15.5375H16.2812V12.5875C16.2812 9.16875 18.3688 7.30625 21.4188 7.30625C22.8813 7.30625 24.1375 7.4125 24.5 7.4625V11.0375H22.4C20.7437 11.0375 20.4188 11.825 20.4188 12.9812V15.5312H24.3875L23.8687 19.5375H20.4188V31.3813C27.1063 29.4625 32 23.3062 32 16Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21015">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/techwensdigital/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21017)">
                          <path
                            d="M19.0625 16C19.0625 17.6914 17.6914 19.0625 16 19.0625C14.3086 19.0625 12.9375 17.6914 12.9375 16C12.9375 14.3086 14.3086 12.9375 16 12.9375C17.6914 12.9375 19.0625 14.3086 19.0625 16Z"
                            fill="white"
                          />
                          <path
                            d="M23.1621 10.5823C23.0149 10.1833 22.78 9.82227 22.4749 9.52588C22.1785 9.2207 21.8176 8.98584 21.4185 8.83862C21.0947 8.71289 20.6084 8.56323 19.7126 8.52246C18.7437 8.47827 18.4531 8.46875 16 8.46875C13.5466 8.46875 13.2561 8.47803 12.2874 8.52222C11.3916 8.56323 10.905 8.71289 10.5815 8.83862C10.1824 8.98584 9.82129 9.2207 9.52515 9.52588C9.21997 9.82227 8.98511 10.1831 8.83765 10.5823C8.71191 10.906 8.56226 11.3926 8.52148 12.2883C8.47729 13.2571 8.46777 13.5476 8.46777 16.001C8.46777 18.4541 8.47729 18.7446 8.52148 19.7136C8.56226 20.6094 8.71191 21.0957 8.83765 21.4194C8.98511 21.8186 9.21973 22.1794 9.5249 22.4758C9.82129 22.781 10.1821 23.0159 10.5813 23.1631C10.905 23.2891 11.3916 23.4387 12.2874 23.4795C13.2561 23.5237 13.5464 23.533 15.9998 23.533C18.4534 23.533 18.7439 23.5237 19.7124 23.4795C20.6082 23.4387 21.0947 23.2891 21.4185 23.1631C22.2197 22.854 22.853 22.2207 23.1621 21.4194C23.2878 21.0957 23.4375 20.6094 23.4785 19.7136C23.5227 18.7446 23.532 18.4541 23.532 16.001C23.532 13.5476 23.5227 13.2571 23.4785 12.2883C23.4377 11.3926 23.2881 10.906 23.1621 10.5823ZM16 20.7185C13.3943 20.7185 11.282 18.6064 11.282 16.0007C11.282 13.395 13.3943 11.283 16 11.283C18.6055 11.283 20.7178 13.395 20.7178 16.0007C20.7178 18.6064 18.6055 20.7185 16 20.7185ZM20.9043 12.199C20.2954 12.199 19.8018 11.7053 19.8018 11.0964C19.8018 10.4875 20.2954 9.9939 20.9043 9.9939C21.5132 9.9939 22.0068 10.4875 22.0068 11.0964C22.0066 11.7053 21.5132 12.199 20.9043 12.199Z"
                            fill="white"
                          />
                          <path
                            d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM25.1321 19.7878C25.0876 20.7659 24.9321 21.4336 24.7051 22.0181C24.2278 23.2522 23.2522 24.2278 22.0181 24.7051C21.4338 24.9321 20.7659 25.0874 19.7881 25.1321C18.8083 25.1768 18.4954 25.1875 16.0002 25.1875C13.5049 25.1875 13.1921 25.1768 12.2122 25.1321C11.2344 25.0874 10.5664 24.9321 9.98218 24.7051C9.3689 24.4744 8.81372 24.1128 8.35474 23.6453C7.88745 23.1865 7.52588 22.6311 7.29517 22.0181C7.06812 21.4338 6.9126 20.7659 6.86816 19.7881C6.823 18.8081 6.8125 18.4951 6.8125 16C6.8125 13.5049 6.823 13.1919 6.86792 12.2122C6.91235 11.2341 7.06763 10.5664 7.29468 9.98193C7.52539 9.3689 7.88721 8.81348 8.35474 8.35474C8.81348 7.88721 9.3689 7.52563 9.98193 7.29492C10.5664 7.06787 11.2341 6.9126 12.2122 6.86792C13.1919 6.82324 13.5049 6.8125 16 6.8125C18.4951 6.8125 18.8081 6.82324 19.7878 6.86816C20.7659 6.9126 21.4336 7.06787 22.0181 7.29468C22.6311 7.52539 23.1865 7.88721 23.6455 8.35474C24.1128 8.81372 24.4746 9.3689 24.7051 9.98193C24.9324 10.5664 25.0876 11.2341 25.1323 12.2122C25.177 13.1919 25.1875 13.5049 25.1875 16C25.1875 18.4951 25.177 18.8081 25.1321 19.7878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21017">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21021)">
                          <path
                            d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM23.3054 12.4751C23.3125 12.6326 23.3159 12.7908 23.3159 12.9497C23.3159 17.8025 19.6221 23.3984 12.8669 23.3987H12.8672H12.8669C10.793 23.3987 8.86304 22.7908 7.23779 21.749C7.52515 21.783 7.81763 21.7998 8.11377 21.7998C9.83447 21.7998 11.418 21.2129 12.675 20.2278C11.0674 20.198 9.71191 19.1362 9.24414 17.677C9.46802 17.72 9.69824 17.7434 9.93433 17.7434C10.2695 17.7434 10.5942 17.6982 10.9028 17.614C9.22241 17.2776 7.95654 15.7925 7.95654 14.0142C7.95654 13.9976 7.95654 13.9827 7.95703 13.9673C8.4519 14.2424 9.01782 14.408 9.62036 14.4265C8.63428 13.7686 7.98608 12.6438 7.98608 11.3696C7.98608 10.6968 8.16797 10.0664 8.4834 9.52368C10.2944 11.7458 13.001 13.2073 16.0532 13.3608C15.9902 13.0918 15.9578 12.8115 15.9578 12.5234C15.9578 10.4961 17.6025 8.85132 19.6306 8.85132C20.687 8.85132 21.6411 9.29785 22.3113 10.0117C23.1479 9.84668 23.9336 9.54102 24.6433 9.12036C24.3687 9.97754 23.7866 10.6968 23.0283 11.1516C23.7712 11.0627 24.4792 10.8657 25.1372 10.5732C24.6458 11.3098 24.0225 11.9568 23.3054 12.4751Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21021">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/techwens/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21023)">
                          <path
                            d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM11.3506 24.1875H7.45386V12.4641H11.3506V24.1875ZM9.40234 10.8633H9.37695C8.06934 10.8633 7.22363 9.96313 7.22363 8.83813C7.22363 7.68774 8.09521 6.8125 9.42822 6.8125C10.7612 6.8125 11.5815 7.68774 11.6069 8.83813C11.6069 9.96313 10.7612 10.8633 9.40234 10.8633ZM25.4014 24.1875H21.5051V17.9158C21.5051 16.3396 20.9409 15.2646 19.531 15.2646C18.4546 15.2646 17.8135 15.9897 17.5317 16.6897C17.4287 16.9402 17.4036 17.2903 17.4036 17.6406V24.1875H13.5071C13.5071 24.1875 13.5581 13.564 13.5071 12.4641H17.4036V14.124C17.9214 13.3252 18.8479 12.189 20.9153 12.189C23.479 12.189 25.4014 13.8645 25.4014 17.4653V24.1875Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21023">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="d-lg-flex align-items-center">
                <div>
                  <p className="text-white mb-2">NEWSLETTER</p>
                  <InputGroup className="footer-input">
                    <Form.Control
                      placeholder="Enter your email"
                      aria-label="Enter your email"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <mask
                          id="mask0_280_20993"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M23.3333 0H0V23.3333H23.3333V0Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_280_20993)">
                          <mask
                            id="mask1_280_20993"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <path
                              d="M0.000244141 0.000185013H23.3336V23.3335H0.000244141V0.000185013Z"
                              fill="white"
                            />
                          </mask>
                          <g mask="url(#mask1_280_20993)">
                            <path
                              d="M1.47953 7.39379C0.455463 7.73513 0.414539 8.39511 1.38866 8.86018L8.4731 12.2437C9.44713 12.7089 10.6249 13.8866 11.0902 14.8607L14.4735 21.9452C14.9388 22.9193 15.5987 22.8784 15.9401 21.8544L22.5498 2.02535C22.8911 1.00128 22.3325 0.442692 21.3084 0.784215L1.47953 7.39379Z"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.97241 13.3613L22.4412 0.892499"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.94834 11.5154L4.47861 17.6139C4.13723 18.638 4.69577 19.1966 5.71993 18.8553L11.8186 16.3855"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                      </svg>
                    </InputGroup.Text>
                  </InputGroup>
                  <p className="primary mt-2">
                    Subscribe to our newsletter right away
                  </p>
                </div>
                <ul className="footer-social-mob">
                  <li>
                    <Link
                      to="https://www.facebook.com/Techwens"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21015)">
                          <path
                            d="M32 16C32 7.1625 24.8375 0 16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C16.0938 32 16.1875 32 16.2812 31.9937V19.5438H12.8438V15.5375H16.2812V12.5875C16.2812 9.16875 18.3688 7.30625 21.4188 7.30625C22.8813 7.30625 24.1375 7.4125 24.5 7.4625V11.0375H22.4C20.7437 11.0375 20.4188 11.825 20.4188 12.9812V15.5312H24.3875L23.8687 19.5375H20.4188V31.3813C27.1063 29.4625 32 23.3062 32 16Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21015">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/techwensdigital/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21017)">
                          <path
                            d="M19.0625 16C19.0625 17.6914 17.6914 19.0625 16 19.0625C14.3086 19.0625 12.9375 17.6914 12.9375 16C12.9375 14.3086 14.3086 12.9375 16 12.9375C17.6914 12.9375 19.0625 14.3086 19.0625 16Z"
                            fill="white"
                          />
                          <path
                            d="M23.1621 10.5823C23.0149 10.1833 22.78 9.82227 22.4749 9.52588C22.1785 9.2207 21.8176 8.98584 21.4185 8.83862C21.0947 8.71289 20.6084 8.56323 19.7126 8.52246C18.7437 8.47827 18.4531 8.46875 16 8.46875C13.5466 8.46875 13.2561 8.47803 12.2874 8.52222C11.3916 8.56323 10.905 8.71289 10.5815 8.83862C10.1824 8.98584 9.82129 9.2207 9.52515 9.52588C9.21997 9.82227 8.98511 10.1831 8.83765 10.5823C8.71191 10.906 8.56226 11.3926 8.52148 12.2883C8.47729 13.2571 8.46777 13.5476 8.46777 16.001C8.46777 18.4541 8.47729 18.7446 8.52148 19.7136C8.56226 20.6094 8.71191 21.0957 8.83765 21.4194C8.98511 21.8186 9.21973 22.1794 9.5249 22.4758C9.82129 22.781 10.1821 23.0159 10.5813 23.1631C10.905 23.2891 11.3916 23.4387 12.2874 23.4795C13.2561 23.5237 13.5464 23.533 15.9998 23.533C18.4534 23.533 18.7439 23.5237 19.7124 23.4795C20.6082 23.4387 21.0947 23.2891 21.4185 23.1631C22.2197 22.854 22.853 22.2207 23.1621 21.4194C23.2878 21.0957 23.4375 20.6094 23.4785 19.7136C23.5227 18.7446 23.532 18.4541 23.532 16.001C23.532 13.5476 23.5227 13.2571 23.4785 12.2883C23.4377 11.3926 23.2881 10.906 23.1621 10.5823ZM16 20.7185C13.3943 20.7185 11.282 18.6064 11.282 16.0007C11.282 13.395 13.3943 11.283 16 11.283C18.6055 11.283 20.7178 13.395 20.7178 16.0007C20.7178 18.6064 18.6055 20.7185 16 20.7185ZM20.9043 12.199C20.2954 12.199 19.8018 11.7053 19.8018 11.0964C19.8018 10.4875 20.2954 9.9939 20.9043 9.9939C21.5132 9.9939 22.0068 10.4875 22.0068 11.0964C22.0066 11.7053 21.5132 12.199 20.9043 12.199Z"
                            fill="white"
                          />
                          <path
                            d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM25.1321 19.7878C25.0876 20.7659 24.9321 21.4336 24.7051 22.0181C24.2278 23.2522 23.2522 24.2278 22.0181 24.7051C21.4338 24.9321 20.7659 25.0874 19.7881 25.1321C18.8083 25.1768 18.4954 25.1875 16.0002 25.1875C13.5049 25.1875 13.1921 25.1768 12.2122 25.1321C11.2344 25.0874 10.5664 24.9321 9.98218 24.7051C9.3689 24.4744 8.81372 24.1128 8.35474 23.6453C7.88745 23.1865 7.52588 22.6311 7.29517 22.0181C7.06812 21.4338 6.9126 20.7659 6.86816 19.7881C6.823 18.8081 6.8125 18.4951 6.8125 16C6.8125 13.5049 6.823 13.1919 6.86792 12.2122C6.91235 11.2341 7.06763 10.5664 7.29468 9.98193C7.52539 9.3689 7.88721 8.81348 8.35474 8.35474C8.81348 7.88721 9.3689 7.52563 9.98193 7.29492C10.5664 7.06787 11.2341 6.9126 12.2122 6.86792C13.1919 6.82324 13.5049 6.8125 16 6.8125C18.4951 6.8125 18.8081 6.82324 19.7878 6.86816C20.7659 6.9126 21.4336 7.06787 22.0181 7.29468C22.6311 7.52539 23.1865 7.88721 23.6455 8.35474C24.1128 8.81372 24.4746 9.3689 24.7051 9.98193C24.9324 10.5664 25.0876 11.2341 25.1323 12.2122C25.177 13.1919 25.1875 13.5049 25.1875 16C25.1875 18.4951 25.177 18.8081 25.1321 19.7878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21017">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21021)">
                          <path
                            d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM23.3054 12.4751C23.3125 12.6326 23.3159 12.7908 23.3159 12.9497C23.3159 17.8025 19.6221 23.3984 12.8669 23.3987H12.8672H12.8669C10.793 23.3987 8.86304 22.7908 7.23779 21.749C7.52515 21.783 7.81763 21.7998 8.11377 21.7998C9.83447 21.7998 11.418 21.2129 12.675 20.2278C11.0674 20.198 9.71191 19.1362 9.24414 17.677C9.46802 17.72 9.69824 17.7434 9.93433 17.7434C10.2695 17.7434 10.5942 17.6982 10.9028 17.614C9.22241 17.2776 7.95654 15.7925 7.95654 14.0142C7.95654 13.9976 7.95654 13.9827 7.95703 13.9673C8.4519 14.2424 9.01782 14.408 9.62036 14.4265C8.63428 13.7686 7.98608 12.6438 7.98608 11.3696C7.98608 10.6968 8.16797 10.0664 8.4834 9.52368C10.2944 11.7458 13.001 13.2073 16.0532 13.3608C15.9902 13.0918 15.9578 12.8115 15.9578 12.5234C15.9578 10.4961 17.6025 8.85132 19.6306 8.85132C20.687 8.85132 21.6411 9.29785 22.3113 10.0117C23.1479 9.84668 23.9336 9.54102 24.6433 9.12036C24.3687 9.97754 23.7866 10.6968 23.0283 11.1516C23.7712 11.0627 24.4792 10.8657 25.1372 10.5732C24.6458 11.3098 24.0225 11.9568 23.3054 12.4751Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21021">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/techwens/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_280_21023)">
                          <path
                            d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM11.3506 24.1875H7.45386V12.4641H11.3506V24.1875ZM9.40234 10.8633H9.37695C8.06934 10.8633 7.22363 9.96313 7.22363 8.83813C7.22363 7.68774 8.09521 6.8125 9.42822 6.8125C10.7612 6.8125 11.5815 7.68774 11.6069 8.83813C11.6069 9.96313 10.7612 10.8633 9.40234 10.8633ZM25.4014 24.1875H21.5051V17.9158C21.5051 16.3396 20.9409 15.2646 19.531 15.2646C18.4546 15.2646 17.8135 15.9897 17.5317 16.6897C17.4287 16.9402 17.4036 17.2903 17.4036 17.6406V24.1875H13.5071C13.5071 24.1875 13.5581 13.564 13.5071 12.4641H17.4036V14.124C17.9214 13.3252 18.8479 12.189 20.9153 12.189C23.479 12.189 25.4014 13.8645 25.4014 17.4653V24.1875Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_21023">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                </ul>
                <p className="footer-copyright">
                  <span>
                    <Link>©Techwens 2022</Link>
                  </span>
                  <span>
                    <Link to="https://techwens.com/privacy-policy/">
                      Privacy policy
                    </Link>
                  </span>
                  <span>
                    <Link>Cookies</Link>
                  </span>
                  <span>
                    <Link>Terms of service</Link>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
