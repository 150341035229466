import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../Style/Modules/Common.scss";
import "../../Style/Modules/Service.scss";
import { Link, useNavigate } from "react-router-dom";
import BlogBanner from "../../Assets/Images/HomeImages/BlogBanner.png";
import BlogImg from "../../Assets/Images/HomeImages/demandService1.png";
import axios from "axios";
import moment from "moment";
import api from "../../Api/api";

const BlogAll = () => {
  const [blogImage, setBlogImage] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const navigate = useNavigate();
  const scrollToRef = useRef();

  useEffect(() => {
    // Scroll to the target element
    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const getImage = async (link, func) => {
    const res = await axios
      .get(link)
      .then((response) => {
        return response?.data[0]?.guid?.rendered;
      })
      .catch((error) => {
        console.log("error", error);
      });

    return res;
  };

  const getBlogImage = async (post) => {
    // console.log('post', post?._links?.['wp:attachment'][0].href)
    const image = await getImage(post?._links?.["wp:attachment"][0].href);
 
    return image;
  };

  // useEffect(() => {
  //   setBlogList([]);
  //   axios
  //     .get("https://techwens.com/wp-json/wp/v2/posts")
  //     .then(async (response) => {
  //       let resData = await response?.data;
  //       response?.data?.map(async (post, i) => {
  //         const blogImage = await getBlogImage(post);
  //         resData = await resData?.map((it) =>
  //           it?.id == post.id ? { ...it, image: blogImage } : it
  //         );
  //         setBlogList(resData);
  //       });
  //     })
  //     .catch((err) => console.log("err", err));
  // }, []);
  

  useEffect(() => {
    api.getData()
    .then(async (response) => {
      let resData = await response?.data;
      console.log('resData', resData)
      response?.data?.map(async (post, i) => {
        const blogImage = await getBlogImage(post);
        resData = await resData?.map((it) =>
          it?.id == post.id ? { ...it, image: blogImage } : it
        );
        setBlogList(resData);
      });
    })
    .catch((error) => {
        console.log(error)
    })
}, [])


  const TimeFormat = (date) => {
    const parsedDate = moment(date);

    // Calculate the time difference
    const timeDifference = moment().diff(parsedDate);

    // Get the duration
    const duration = moment.duration(timeDifference);

    // Get days, hours, minutes, and seconds
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const onBlogClick = async (post,slug) => {
    navigate(`/blog/${slug}`, {
      state: {
        post: post,
      },
    });
  };

  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Banner Section~~~~~~~~~~~~~~~~~~~~~~~*/}

      <section className="globalBanner" ref={scrollToRef}>
        <Container>
          <Row>
            <Col md={10}>
              <div className="globalBanner-text">
                <p>check out our all </p>
                <h1 className="gradient">Our Blogs</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <div className="globalBanner-text">
                <h5>Exploring Innovation, One Blog at a Time</h5>
                <p className="secondary">
                  With the help of blogs, you can remain abreast in your field
                  and get to know what your business needs. Hence, feel free to
                  check out this section as and when you can.
                </p>
              </div>
            </Col>
            <Col md={7}>
              <div className="globalBanner-img">
                <img src={BlogBanner} alt="" />
              </div>
            </Col>
          </Row>
          <Link className="phNumber">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M31.8617 19.6763C32.2677 12.5406 26.8122 6.42678 19.6765 6.02077C12.5408 5.61475 6.42698 11.0702 6.02097 18.2059C5.61495 25.3416 11.0704 31.4554 18.2061 31.8615C21.6331 32.0565 24.9972 30.882 27.5581 28.5965C30.1187 26.3114 31.6667 23.1027 31.8617 19.6763ZM19.5781 27.2949C19.578 27.2949 19.5779 27.2949 19.5778 27.2949C19.577 27.2949 19.5766 27.2939 19.5772 27.2934C19.5779 27.2928 19.5775 27.2918 19.5767 27.2918L18.6563 27.2362C17.451 27.175 16.3652 26.4886 15.793 25.426C15.2419 24.3328 14.8695 23.1584 14.6899 21.9474C14.5105 20.8335 14.454 19.7032 14.5217 18.577C14.5766 17.5473 14.7343 16.5256 14.992 15.5272C15.2627 14.4453 15.6818 13.4061 16.2376 12.4391C16.8971 11.3107 18.1376 10.6517 19.4419 10.7369L20.526 10.7987C20.8281 10.8153 21.0596 11.0737 21.043 11.3758C21.043 11.3765 21.043 11.3774 21.0429 11.3782L20.8476 14.8011C20.831 15.1032 20.5727 15.3347 20.2706 15.3181C20.2698 15.3181 20.269 15.318 20.2681 15.318L18.2582 15.2033C17.6848 15.1674 17.1716 15.5565 17.0512 16.1183C16.8666 16.9728 16.7497 17.8406 16.7014 18.7136C16.6436 19.6905 16.6725 20.6706 16.7879 21.6424C16.8552 22.2034 17.314 22.6359 17.878 22.67L19.837 22.7665C20.1391 22.7831 20.3706 23.0414 20.354 23.3435C20.354 23.3443 20.3539 23.3451 20.3539 23.346L20.155 26.7779C20.1386 27.08 19.8802 27.3115 19.5781 27.2949Z"
                  fill="black"
                />
              </svg>
            </span>
            (033) 4804-7123
          </Link>
        </Container>
        <div className="innerLine">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>

      <section className="latestBlog">
        <Container>
          <h2>Latest Blogs</h2>
          <Row>
            <Col md={8}>
              <h3 className="gradient">
                From Our Trending Blogs and Resources
              </h3>
            </Col>
            <Col md={4}>
              <p className="primary">
                Stay updated on the latest technological trends by following our
                blog page on a daily basis.
              </p>
            </Col>
          </Row>
          <Row className="mt-3 mt-lg-5">
            {blogList?.map((post, index) => (
              <Col sm={6} md={4} key={index} className="latestBlog-box">
                <p className="latestBlog-title">{`${post?.title?.rendered}`}</p>
                <div className="latestBlog-img">
                  <img
                    src={post?.image}
                    alt=""
                    onClick={() => onBlogClick(post,post.slug)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogAll;
