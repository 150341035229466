import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const GrowthStage = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Stage Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="stage">
        <div className="stage-progress">
          <Container>
            <ul>
              <li>
                <div className="wrapper">
                  <div className="wrapper-child">
                    <span>800</span>
                    <p className="primary">
                      Project
                      <br />
                      Completed
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="wrapper">
                  <div className="wrapper-child">
                    {" "}
                    <span>2k</span>
                    <p className="primary">
                      Happy
                      <br />
                      Customers
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="wrapper">
                  <div className="wrapper-child">
                    {" "}
                    <span>14</span>
                    <p className="primary">
                      Years
                      <br />
                      Experience
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="wrapper">
                  <div className="wrapper-child">
                    {" "}
                    <span>6+</span>
                    <p className="primary">
                      Awards
                      <br />
                      Achievement
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <Row className="justify-content-between">
              <Col
                md={5}
                xxl={3}
                className="d-flex flex-column justify-content-end"
              >
                <p className="primary">Free consultation for you</p>
                <p className="stage-head">Know the Stages of Our Work</p>
              </Col>
              <Col
                md={4}
                xxl={3}
                className="d-flex justify-content-start align-items-end mt-3 mt-md-0"
              >
                <Link to="/service" className="primary-button">
                  View
                  <br />
                  all Services
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <ul className="stage-growth">
            <li>
              <p className="stage-name">Requirements Gathering</p>
              <div className="stage-description">
                <p className="primary">
                  This initial stage involves understanding the needs and
                  objectives of the software project. Development teams work
                  closely with stakeholders to gather and document requirements.
                </p>
                <Link>
                  Know More
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="10"
                      viewBox="0 0 17 10"
                      fill="none"
                    >
                      <path
                        d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </li>
            <li>
              <p className="stage-name">Analysis and Planning</p>
              <div className="stage-description">
                <p className="primary">
                  After gathering requirements, the development team analyzes
                  the data and defines the project's scope. They create a
                  project plan, establish timelines, allocate resources, and
                  define the overall architecture of the software.
                </p>
                <Link>
                  Know More
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="10"
                      viewBox="0 0 17 10"
                      fill="none"
                    >
                      <path
                        d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </li>
            <li>
              <p className="stage-name">Design Stage</p>
              <div className="stage-description">
                <p className="primary">
                  In this stage, the software's architectural and user interface
                  design is created. This includes defining the system's
                  structure, components, and the user experience.
                </p>
                <Link>
                  Know More
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="10"
                      viewBox="0 0 17 10"
                      fill="none"
                    >
                      <path
                        d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </li>
            <li>
              <p className="stage-name">Final Development Stage</p>
              <div className="stage-description">
                <p className="primary">
                  This is the core stage where actual coding and programming
                  take place. Developers write the source code, following best
                  practices coding standards and using the chosen technology
                  stack.
                </p>
                <Link>
                  Know More
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="10"
                      viewBox="0 0 17 10"
                      fill="none"
                    >
                      <path
                        d="M1 5L15 5M15 5L11.093 1M15 5L11.093 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
};

export default GrowthStage;
