import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DemoBlog from "../../Assets/Images/HomeImages/DemoBlog.png";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import moment from "moment";
const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
};
function Blog() {
  const [blogPosts, setBlogPosts] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [blogImage, setBlogImage] = useState([]);
  const [blogList, setBlogList] = useState([]);

  const getImage = async (link, func) => {
    const res = await axios
      .get(link)
      .then((response) => {
        // console.log(response?.data[0]?.guid?.rendered, "redjsbfjsd");
        return response?.data[0]?.guid?.rendered;
      })
      .catch((error) => {
        console.log("error", error);
      });

    return res;
  };

  const getBlogImage = async (post) => {
    // console.log("post", post);
    // console.log('post', post?._links?.['wp:attachment'][0].href)
    const image = await getImage(post?._links?.["wp:attachment"][0].href);
    // console.log("sl", image);
    return image;
  };

  useEffect(() => {
    // Make a GET request to your WordPress blog API
    axios
      .get("https://techwens.com/wp-json/wp/v2/posts")
      .then((response) => {
        setBlogPosts(response.data);
      })
      .catch((err) => console.log("err", err));
  }, []);
  // console.log("blogPosts", blogPosts);

  useEffect(() => {
    setBlogList([]);
    blogPosts?.map(async (post, i) => {
      const blogImage = await getBlogImage(post);
      // console.log("blogImage", blogImage);

      setBlogList((prev) => [...prev, { ...post, image: blogImage }]);
    });
  }, [blogPosts]);
  const TimeFormat = (date) => {
    const parsedDate = moment(date);

    // Calculate the time difference
    const timeDifference = moment().diff(parsedDate);

    // Get the duration
    const duration = moment.duration(timeDifference);

    // Get days, hours, minutes, and seconds
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };
  return (
    <section className="blog">
      <Container>
        <Row>
          <Col lg={3} xl={4} className="blog-head">
            <Container className="mb-5 mb-lg-0">
              <Row>
                <Col sm={8} lg={12}>
                  <p className="primary">News Insight</p>
                  <h3>Gateway to Technological Blogs</h3>
                  <p className="primary">
                    Learn more about what technology has for you through our
                    blogs.
                  </p>
                </Col>
                <Col
                  sm={4}
                  lg={12}
                  className="d-md-flex justify-content-end justify-content-md-start"
                >
                  <Link className="primary-button">
                    Check
                    <br />
                    Our Blogs
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={9} xl={8}>
            <ul>
              <li>
                <Swiper
                  spaceBetween={39}
                  slidesPerView={3}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {blogList
                    ?.slice() // Create a copy of the array to avoid modifying the original data
                    .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date in ascending order
                    .reverse()
                    .map((post, i) => {
                      {
                        /* console.log("jasim", post); */
                      }

                      return (
                        <SwiperSlide tabIndex={i}>
                          {" "}
                          <div className="blog-card">
                            <div className="blog-img">
                              <img src={post?.image} alt="" />
                            </div>
                            <div className="blog-create">
                              <Link to="/BlogAll">
                                {`${post?.title?.rendered}`?.substring(0, 10)}
                              </Link>
                              <span>
                                {moment(post?.date).format("MMM Do YY")}
                              </span>
                            </div>
                            <h5 className="blog-title">
                              {post?.title?.rendered}
                            </h5>
                            <div className="blog-post">
                              <p className="primary">Micel Lurecho</p>
                              <span>.</span>
                              <p className="primary">{`${
                                TimeFormat(post?.date).days || 0
                              } Day ${TimeFormat(post?.date).hours} Hours ${
                                TimeFormat(post?.date).minutes
                              } Minutes`}</p>
                            </div>
                          </div>
                          <div className="swiper-button-prev"></div>
                          <div className="swiper-button-next"></div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </li>
            </ul>
            {/* <ul className="blog-category">
              <li className="active">Manufacturing</li>
              <li>Education</li>
              <li>Fintech</li>
              <li>Fashion</li>
              <li>Case Studies</li>
            </ul> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Blog;
