import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeContactImage from "../../Assets/Images/HomeImages/HomeContact-Image.png";

const Contact = () => {
  return (
    <>
      {/* ~~~~~~~~~~~~~~~~~~~~ Home Contact Section~~~~~~~~~~~~~~~~~~~~~~~*/}
      <section className="HomeContact">
        <Container>
          <Row>
            <Col xs={12} className="d-flex">
              <div>
                <p className="secondary">Work with us</p>
                <h3>Stuck in Executing Project Ideas? Let’s Talk Today!</h3>
              </div>
              <Link to="/contact" className="secondary-button">
                Let’s
                <br />
                talk to us
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
